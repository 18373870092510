import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from 'axios';
import { getPermission } from '../../../libs/auth.js';
import Config from '../../../libs/config.js';
import { Link, withRouter } from 'react-router-dom';

const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50
  });

class StateList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [

            ],
            permissions: [],
            states: []
        }
    }

    componentDidMount() {
        getPermission('State').then(data => {
            console.log('eehhh ', data);
            let token = localStorage.getItem('auth')
            axios.get(Config.BACKEND_API + 'admin/state/list', {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(states => {
                console.log('aaaa', states)
                this.setState({
                    permissions: data.permissions,
                    columns: [
                        {
                            dataField: 'id',
                            text: 'ID',
                            isDummyField: true,
                            formatter: (cell, row, rowIndex) => ++rowIndex
                        }, {
                            dataField: 'name',
                            text: 'Name',
                            searchable: true
                        }, {
                            dataField: 'actions',
                            text: 'Actions',
                            isDummyField: true,
                            csvExport: false,
                            formatter: this.actionsFormatter,
                        }
                    ],
                    states: states.data.states
                })
            })

        }).catch(err => {
            this.props.history.push('/')

        })

    }

  actionsFormatter = (cell, row) => {
    //   <ActionsFormatter id={row.id} />
    console.log("celllll", cell, row);
    console.log("Permissions", this.state.permissions);
    return (
      <>
        {/* <button type="button" class="btn btn-primary mr-1" >
                <i class="fas fa-eye"></i>
            </button> */}
        {this.state.permissions.edit && (
          <Link to={"/state/edit/" + row.id}>
            <button type="button" class="btn btn-warning mr-1">
              <i class="fas fa-pen"></i>
            </button>
          </Link>
        )}
        {/* {this.state.permissions.delete &&
                <button type="button" class="btn btn-danger mr-1" onClick={this.handleDeleteShow} >
                    <i class="fas fa-trash-alt"></i>
                </button>
            } */}
      </>
    );
  };

  render() {
    return (
      <>
        <Layout>
          <>
            {/* Page Heading */}
            <h1 className="h3 mb-2 text-gray-800">States</h1>
            {/* <p className="mb-4">DataTables is a third party plugin that is used to generate the demo table below.
    For more information about DataTables, please visit the <a target="_blank" href="https://datatables.net">official DataTables documentation</a>.</p> */}
                        {/* DataTales Example */}
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h4 className="m-0 font-weight-bold text-primary float-left">List</h4>
                                <Link to={"/state/add/"} className="float-right">
                                    <button type="button" class="btn btn-primary mr-1" >
                                        <i class="fas fa-plus"></i> Add
                                    </button>
                                </Link>
                            </div>
                            <div className="card-body">
                                {this.state.columns.length !== 0 && <BootstrapTable keyField='id' data={this.state.states} columns={this.state.columns} pagination={pagination} />}
                            </div>
                        </div>
                    </>

                </Layout>
            </>
        )
    }
}

export default withRouter(StateList)
