import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";

class AddState extends Component {
  constructor(props) {
    super(props);
    this.state = {
        name: ''
    }
  }

  componentDidMount() {
    getPermissionAccess('State','add').catch(err => {
        this.props.history.push('/')
    })

}
handleChange = key => event => {
    this.setState({
        [key]: event.target.value,
    })
}
handleAdd = e => {
    e.preventDefault();
    console.log(this.state)
    let token = localStorage.getItem('auth')
    axios.post(Config.BACKEND_API+ 'admin/state/add/', {
        name:this.state.name
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).then(res=> {
        console.log('aaaa', res)
        this.props.history.push('/state')

    })
}
  render() {
    console.log("propssssssss", this.props);
    return (
      <Layout>
        <h1>Add State</h1>
        <form onSubmit={this.handleAdd}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleChange('name')}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
           Add
          </button>
        </form>
      </Layout>
    );
  }
}

export default withRouter(AddState);
