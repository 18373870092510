import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import ImgUpload from "../../layout/upload/ImgUpload";
import { getPermissionAccess } from "../../../libs/auth";


class BannerAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerImageUrl: "",
      store: "",
      url: "",
      LatLng: "",
      storeName: [],
    };
  }

  componentDidMount() {
    getPermissionAccess("Banner", "add").catch((err) => {
      this.props.history.push("/");
    });
    let token = localStorage.getItem("auth");
    this.setState({ token: token });
    axios
      .get(Config.BACKEND_API + "admin/vendor/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({
          storeName: res.data.vendor,
        });
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log("storeenter", [e.target.name]);
  };

  handleAdd = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/banner/add",
        {
          bannerImageUrl: this.state.bannerImageUrl,
          store: this.state.store,
          lat: this.state.lat,
          lng: this.state.lng,
          url: this.state.url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/banner/list");
      });
  };

  handleImageChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };

  render() {
    return (
      <Layout>
        <h1 className="b-4">Add Banner Image</h1>
        <div className="row">
          <div className="col-6">
            <form onSubmit={this.handleAdd}>
              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Upload Banner</label>
                  <ImgUpload
                    name={"bannerImageUrl"}
                    token={this.state.token}
                    actionUrl={"admin/upload"}
                    handleImageChange={this.handleImageChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="lat">Lat</label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    id="lat"
                    placeholder="lat"
                    name="lat"
                    value={this.state.lat}
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="lng">lng</label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    id="lng"
                    placeholder="lng"
                    name="lng"
                    value={this.state.lng}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="store">Store</label>
                  <select
                    class="form-control"
                    name="store"
                    onChange={this.handleChange}
                    required
                  >
                    <option value=""  selected disabled>Choose Store</option>
                    {this.state.storeName.map((e) => (
                      <option value={e.id}>{e.name}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="url">Url</label>
                  <input
                    type="url"
                    className="form-control"
                    id="url"
                    placeholder="Url"
                    name="url"
                    value={this.state.url}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <button type="submit" className="btn btn-primary">
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(BannerAdd);
