import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../../layout/loader/loader.js";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// console.log(this.state.columns.totalOrders, "this.state.columns.totalOrders");

const { SearchBar, ClearSearchButton } = Search;

const time = 15000;

class OrdersList extends Component {
  intervalId;
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      orders: [],
      loaderLoading: true,
      pageNo: 1,
      rowNo: 30,
      totalOrders: 0,
    };
  }

  componentDidMount() {
    // clearTimeout(this.intervalId);
    let token = localStorage.getItem("auth");
    getPermission("Order")
      .then((data) => {
        this.setState(
          {
            permissions: data.permissions,
            token: token,
          },
          () => {
            // this.intervalId = setTimeout(this.getOrderList, 15000);
            this.getOrderList();

          }
        );
      })
      .catch((err) => {
        this.props.history.push("/");
      });
       setInterval(this.getOrderListInterval, 30000);
      
  }
  componentWillUnmount() {
    // clearInterval(this.intervalId);
  }

  getOrderList = (pageNum) => {
    this.setState({ loaderLoading: true });
    axios
      .get(
        Config.BACKEND_API +
          `admin/orders/list?PageNo=${
            pageNum ? pageNum : this.state.pageNo
          }&Rows=${this.state.rowNo}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((res) => {
        let oneMinute = 1000 * 60 * 60;
        this.intervalId = setTimeout(this.getOrderList, oneMinute);
        //Storing last order no.
        if(localStorage.getItem('latestOrderNo') === null){
          localStorage.setItem('latestOrderNo', res.data.orders[0].orderNo);
        }else{
          if(localStorage.getItem('latestOrderNo') != res.data.orders[0].orderNo){
            localStorage.setItem('latestOrderNo', res.data.orders[0].orderNo);
            toast.success(`New Order #${res.data.orders[0].orderNo}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "orderNo",
              text: "Order No",
              searchable: true,
            },
            {
              dataField: "createdAt",
              text: "Ordered Time",
              searchable: true,
              formatter: (createdAt, row) => (
                <span>
                  {" "}
                  {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                </span>
              ),
            },
            {
              dataField: "restaurant",
              text: "Restaurant",
              searchable: true,
            },
            {
              dataField: "deliveryBoy.name",
              text: "Delivery Boy",
              searchable: true,
              formatter: this.deliveryboyName,
            },
            {
              dataField: "orderStatus",
              text: "OrderStatus",
              searchable: true,
              formatter: this.orderStatusLabel,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          orders: res.data.orders,
          totalOrders: res.data.totalOrders,
          loaderLoading: false,
        });
      });
  };
  getOrderListInterval = (pageNum) => {
 
    axios
      .get(
        Config.BACKEND_API +
          `admin/orders/list?PageNo=${
            pageNum ? pageNum : this.state.pageNo
          }&Rows=${this.state.rowNo}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((res) => {
        let oneMinute = 1000 * 60 * 60;
        this.intervalId = setTimeout(this.getOrderList, oneMinute);
        //Storing last order no.
        if(localStorage.getItem('latestOrderNo') === null){
          localStorage.setItem('latestOrderNo', res.data.orders[0].orderNo);
        }else{
          if(localStorage.getItem('latestOrderNo') != res.data.orders[0].orderNo){
            localStorage.setItem('latestOrderNo', res.data.orders[0].orderNo);
            toast.success(`New Order #${res.data.orders[0].orderNo}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "orderNo",
              text: "Order No",
              searchable: true,
            },
            {
              dataField: "createdAt",
              text: "Ordered Time",
              searchable: true,
              formatter: (createdAt, row) => (
                <span>
                  {" "}
                  {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                </span>
              ),
            },
            {
              dataField: "restaurant",
              text: "Restaurant",
              searchable: true,
            },
            {
              dataField: "deliveryBoy.name",
              text: "Delivery Boy",
              searchable: true,
              formatter: this.deliveryboyName,
            },
            {
              dataField: "orderStatus",
              text: "OrderStatus",
              searchable: true,
              formatter: this.orderStatusLabel,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          orders: res.data.orders,
          totalOrders: res.data.totalOrders,
       
        });
      });
  };

  deliveryboyName = (deliveryBoy, row) => {
    return (
      <>
        {row.deliveryBoy ? (
          <span>{deliveryBoy}</span>
        ) : (
          <span>No DeliveryBoy Assigned</span>
        )}
      </>
    );
  };

  orderStatusLabel = (status, row) => {
    if (status === 1) {
      return (
        <>
          <h5>
            {" "}
            <span className="badge badge-warning">Pending</span>
          </h5>
        </>
      );
    } else if (status === 2) {
      return (
        <>
          <h5>
            {" "}
            <span className="badge badge-primary">Vendor Accepted</span>
          </h5>
        </>
      );
    } else if (status === 3) {
      return (
        <>
          <h5>
            {" "}
            <span className="badge badge-primary">Delivery Boy Accepted</span>
          </h5>
        </>
      );
    } else if (status === 4) {
      return (
        <>
          <h5>
            {" "}
            <span className="badge badge-primary">Ready to pick up</span>
          </h5>
        </>
      );
    } else if (status === 5) {
      return (
        <>
          <h5>
            {" "}
            <span className="badge badge-info">Picked</span>
          </h5>
        </>
      );
    } else if (status === 0) {
      return (
        <>
          <h5>
            {" "}
            <span className="badge badge-success">Delivered</span>
          </h5>
        </>
      );
    } else if (status === 11) {
      return (
        <>
          <h5>
            {" "}
            <span className="badge badge-danger">Cancelled</span>
          </h5>
        </>
      );
    }
  };

  actionsFormatter = (cell, row) => {
    console.log("sdds", this.state);
    return (
      <>
        {this.state.permissions.view && (
          <Link to={"/order/view/" + row.id}>
            <button type="button" class="btn btn-primary mr-2">
              <i class="fa fa-eye"></i>
            </button>
          </Link>
        )}
      </>
    );
  };

  handlePagination(e) {
    // e.preventDefault();
    console.log(e.selected, "e.selected");
    this.setState({ pageNo: e.selected + 1 });
    this.getOrderList(e.selected + 1);
  }

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Order List</h1>
        <div className="card shadow mb-4">
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <ToolkitProvider
                bootstrap4
                keyField="id"
                data={this.state.orders}
                columns={this.state.columns}
                search
              >
                {(props) => (
                  <>
                    <div className="d-flex align-items-center mb-3">
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      // pagination={paginationFactory(pagination)}
                    />
                  </>
                )}
              </ToolkitProvider>
            )}
            <ReactPaginate
              breakLabel="..."
              previousLabel="<"
              nextLabel=">"
              onPageChange={this.handlePagination.bind(this)}
              pageRangeDisplayed={5}
              pageCount={this.state.totalOrders / this.state.rowNo}
              // renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
        {this.state.loaderLoading ? <Loader /> : null}
      </Layout>
    );
  }
}

export default withRouter(OrdersList);
