import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
// import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import "jspdf-autotable";
// import jsPDF from "jspdf";
import { withRouter, Link } from "react-router-dom";
import { getPermission } from "../../../libs/auth.js";
import Loader from "../../layout/loader/loader.js";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class AdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      orders: "",
      totalAmount: "",
      loaderLoading: true,
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("auth");
    if (!token) {
      this.props.history.push("/login");
    }
    getPermission("Admin")
      .then((data) => {
        this.setState({
          permissions: data.permissions,
        });
        this.getAdminCODListByDate();
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getAdminCODList = (e) => {
    this.setState({
      loaderLoading: true,
    });
    e.preventDefault();
    this.getAdminCODListByDate();
  };

  getAdminCODListByDate = () => {
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/cash-on-delivery/admin/business/list/" +
          this.props.match.params.id,
        {
          startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("orders", res);
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "name",
              text: "DeliveryBoy",
              searchable: true,
            },
            {
              dataField: "orders",
              text: "Orders",
              searchable: true,
            },
            {
              dataField: "total",
              text: "Total",
              searchable: true,
            },
          ],
          orders: res.data.orders,
          totalAmount: res.data.totalAmount,
          deliveryBoys: res.data.deliveryBoys,
          loaderLoading: false,
        });
      });
  };

  dateFormatter = (date) => {
    return <>{moment(date).format("YYYY-MM-DD")}</>;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  //   downloadPDF = () => {
  //     let orders = this.state.orders;
  //     const unit = "pt";
  //     const size = "A4";
  //     const orientation = "portrait";

  //     const marginLeft = 40;
  //     const doc = new jsPDF(orientation, unit, size);

  //     doc.setFontSize(15);

  //     const title = "Business Status";
  //     const headers = [
  //       [
  //         "Order No",
  //         "Date",
  //         "Shop Name",
  //         "Product Price",
  //         "Delivery Tax",
  //         "Deduction",
  //         "Amount Recieved",
  //         "Shop Amount",
  //         "Delivery Charge",
  //         "Profit",
  //       ],
  //     ];

  //     orders = orders.map((e) => [
  //       e.orderNo,
  //       moment(e.date).format("YYYY-MM-DD"),
  //       e.shopName,
  //       e.productPrice,
  //       e.deliveryTax,
  //       e.deduction,
  //       e.amountRecieved,
  //       e.shopAmount,
  //       e.deliveryCharge,
  //       e.profit,
  //     ]);

  //     let content = {
  //       styles: { fontSize: 10 },
  //       startY: 50,
  //       head: headers,
  //       body: orders,
  //     };
  //     doc.text(title, marginLeft, 40);
  //     doc.autoTable(content);
  //     doc.save("BusinessStatus.pdf");
  //   };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Admin COD List</h1>

        <div className="row">
          <div className="col-12">
            <div className="card  mb-4">
              {/* <div className="card-header py-3">
                <h4 className="m-0 font-weight-bold text-primary float-left">
                  Business Status
                </h4>
              </div> */}
              <div className="card-body">
                <form onSubmit={this.getAdminCODList}>
                  <div className="form-row">
                    <div className="form-group col-4">
                      <label for="startDate">Select Start Date</label>
                      <input
                        type="date"
                        value={this.state.startDate}
                        name="startDate"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group  col-4">
                      <label for="endDate">Select End Date</label>
                      <input
                        type="date"
                        value={this.state.endDate}
                        name="endDate"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label className="opacity-0">.</label> <br />
                      <button
                        type="submit"
                        className="btn btn-primary px-4 shadow"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <div className="card  border-left-primary shadow h-100 ">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Orders
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {this.state.orders}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card  border-left-primary shadow h-100 ">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Amount
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {this.state.totalAmount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Admin COD List
            </h4>
            {/* <button
              className="float-right btn btn-outline-dark"
              onClick={this.downloadPDF}
            >
              Print
            </button> */}
          </div>

          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.deliveryBoys}
                columns={this.state.columns}
                pagination={pagination}
                wrapperClasses="table-responsive"
              />
            )}
          </div>
        </div>
        {this.state.loaderLoading ? <Loader /> : null}
      </Layout>
    );
  }
}

export default withRouter(AdminView);
