import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

const { SearchBar, ClearSearchButton } = Search;

class DeliveryboyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      deliveryboy: [],
      showAlertModal: false,
      deliveryBoyId: "",
      disabledId: "",
    };
  }

  componentDidMount() {
    getPermission("DeliveryBoy")
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState({
          permissions: data.permissions,
          token: token,
        });
        this.getDeliveryBoyList();
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getDeliveryBoyList = () => {
    axios
      .get(Config.BACKEND_API + "admin/deliveryBoy/list", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("deliveryboy", res);
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "name",
              text: "Name",
              searchable: true,
            },
            {
              dataField: "phoneNumber",
              text: "Phone Number",
              searchable: true,
            },
            {
              dataField: "isAvailable",
              text: "Is DeliveryBoy Available",
              searchable: true,
              formatter: this.isAvailable,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          deliveryboy: res.data.deliveryBoy,
        });
      });
  };

  isAvailable = (isAvailable, row) => {
    return (
      <>
        <label htmlFor={row.id} className="toggle-btn mt-2">
          <div className="position-relative">
            <input
              id={row.id}
              className="d-none"
              type="checkbox"
              name="isShowing"
              value={row.isAvailable}
              defaultChecked={row.isAvailable}
              disabled={this.state.disabledId === row.id}
              onClick={() => this.isAvailableToggle(row.isAvailable, row.id)}
            />
            <div className="rounded-pill shadow-inner toggle-pill" />
            <div className="dot position-absolute bg-white rounded-circle" />
          </div>
        </label>
      </>
    );
  };

  isAvailableToggle = (isAvailable, id) => {
    this.setState({ disabledId: id });
    let deliveryboy = this.state.deliveryboy;
    let index = _.findIndex(deliveryboy, { id });
    let status = deliveryboy[index].isAvailable;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/deliveryBoy/status/update/" + id,
        {
          isAvailable: !status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        deliveryboy[index].isAvailable = !status;
        this.setState({
          deliveryboy,
          disabledId: "",
        });
      });
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
        <div className="d-flex align-items-center ">
        {this.state.permissions.edit && (
          <Link to={"/deliveryboy/edit/" + row.id}>
            <button type="button" class="btn btn-warning mr-2">
              <i class="fas fa-pen"></i>
            </button>
          </Link>
        )}

        {this.state.permissions.view && (
          <Link to={"/deliveryboy/view/" + row.id}>
            <button type="button" class="btn btn-primary mr-2">
              <i class="fas fa-eye"></i>
            </button>
          </Link>
        )}

        {this.state.permissions.delete && (
          <button
            onClick={() => this.deleteConfirmationPopup(row)}
            type="button"
            class="btn btn-danger mr-1"
          >
            <i class="fas fa-trash"></i>
          </button>
        )}

        <Link to={"/deliveryboyorders/" + row.id}>
          <button
            type="button"
            class="btn btn-outline-info font-weight-normal ml-1 mr-2"
          >
            Deliveryboy Orders
          </button>
        </Link>
        </div>
      </>
    );
  };

  deleteConfirmationPopup = (deliveryBoy) => {
    this.setState({
      showAlertModal: true,
      deliveryBoyId: deliveryBoy.id,
    });
  };

  handleCancel = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  handleDeleteDeliveryBoy = () => {
    let id = this.state.deliveryBoyId;
    axios
      .post(
        Config.BACKEND_API + "admin/deliveryBoy/delete/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          showAlertModal: false,
        });
        this.getDeliveryBoyList();
      });
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Delivery Boy</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Delivery Boy List
            </h4>
            <Link to={"/deliveryboy/add/"} className="float-right">
              <button type="button" class="btn btn-primary mr-1">
                <i class="fas fa-plus"></i> Add
              </button>
            </Link>
          </div>
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <ToolkitProvider
                bootstrap4
                keyField="id"
                data={this.state.deliveryboy}
                columns={this.state.columns}
                search
              >
                {(props) => (
                  <>
                    <div className="d-flex align-items-center mb-3">
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={pagination}
                      wrapperClasses="table-responsive"
                    />
                  </>
                )}
              </ToolkitProvider>
            )}
          </div>
        </div>

        {/* #popup */}
        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">
                Are you sure you want to delete this DeliveryBoy ?
              </h6>

              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleDeleteDeliveryBoy}
                  className="btn btn-success mr-3"
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(DeliveryboyList);
