import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
import { Link } from "react-router-dom";
import _ from "lodash";
import { getPermission } from "../../../libs/auth.js";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class ScratchCardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      scratchCard: [],
      permissions: [],
      showAlertModal: false,
      scratchcardId: "",
      isActive: "",
    };
  }

  componentDidMount() {
    getPermission("ScratchCard")
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState({
          permissions: data.permissions,
          token: token,
        });
        this.getScratchCardList();
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getScratchCardList = () => {
    axios
      .get(Config.BACKEND_API + "admin/scratch-card/list", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        console.log("scratchcard", res);
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "name",
              text: "Name",
              searchable: true,
            },
            {
              dataField: "amount",
              text: "Amount",
              searchable: true,
            },
            {
              dataField: "store.name",
              text: "Store",
              searchable: true,
            },
            {
              dataField: "isActive",
              text: "Is Active",
              searchable: true,
              formatter: this.isActive,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          scratchCard: res.data.scratchCard,
        });
      });
  };

  isActive = (isActive, row) => {
    return (
      <>
        <label htmlFor={row.id} className="toggle-btn mt-2">
          <div className="position-relative">
            <input
              id={row.id}
              className="d-none"
              type="checkbox"
              name="isActive"
              value={row.isActive}
              defaultChecked={row.isActive}
              disabled={this.state.disabledId === row.id}
              onClick={() => this.isActiveToggle(row.isActive, row.id)}
            />
            <div className="rounded-pill shadow-inner toggle-pill" />
            <div className="dot position-absolute bg-white rounded-circle" />
          </div>
        </label>
      </>
    );
  };

  isActiveToggle = (isActive, id) => {
    this.setState({ disabledId: id });
    let scratchCard = this.state.scratchCard;
    let index = _.findIndex(scratchCard, { id });
    let status = scratchCard[index].isActive;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/scratch-card/activate/" + id,
        {
          isActive: !status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        scratchCard[index].isActive = !status;
        this.setState({
          scratchCard,
          disabledId: "",
        });
      });
  };

  deleteConfirmationPopup = (scratchcard) => {
    this.setState({
      showAlertModal: true,
      scratchcardId: scratchcard.id,
    });
  };

  handleCancel = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  handleDeleteScratchCard = () => {
    let id = this.state.scratchcardId;
    axios
      .post(
        Config.BACKEND_API + "admin/scratch-card/activate/" + id,
        {
          isActive: false,
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          showAlertModal: false,
        });
        this.getScratchCardList();
      });
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
        {this.state.permissions.edit && (
          <Link to={"/scratchcard/edit/" + row.id}>
            <button type="button" class="btn btn-warning mr-2">
              <i class="fas fa-pen"></i>
            </button>
          </Link>
        )}

        {this.state.permissions.delete && (
          <button
            onClick={() => this.deleteConfirmationPopup(row)}
            type="button"
            class="btn btn-danger mr-1"
          >
            <i class="fas fa-trash"></i>
          </button>
        )}
      </>
    );
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Scratch Card</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Scratch Card List
            </h4>
            {this.state.permissions.add && (
              <Link to={"/scratchcard/add/"} className="float-right">
                <button type="button" class="btn btn-primary mr-1">
                  <i class="fas fa-plus"></i> Add
                </button>
              </Link>
            )}
          </div>
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.scratchCard}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>

        {/* #popup */}
        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">
                Are you sure you want to delete this ScratchCard ?
              </h6>

              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleDeleteScratchCard}
                  className="btn btn-success mr-3"
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ScratchCardList;
