import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import { getPermission } from "../../../libs/auth.js";
import Loader from "../../layout/loader/loader.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

const { SearchBar, ClearSearchButton } = Search;

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      users: [],
      isLoading: false,
      showAlertModal: false,
      wallet: "",
      walletamount: "",
      userId: "",
      permissions: [],
      loaderLoading: true,
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("auth");
    if (!token) {
      this.props.history.push("/login");
    }
    getPermission("User")
      .then((data) => {
        this.setState({
          permissions: data.permissions,
        });
        this.getUserList();
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getUserList = () => {
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/user/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("user", res);
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "name",
              text: "Name",
              searchable: true,
            },
            {
              dataField: "email",
              text: "Email Id",
              searchable: true,
            },
            {
              dataField: "phoneNumber",
              text: "Phone Number",
              searchable: true,
            },
            {
              dataField: "wallet",
              text: "Wallet",
              searchable: true,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          users: res.data.users,
          loaderLoading: false,
        });
      });
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
        {this.state.permissions.edit && (
          <button
            onClick={() => this.handleWallet(row)}
            type="button"
            class="btn btn-outline-warning mr-2"
          >
            Edit Wallet
          </button>
        )}
      </>
    );
  };

  handleWallet = (row) => {
    this.setState({
      showAlertModal: true,
      wallet: row.wallet,
      userId: row.id,
    });
  };

  handleCancel = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  handleUpdate = () => {
    let id = this.state.userId;
    let users = this.state.users;
    let index = _.findIndex(users, { id });
    let token = localStorage.getItem("auth");

    axios
      .post(
        Config.BACKEND_API + "admin/user/wallet/update/" + this.state.userId,
        {
          walletAmount: this.state.wallet,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        users[index].wallet = this.state.wallet;
        this.setState({ isLoading: true }, () => {
          this.setState({
            users,
            showAlertModal: false,
            isLoading: false,
          });
        });
        // this.getUserList()
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Users</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Users List
            </h4>
          </div>
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <ToolkitProvider
                bootstrap4
                keyField="id"
                data={this.state.isLoading === false ? this.state.users : []}
                columns={this.state.columns}
                search
              >
                {(props) => (
                  <>
                    <div className="d-flex align-items-center mb-3">
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={pagination}
                    />
                  </>
                )}
              </ToolkitProvider>
            )}
          </div>
        </div>

        {/* #popup */}
        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">Edit Wallet Amount</h6>
              <input
                type="number"
                className="form-control mx-5 mb-2"
                value={this.state.wallet}
                name="wallet"
                onChange={this.handleChange}
              />
              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleUpdate}
                  className="btn btn-success mr-3"
                >
                  Update
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.loaderLoading ? <Loader /> : null}
      </Layout>
    );
  }
}

export default withRouter(UsersList);
