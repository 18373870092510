import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
// import { Modal } from "react-bootstrap";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
// import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
// import { Link, withRouter } from "react-router-dom";
import _ from "lodash";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      transaction:[]
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/transactions/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {   
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "amount",
              text: "Amount",
              searchable: true,  
              formatter: (cell, row) => {
                return cell/100;
              }  
            },
            {
              dataField: "status",
              text: "Status",
              searchable: true,
            },
            {
              dataField: "contact",
              text: "Contact",
              searchable: true,
            },
          ],
          transaction: res.data.transaction,
        });
      });
  }

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Transaction List</h1>
        <div className="card shadow mb-4">
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.transaction}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default TransactionList;
