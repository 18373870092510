import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";

class DeliveryboyView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      deliveryBoyOrders: [],
      settlementBalance: "",
      totalEarning: "",
    };
  }

  componentDidMount() {
    getPermission("DeliveryBoy")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(
            Config.BACKEND_API +
              "admin/deliveryBoy/earning/list/" +
              this.props.match.params.id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            this.setState({
              permissions: data.permissions,
              columns: [
                {
                  dataField: "id",
                  text: "ID",
                  isDummyField: true,
                  formatter: (cell, row, rowIndex) => ++rowIndex,
                },
                {
                  dataField: "orderNo",
                  text: "Order No",
                  searchable: true,
                },
                {
                  dataField: "deliveryBoyEarning",
                  text: "Delivery Boy Earning",
                  searchable: true,
                },
              ],
              deliveryBoyOrders: res.data.deliveryBoyOrders,
              settlementBalance: res.data.settlementBalance,
              totalEarning: res.data.totalEarning,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800 pr-2">Delivery Boy</h1>
        <div className="d-flex align-items-center mb-4 ">
          <h6 className="mb-0 text-gray-900">
            Total Earning : ₹{this.state.totalEarning}
          </h6> <span className="px-2 opacity-6">|</span>
          <h6 className="mb-0 text-gray-900">
            Settlement Balance : ₹{this.state.settlementBalance}
          </h6>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Delivery Boy Orders
            </h4>
          </div>
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.deliveryBoyOrders}
                columns={this.state.columns}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(DeliveryboyView);
