import axios from "axios";
import { Component } from "react";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import { withRouter } from "react-router-dom";

class ScratchCardEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      amount: "",
      minOrderPrice: "",
      maxOrderPrice: "",
      type: "",
    };
  }

  componentDidMount() {
    getPermissionAccess("ScratchCard", "edit").catch((err) => {
      this.props.history.push("/");
    });
    let token = localStorage.getItem("auth");
    axios
      .get(
        Config.BACKEND_API + "admin/scratch-card/" + this.props.match.params.id,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        this.setState({
          name: res.data.scratchCard.name,
          amount: res.data.scratchCard.amount,
          minOrderPrice: res.data.scratchCard.minOrderPrice,
          maxOrderPrice: res.data.scratchCard.maxOrderPrice,
          type: res.data.scratchCard.type,
        });

        if (res.data.scratchCard.store) {
          this.setState({
            store: res.data.scratchCard.store.name,
          });
        }
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/scratch-card/update/" +
          this.props.match.params.id,
        {
          name: this.state.name,
          amount: this.state.amount,
          minOrderPrice: this.state.minOrderPrice,
          maxOrderPrice: this.state.maxOrderPrice,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/scratchcard");
      });
  };

  render() {
    return (
      <Layout>
        <h1 className="mb-4">Edit Scratch Card</h1>
        <div className="row">
          <div className="col-6">
            <form onSubmit={this.handleUpdate}>
              <div className="form-group ">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group ">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  placeholder="Amount"
                  name="amount"
                  value={this.state.amount}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderPrice">Min Order Price</label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderPrice"
                  placeholder="Min Order Price"
                  name="minOrderPrice"
                  value={this.state.minOrderPrice}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="maxOrderPrice">Max Order Price</label>
                <input
                  type="number"
                  className="form-control"
                  id="maxOrderPrice"
                  placeholder="Max Order Price"
                  name="maxOrderPrice"
                  value={this.state.maxOrderPrice}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="type">Type</label>
                <input
                  type="text"
                  className="form-control"
                  id="type"
                  placeholder="Type"
                  name="type"
                  value={this.state.type}
                  disabled
                />
              </div>

              {this.state.type === "normal" ? (
                <div className="form-group">
                  <label htmlFor="store">Store</label>
                  <input
                    type="text"
                    className="form-control"
                    id="store"
                    placeholder="store"
                    name="store"
                    value={this.state.store}
                    disabled
                  />
                </div>
              ) : (
                ""
              )}

              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(ScratchCardEdit);
