import Layout from "../layout/mainlayout/Layout.js";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Config from "../../libs/config.js";

class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDeliveryBoys: "",
      completedOrders: "",
      deliveryBoys: "",
      openVendors: "",
      pendingOrders: "",
      totalOrders: "",
      vendors: "",
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("auth");
    if (!token) {
      this.props.history.push("/login");
    }
    axios
      .get(Config.BACKEND_API + "admin/analytics/count", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("dddddmms", res);
        this.setState({
          activeDeliveryBoys: res.data.activeDeliveryBoys,
          completedOrders: res.data.completedOrders,
          deliveryBoys: res.data.deliveryBoys,
          openVendors: res.data.openVendors,
          pendingOrders: res.data.pendingOrders,
          totalOrders: res.data.totalOrders,
          vendors: res.data.vendors,
        });
      });
  }

  render() {
    return (
      <>
        <Layout>
          <h1 className="h3 mb-4">Dashboard</h1>

          <div className="row">
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Active DeliveryBoys
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.activeDeliveryBoys}
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-success shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                        Completed Orders
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.completedOrders}
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-warning shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Delivery Boy
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.deliveryBoys}
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <i className="fas fa-comments fa-2x text-gray-300" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-info shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                        Opened Vendors
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.openVendors}
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <i className="fas fa-comments fa-2x text-gray-300" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-secondary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                        Pending Orders
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.pendingOrders}
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <i className="fas fa-comments fa-2x text-gray-300" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-dark shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                        Total Orders
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.totalOrders}
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <i className="fas fa-comments fa-2x text-gray-300" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Vendors
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.vendors}
                      </div>
                    </div>
                    {/* <div className="col-auto">
                      <i className="fas fa-comments fa-2x text-gray-300" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withRouter(DashBoard);
