import axios from "axios";
import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import { getPermission } from "../../../libs/auth";

class DeliveryBoyEarning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charges: [],
      permissions: [],
    };
  }

  componentDidMount() {
    getPermission("DeliveryBoyEarning")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(
            Config.BACKEND_API +
              "admin/delivery-boy-earning/get-earning-structure",
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((res) => {
            this.setState({
              permissions: data.permissions,
              charges: res.data.charges,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  render() {
    return (
      <Layout>
        <h1 class="h3 mb-4 text-gray-800">Delivery Boy</h1>
        <div className="row">
          <div className="col-6">
            <div className="card delivery-card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <div className="text-primary font-weight-bold">
                  Delivery Boy Earning
                </div>
                {this.state.permissions.edit && (
                  <Link to={"/deliveryboyearning/edit/"}>
                    <button className="btn btn-sm btn-primary">Edit</button>
                  </Link>
                )}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-9">First Two Km</div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.firstTwoKm}
                  </div>

                  <div className="col-9">Two to Six Km</div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.twoToSixKm}
                  </div>

                  <div className="col-9">After Six Km</div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.afterSixKm}
                  </div>

                  <div className="col-9">Minimum Earning Per Day Bonus</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minEarningPerDayBonus}
                  </div>

                  <div className="col-9">
                    Minimum Earning Per Day Bonus Amount
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minEarningPerDayBonusAmount}
                  </div>

                  <div className="col-9">Minimum Earning Per Day Bonus 2</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minEarningPerDayBonus2}
                  </div>

                  <div className="col-9">
                    Minimum Earning Per Day Bonus Amount 2
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minEarningPerDayBonusAmount2}
                  </div>

                  <div className="col-9">Minimum Earning Per Day Bonus 3</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minEarningPerDayBonus3}
                  </div>

                  <div className="col-9">
                    Minimum Earning Per Day Bonus Amount 3
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minEarningPerDayBonusAmount3}
                  </div>

                  <div className="col-9">Minimum Earning Per Day Bonus 4</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minEarningPerDayBonus4}
                  </div>

                  <div className="col-9">
                    Minimum Earning Per Day Bonus Amount 4
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minEarningPerDayBonusAmount4}
                  </div>

                  <div className="col-9">Minimum Earning Per Day Bonus 5</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minEarningPerDayBonus5}
                  </div>

                  <div className="col-9">
                    Minimum Earning Per Day Bonus Amount 5
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minEarningPerDayBonusAmount5}
                  </div>

                  <div className="col-9">Minimum Earning Per Day Bonus 6</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minEarningPerDayBonus6}
                  </div>

                  <div className="col-9">
                    Minimum Earning Per Day Bonus Amount 6
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minEarningPerDayBonusAmount6}
                  </div>

                  <div className="col-9">Minimum Earning Per Day Bonus 7</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minEarningPerDayBonus7}
                  </div>

                  <div className="col-9">
                    Minimum Earning Per Day Bonus Amount 7
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minEarningPerDayBonusAmount7}
                  </div>

                  <div className="col-9">Minimum Earning Per Day Bonus 8</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minEarningPerDayBonus8}
                  </div>

                  <div className="col-9">
                    Minimum Earning Per Day Bonus Amount 8
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minEarningPerDayBonusAmount8}
                  </div>

                  <div className="col-9">Minimum Order Bonus</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minOrderBonus}
                  </div>

                  <div className="col-9">Minimum Order Bonus Amount</div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minOrderBonusAmount}
                  </div>

                  <div className="col-9">Minimum Order Bonus 2</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minOrderBonus2}
                  </div>

                  <div className="col-9">Minimum Order Bonus Amount 2</div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minOrderBonusAmount2}
                  </div>

                  <div className="col-9">Minimum Order Bonus 3</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minOrderBonus3}
                  </div>

                  <div className="col-9">Minimum Order Bonus Amount 3</div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minOrderBonusAmount3}
                  </div>

                  <div className="col-9">Minimum Order Per Week Bonus</div>
                  <div className="col-3 text-right font-weight-bold">
                    {this.state.charges?.minOrderPerWeekBonus}
                  </div>

                  <div className="col-9">
                    Minimum Order Per Week Bonus Amount
                  </div>
                  <div className="col-3 text-right font-weight-bold">
                    ₹{this.state.charges?.minOrderPerWeekBonusAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(DeliveryBoyEarning);
