import React, { Component } from "react";

export default class VariantFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      price: "",
      cgst: "",
      sgst: "",
    };
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.handleFieldChange(this.props.index, this.state);
      }
    );
  };

  render() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6" required>
            <label htmlFor="price">Type</label>
            <input
              type="text"
              className="form-control"
              id="type"
              placeholder="Type"
              name="type"
              value={this.props.type}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="form-group col-md-6" required>
            <label htmlFor="price">Price</label>
            <input
              type="number"
              className="form-control"
              id="price"
              placeholder="Price"
              name="price"
              value={this.props.price}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6" required>
            <label htmlFor="cgst">cgst</label>
            <input
              type="number"
              className="form-control"
              id="cgst"
              placeholder="cgst"
              name="cgst"
              value={this.props.cgst}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="form-group col-md-6" required>
            <label htmlFor="sgst">sgst</label>
            <input
              type="number"
              className="form-control"
              id="sgst"
              placeholder="sgst"
              name="sgst"
              value={this.props.sgst}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
      </div>
    );
  }
}
