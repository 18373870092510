import axios from "axios";
import { Component } from "react";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import { withRouter } from "react-router-dom";

class EditDeliveryBoyEarning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      afterSixKm: "",
      firstTwoKm: "",
      minEarningPerDayBonus: "",
      minEarningPerDayBonusAmount: "",
      minEarningPerDayBonus2: "",
      minEarningPerDayBonusAmount2: "",
      minEarningPerDayBonus3: "",
      minEarningPerDayBonusAmount3: "",
      minEarningPerDayBonus4: "",
      minEarningPerDayBonusAmount4: "",
      minEarningPerDayBonus5: "",
      minEarningPerDayBonusAmount5: "",
      minEarningPerDayBonus6: "",
      minEarningPerDayBonusAmount6: "",
      minEarningPerDayBonus7: "",
      minEarningPerDayBonusAmount7: "",
      minEarningPerDayBonus8: "",
      minEarningPerDayBonusAmount8: "",
      minOrderBonus: "",
      minOrderBonusAmount: "",
      minOrderBonus2: "",
      minOrderBonusAmount2: "",
      minOrderBonus3: "",
      minOrderBonusAmount3: "",
      minOrderPerWeekBonus: "",
      minOrderPerWeekBonusAmount: "",
      twoToSixKm: "",
    };
  }

  componentDidMount() {
    getPermissionAccess("DeliveryBoyEarning", "edit")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(
            Config.BACKEND_API +
              "admin/delivery-boy-earning/get-earning-structure",
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((res) => {
            this.setState({
              ...res.data.charges,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/delivery-boy-earning/update-earnings",
        {
          ...this.state,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/deliveryboyearning");
      });
  };

  render() {
    return (
      <Layout>
        <h1 className="mb-4">Edit DeliveryBoy Earning</h1>
        <div className="row">
          <div className="col-6">
            <form onSubmit={this.handleUpdate}>
              <div className="form-group ">
                <label htmlFor="afterSixKm">After Six Km</label>
                <input
                  type="number"
                  className="form-control"
                  id="afterSixKm"
                  placeholder="After Six Km"
                  name="afterSixKm"
                  value={this.state.afterSixKm}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group ">
                <label htmlFor="firstTwoKm">First Two Km</label>
                <input
                  type="number"
                  className="form-control"
                  id="firstTwoKm"
                  placeholder="First Two Km"
                  name="firstTwoKm"
                  value={this.state.firstTwoKm}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonus">
                  Minimum Earning Per Day Bonus
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonus"
                  placeholder="Minimum Earning Per Day Bonus"
                  name="minEarningPerDayBonus"
                  value={this.state.minEarningPerDayBonus}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonusAmount">
                  Minimum Earning Per Day Bonus Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonusAmount"
                  placeholder="Minimum Earning Per Day Bonus Amount"
                  name="minEarningPerDayBonusAmount"
                  value={this.state.minEarningPerDayBonusAmount}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonus">
                  Minimum Earning Per Day Bonus 2
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonus2"
                  placeholder="Minimum Earning Per Day Bonus"
                  name="minEarningPerDayBonus2"
                  value={this.state.minEarningPerDayBonus2}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonusAmount">
                  Minimum Earning Per Day Bonus Amount 2
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonusAmount2"
                  placeholder="Minimum Earning Per Day Bonus Amount"
                  name="minEarningPerDayBonusAmount2"
                  value={this.state.minEarningPerDayBonusAmount2}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonus">
                  Minimum Earning Per Day Bonus 3
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonus3"
                  placeholder="Minimum Earning Per Day Bonus"
                  name="minEarningPerDayBonus3"
                  value={this.state.minEarningPerDayBonus3}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonusAmount">
                  Minimum Earning Per Day Bonus Amount 3
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonusAmount3"
                  placeholder="Minimum Earning Per Day Bonus Amount"
                  name="minEarningPerDayBonusAmount3"
                  value={this.state.minEarningPerDayBonusAmount3}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonus">
                  Minimum Earning Per Day Bonus 4
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonus4"
                  placeholder="Minimum Earning Per Day Bonus"
                  name="minEarningPerDayBonus4"
                  value={this.state.minEarningPerDayBonus4}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonusAmount">
                  Minimum Earning Per Day Bonus Amount 4
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonusAmount4"
                  placeholder="Minimum Earning Per Day Bonus Amount"
                  name="minEarningPerDayBonusAmount4"
                  value={this.state.minEarningPerDayBonusAmount4}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonus">
                  Minimum Earning Per Day Bonus 5
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonus5"
                  placeholder="Minimum Earning Per Day Bonus"
                  name="minEarningPerDayBonus5"
                  value={this.state.minEarningPerDayBonus5}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonusAmount">
                  Minimum Earning Per Day Bonus Amount 5
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonusAmount5"
                  placeholder="Minimum Earning Per Day Bonus Amount"
                  name="minEarningPerDayBonusAmount5"
                  value={this.state.minEarningPerDayBonusAmount5}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonus">
                  Minimum Earning Per Day Bonus 6
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonus6"
                  placeholder="Minimum Earning Per Day Bonus"
                  name="minEarningPerDayBonus6"
                  value={this.state.minEarningPerDayBonus6}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonusAmount">
                  Minimum Earning Per Day Bonus Amount 6
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonusAmount6"
                  placeholder="Minimum Earning Per Day Bonus Amount"
                  name="minEarningPerDayBonusAmount6"
                  value={this.state.minEarningPerDayBonusAmount6}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonus">
                  Minimum Earning Per Day Bonus 7
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonus7"
                  placeholder="Minimum Earning Per Day Bonus"
                  name="minEarningPerDayBonus7"
                  value={this.state.minEarningPerDayBonus7}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonusAmount">
                  Minimum Earning Per Day Bonus Amount 7
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonusAmount7"
                  placeholder="Minimum Earning Per Day Bonus Amount"
                  name="minEarningPerDayBonusAmount7"
                  value={this.state.minEarningPerDayBonusAmount7}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonus">
                  Minimum Earning Per Day Bonus 8
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonus8"
                  placeholder="Minimum Earning Per Day Bonus"
                  name="minEarningPerDayBonus8"
                  value={this.state.minEarningPerDayBonus8}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minEarningPerDayBonusAmount">
                  Minimum Earning Per Day Bonus Amount 8
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minEarningPerDayBonusAmount8"
                  placeholder="Minimum Earning Per Day Bonus Amount"
                  name="minEarningPerDayBonusAmount8"
                  value={this.state.minEarningPerDayBonusAmount8}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderBonus">Minimum Order Bonus</label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderBonus"
                  placeholder="Minimum Order Bonus"
                  name="minOrderBonus"
                  value={this.state.minOrderBonus}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderBonusAmount">
                  Minimum Order Bonus Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderBonusAmount"
                  placeholder="Minimum Order Bonus Amount"
                  name="minOrderBonusAmount"
                  value={this.state.minOrderBonusAmount}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderBonus">Minimum Order Bonus 2</label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderBonus2"
                  placeholder="Minimum Order Bonus"
                  name="minOrderBonus2"
                  value={this.state.minOrderBonus2}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderBonusAmount">
                  Minimum Order Bonus Amount 2
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderBonusAmount2"
                  placeholder="Minimum Order Bonus Amount"
                  name="minOrderBonusAmount2"
                  value={this.state.minOrderBonusAmount2}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderBonus">Minimum Order Bonus 3</label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderBonus3"
                  placeholder="Minimum Order Bonus"
                  name="minOrderBonus3"
                  value={this.state.minOrderBonus3}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderBonusAmount">
                  Minimum Order Bonus Amount 3
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderBonusAmount3"
                  placeholder="Minimum Order Bonus Amount"
                  name="minOrderBonusAmount3"
                  value={this.state.minOrderBonusAmount3}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderPerWeekBonus">
                  Minimum Order Per Week Bonus
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderPerWeekBonus"
                  placeholder="Minimum Order Per Week Bonus"
                  name="minOrderPerWeekBonus"
                  value={this.state.minOrderPerWeekBonus}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="minOrderPerWeekBonusAmount">
                  Minimum Order Per Week Bonus Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minOrderPerWeekBonusAmount"
                  placeholder="Minimum Order Per Week Bonus Amount"
                  name="minOrderPerWeekBonusAmount"
                  value={this.state.minOrderPerWeekBonusAmount}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="twoToSixKm">Two to Six Km</label>
                <input
                  type="number"
                  className="form-control"
                  id="twoToSixKm"
                  placeholder="Two to Six Km"
                  name="twoToSixKm"
                  value={this.state.twoToSixKm}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(EditDeliveryBoyEarning);
