import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";

class AddCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      states: [],
      latitude: "",
      longitude: "",
    };
  }

  componentDidMount() {
    getPermissionAccess("City", "add")
      .then((states) => {
        let token = localStorage.getItem("auth");
        axios
          .get(Config.BACKEND_API + "admin/state/list", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((states) => {
            this.setState({
              states: states.data.states,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  handleChange = (key) => (event) => {
    this.setState({
      [key]: event.target.value,
    });
  };

  handleAdd = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/city/add/",
        {
          name: e.target.name.value,
          state: e.target.state.value,
          latitude: e.target.latitude.value,
          longitude: e.target.longitude.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/city");
      });
  };
  render() {
    console.log("propssssssss", this.props);
    return (
      <Layout>
        <h1>Add City</h1>
        <form onSubmit={this.handleAdd}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <select class="form-control" name="state">
                {this.state.states.map((e) => (
                  <option value={e.id}>{e.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-row">
                <div className="form-group col-md-6" >
                  <label htmlFor="latitude">Latitude</label>
                  <input
                    type="text"
                    className="form-control"
                    id="latitude"
                    placeholder="Latitude"
                    name="latitude"               
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className="form-group col-md-6" >
                  <label htmlFor="longitude">Longitude</label>
                  <input
                    type="text"
                    className="form-control"
                    id="longitude"
                    placeholder="Longitude"
                    name="longitude"                 
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
          <button type="submit" className="btn btn-primary">
            Add
          </button>
        </form>
      </Layout>
    );
  }
}

export default withRouter(AddCity);
