import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";

class AdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      admins: [],
      showModal: false,
      deleteId: "",
      adminPermission: false,
      showAlertModal: false,
      adminsId: "",
    };
  }

  componentDidMount() {
    getPermission("Admin")
      .then((data) => {
        console.log("eehhh ", data);
        let token = localStorage.getItem("auth");
        this.setState({
          permissions: data.permissions,
          adminId: data.id,
          token: token,
        });
        this.getAdminList();
      })
      .catch((err) => {
        console.log("err ", err);
        this.props.history.push("/");
      });
    getPermission("AdminPermission").then((data) => {
      this.setState({
        adminPermission: true,
      });
    });
  }

  getAdminList = () => {
    axios
      .get(Config.BACKEND_API + "admin/list", {
        headers: {
          Authorization: "Bearer " + this.state.token,
        },
      })
      .then((res) => {
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "name",
              text: "Name",
              searchable: true,
            },
            {
              dataField: "email",
              text: "Email",
              searchable: true,
            },
            {
              dataField: "type",
              text: "Type",
              searchable: true,
              formatter: this.adminType,
            },
            {
              dataField: "city.name",
              text: "City",
              searchable: true,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          admins: res.data.admins,
        });
      });
  };

  adminType = (type) => {
    if (type === "franchise") {
      return <>City Admin</>;
    } else {
      return <>{type}</>;
    }
  };

  actionsFormatter = (cell, row) => {
    if (this.state.adminId === row.id) {
      return <>
        <Link to={"/admin/view/" + row.id}>
          <button type="button" class="btn btn-secondary mr-1">
            <i class="fas fa-eye"></i>
          </button>
        </Link>
      </>;
    }
    return (
      <>
        {this.state.permissions.edit && (
          <Link to={"/admin/edit/" + row.id}>
            <button type="button" class="btn btn-warning mr-1">
              <i class="fas fa-pen"></i>
            </button>
          </Link>
        )}
        {this.state.adminPermission && (
          <Link to={"/admin/permission/" + row.id}>
            <button type="button" class="btn btn-primary mr-1">
              <i class="fas fa-user-lock"></i>
            </button>
          </Link>
        )}
        {this.state.permissions.delete && (
          <button
            onClick={() => this.deleteConfirmationPopup(row)}
            type="button"
            class="btn btn-danger mr-1"
          >
            <i class="fas fa-trash"></i>
          </button>
        )}

        <Link to={"/admin/view/" + row.id}>
          <button type="button" class="btn btn-secondary mr-1">
            <i class="fas fa-eye"></i>
          </button>
        </Link>
      </>
    );
  };

  deleteConfirmationPopup = (admin) => {
    this.setState({
      showAlertModal: true,
      adminsId: admin.id,
    });
  };

  handleCancel = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  handleDeleteAdmin = () => {
    let id = this.state.adminsId;
    axios
      .post(
        Config.BACKEND_API + "admin/delete/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          showAlertModal: false,
        });
        this.getAdminList();
      });
  };

  render() {
    return (
      <>
        <Layout>
          <>
            {/* <Modal show={this.state.showModal} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure to delete?</Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-secondary"
                  onClick={this.handleClose}
                >
                  Close
                </button>
                <button className="btn btn-danger" >
                  Delete
                </button>
              </Modal.Footer>
            </Modal> */}
            {/* Page Heading */}
            <h1 className="h3 mb-2 text-gray-800">Admin Users</h1>
            {/* <p className="mb-4">DataTables is a third party plugin that is used to generate the demo table below.
    For more information about DataTables, please visit the <a target="_blank" href="https://datatables.net">official DataTables documentation</a>.</p> */}
            {/* DataTales Example */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h4 className="m-0 font-weight-bold text-primary float-left">
                  List
                </h4>
                <Link to={"/admin/add/"} className="float-right">
                  <button type="button" class="btn btn-primary mr-1">
                    <i class="fas fa-user-plus"></i> Add
                  </button>
                </Link>
              </div>
              <div className="card-body">
                {this.state.columns.length !== 0 && (
                  <BootstrapTable
                    keyField="id"
                    data={this.state.admins}
                    columns={this.state.columns}
                    pagination={paginationFactory()}
                  />
                )}
              </div>
            </div>
          </>

          {/* #popup */}
          <div
            className="d-pageoverlay"
            style={{ display: this.state.showAlertModal ? "block" : "none" }}
          >
            <div className="pagemodal-bg-wrapper">
              <div className="d-popup">
                <h6 className="mb-3">
                  Are you sure you want to delete this Admin ?
                </h6>

                <div className="d-flex justify-content-center mt-3">
                  <button
                    onClick={this.handleDeleteAdmin}
                    className="btn btn-success mr-3"
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    onClick={this.handleCancel}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withRouter(AdminList);
