import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { getPermissionAccess } from "../../../libs/auth";
import moment from "moment";

const types = ['Recieved','Recieve'];
class CashOnDeliveryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      orders: [],
      showAlertModal: false,
      recieveSingleId: "",
      modalType: null,
      isReturnedToAdmin: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    getPermissionAccess("CashOnDelivery", "view").catch((err) => {
      this.props.history.push("/");
    });

    this.getCashOnDeliveryList();
  }

  recieveSingleWithAlert = (id) => {
    this.setState({
      showAlertModal: true,
      recieveSingleId: id,
      modalType: "single",
    });
  };

  getCashOnDeliveryList = () => {
    let token = localStorage.getItem("auth");
    axios
      .get(
        Config.BACKEND_API +
          "admin/cash-on-delivery/delivery-boy/order/list/" +
          this.props.match.params.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let orders = res.data.orders;
        let notReceivedOrders = orders.filter( order => order.isReturnedToAdmin === false)
        let receivedOrders = orders.filter( order => order.isReturnedToAdmin === true)
        orders = notReceivedOrders.concat(receivedOrders);
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "serviceType",
              text: "Service Type",
              searchable: true,
              
            },
            {
              dataField: "pickUpNo",
              text: "Pick Up No",
              searchable: true,
              formatter: (pickUpNo, row) =>
                pickUpNo !== null ? pickUpNo : "NA",
            },
            {
              dataField: "orderNo",
              text: "Order No",
              searchable: true,
              formatter: (orderNo, row) => (orderNo !== null ? orderNo : "NA"),
              sort: true,
            },

            {
              dataField: "amount",
              text: "Amount",
              searchable: true,
              formatter: (sum, row) => <strong>₹{sum}</strong>,
            },
            {
              dataField: "amountReceivedDate",
              text: "Amt Recieved Date",
              searchable: true,
              formatter: this.dateFormatter,
            },

            {
              dataField: "receivedBy.name",
              text: "Received By",
              searchable: true,
         
            },


            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.ReceiveSingleCash,
              sort: true,
              sortValue: (cell, row) => types[cell]
            },
          ],
          orders
    
        });
      });
  };

  dateFormatter = (date) => {
    return <>{date ? moment(date).format("YYYY-MM-DD") : "Not recieved"}</>;
  };

  recieveCancel = () => {
    this.setState({
      showAlertModal: false,
      recieveSingleId: "",
      modalType: null,
    });
  };

  recieveSingle = () => {
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/cash-on-delivery/receive/cod/amount/" +
          this.state.recieveSingleId,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let id = this.state.recieveSingleId;
        let orders = this.state.orders;
        // _.remove(orders, { id });
        let index = _.findIndex(orders, { id });
        orders[index].isReturnedToAdmin = true;
        this.setState({ isLoading: true }, () => {
          this.setState({
            orders,
            showAlertModal: false,
            recieveSingleId: "",
            modalType: null,
            isLoading: false,
          });
        });

        // this.getCashOnDeliveryList();
      });
  };

  recieveAllWithAlert = () => {
    this.setState({
      showAlertModal: true,
      modalType: "all",
    });
  };

  recieveAll = () => {
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/cash-on-delivery/receive/all/" +
          this.props.match.params.id,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/cashondelivery");
      });
  };

  ReceiveSingleCash = (cell, row) => {
    return (
      <>
        {row.isReturnedToAdmin !== true ? (
          <button
            type="button"
            class="btn btn-primary mr-2"
            onClick={() => this.recieveSingleWithAlert(row.id)}
          >
            Recieve
          </button>
        ) : (
          <>
            <span className="badge text-md badge-success">Recieved</span>
          </>
        )}
      </>
    );
  };

  render() {
    console.log("th", this.state.orders);
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Cash On Delivery View</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Order List
            </h4>

            {/* <button
              type="button"
              class="btn btn-primary mr-1 float-right"
              onClick={() => this.recieveAllWithAlert()}
            >
              Recieve All
            </button> */}
          </div>
          <div className="card-body">
            {this.state.orders.length !== 0 && (
              <BootstrapTable
                keyField="id"
                // data={this.state.orders}
                data={this.state.isLoading === false ? this.state.orders : []}
                columns={this.state.columns}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>

        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <p>Are you sure? This action can't be reversed!</p>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btn-success mr-3"
                  onClick={
                    this.state.modalType === "single"
                      ? this.recieveSingle
                      : this.state.modalType === "all"
                      ? this.recieveAll
                      : ""
                  }
                >
                  Yes
                </button>
                <button className="btn btn-danger" onClick={this.recieveCancel}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(CashOnDeliveryView);
