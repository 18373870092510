import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import ImgUpload from "../../layout/upload/ImgUpload";
import { getPermissionAccess } from "../../../libs/auth";

class BannerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerImageUrl: "",
      store: "",
      url: "",
      LatLng: "",
      storeName: [],
    };
  }

  componentDidMount() {
    getPermissionAccess("Banner", "edit").catch((err) => {
      this.props.history.push("/");
    });
    let token = localStorage.getItem("auth");
    this.setState({ token: token });
    axios
      .get(Config.BACKEND_API + "admin/vendor/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({
          storeName: res.data.vendor,
        });
      });
    axios
      .get(
        Config.BACKEND_API +
          "admin/banner/getById/" +
          this.props.match.params.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("response", res);
        this.setState({
          bannerImageUrl: res.data.banner.bannerImageUrl,
          store: res.data.banner.store
            ? res.data.banner.store.id
            : this.state.storeName[0].id,
          url: res.data.banner.url,
          lat:
            res.data.banner.LatLng !== null
              ? res.data.banner.LatLng.coordinates[0]
              : "",
          lng:
            res.data.banner.LatLng !== null
              ? res.data.banner.LatLng.coordinates[1]
              : "",
        });
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/banner/update/" +
          this.props.match.params.id,
        {
          bannerImageUrl: this.state.bannerImageUrl,
          store: this.state.store,
          url: this.state.url,
          lat: this.state.lat,
          lng: this.state.lng,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/banner/list");
      });
  };

  handleImageChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };

  render() {
    console.log("dms", this.state.store);
    return (
      <Layout>
        <h1 className="b-4">Edit Banner Image</h1>
        <div className="row">
          <div className="col-6">
            <form onSubmit={this.handleUpdate}>
              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Replace Banner</label>
                  {this.state.bannerImageUrl !== "" && (
                    <ImgUpload
                      name={"bannerImageUrl"}
                      token={this.state.token}
                      actionUrl={"admin/upload"}
                      handleImageChange={this.handleImageChange}
                      image={this.state.bannerImageUrl}
                    />
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="lat">Lat</label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    id="lat"
                    placeholder="lat"
                    name="lat"
                    value={this.state.lat}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="lng">lng</label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    id="lng"
                    placeholder="lng"
                    name="lng"
                    value={this.state.lng}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="store">Store</label>
                  <select
                    class="form-control"
                    name="store"
                    onChange={this.handleChange}
                  >
                    {this.state.storeName.map((e) => (
                      <option value={e.id} selected={this.state.store === e.id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="url">Url</label>
                  <input
                    type="url"
                    className="form-control"
                    id="url"
                    placeholder="Url"
                    name="url"
                    value={this.state.url}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(BannerEdit);
