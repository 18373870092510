const { default: axios } = require("axios");
const _  = require("lodash");
const { default: Config } = require("./config");


module.exports = {
    getPermission: (module) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('auth')
            axios.get(Config.BACKEND_API + 'admin/auth', {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(res => {
                let permissions = _.find(res.data.permissions, {module});
                if (permissions.view) {
                    resolve({
                        permissions,
                        id: res.data.id
                    })
                }else{
                    reject('Module Not found')
                }
            }).catch(err => {
                // console.log('Err ', err.response.data.message)
                reject(err)
            })
        })
    },
    getAllPermissions: () => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('auth')
            axios.get(Config.BACKEND_API + 'admin/auth', {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(res => {
                resolve(res.data.permissions)
            }).catch(err => {
                // console.log('Err ', err.response.data.message)
                reject(err)
            })
        })
    },
    getPermissionAccess: (module, access) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('auth')
            axios.get(Config.BACKEND_API + 'admin/auth', {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(res => {
                let permissions = _.find(res.data.permissions, {module});
                if (permissions[access]) {
                    resolve({permissions})
                }else{
                    reject('Dont have permision')
                }
            }).catch(err => {
                // console.log('Err ', err.response.data.message)
                reject(err)
            })
        })
    },
}