import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class PayoutList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      payout: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    getPermission("Payout")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(Config.BACKEND_API + "admin/payout/list", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            this.setState({
              permissions: data.permissions,
              columns: [
                {
                  dataField: "id",
                  text: "ID",
                  isDummyField: true,
                  formatter: (cell, row, rowIndex) => ++rowIndex,
                },
                {
                  dataField: "name",
                  text: "Name",
                  searchable: true,
                  formatter: this.name,
                },
                {
                  dataField: "type",
                  text: "Type",
                  searchable: true,
                },
                {
                  dataField: "amount",
                  text: "Amount",
                  searchable: true,
                },
                {
                  dataField: "status",
                  text: "Status",
                  searchable: true,
                  formatter: this.checkStatus,
                },
                {
                  dataField: "actions",
                  text: "Actions",
                  isDummyField: true,
                  csvExport: false,
                  formatter: this.actionsFormatter,
                },
              ],
              payout: res.data.payout,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  name = (cell, row) => {
    if (row.deliveryBoy) {
      return (
        <>
          <span> {row.deliveryBoy.name}</span>
        </>
      );
    } else if (row.vendor) {
      return (
        <>
          <span> {row.vendor.name}</span>
        </>
      );
    }
  };

  checkStatus = (cell, row) => {
    let status = row.status;
    if (status === "pending") {
      return (
        <>
          <h5>
            <span className="badge badge-warning">Pending</span>
          </h5>
        </>
      );
    } else if (status === "accepted") {
      return (
        <>
          <h5>
            <span className="badge badge-success">Accepted</span>
          </h5>
        </>
      );
    } else if (status === "rejected") {
      return (
        <>
          <h5>
            <span className="badge badge-danger">Rejected</span>
          </h5>
        </>
      );
    }
  };

  accept = (id) => {
    let payout = this.state.payout;
    let index = _.findIndex(payout, { id });
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/payout/accept/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        payout[index].status = "accepted";
        this.setState({ isLoading: true }, () => {
          this.setState({
            payout,
            isLoading: false,
          });
        });
      })
      .catch(({ response }) => {
        this.notify(response.data.message);
      });
  };

  notify = (message) => {
    toast.error("" + message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  reject = (id) => {
    let payout = this.state.payout;
    let index = _.findIndex(payout, { id });
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/payout/reject/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        payout[index].status = "rejected";
        this.setState({ isLoading: true }, () => {
          this.setState({
            payout,
            isLoading: false,
          });
        });
      });
  };

  actionsFormatter = (cell, row) => {
    if (row.status === "pending") {
      return (
        <>
          <button
            className="btn btn-sm btn-info mr-2"
            onClick={() => this.accept(row.id)}
          >
            Accept
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => this.reject(row.id)}
          >
            Reject
          </button>
        </>
      );
    } else {
      return <></>;
    }
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Payout</h1>
        <div className="card shadow mb-4">
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.isLoading === false ? this.state.payout : []}
                // data={this.state.payout}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(PayoutList);
