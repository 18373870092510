import axios from "axios";
import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { getPermission } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";

class DeliveryCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charges: [],
      permissions: [],
    };
  }

  componentDidMount() {
    getPermission("DeliveryCharge")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(Config.BACKEND_API + "admin/delivery/get-charges", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((res) => {
            this.setState({
              permissions: data.permissions,
              charges: res.data.charges,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  render() {
    return (
      <Layout>
        <h1 class="h3 mb-4 text-gray-800">Delivery Charge</h1>
        <div className="row">
          <div className="col-6">
            <div className="card delivery-card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <div className="text-primary font-weight-bold">
                  Delivery Charges
                </div>
                <Link to={"/delivery-charge/edit/"}>
                  <button className="btn btn-sm btn-primary">Edit</button>
                </Link>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-6">Base Charge</div>
                  <div className="col-6 text-right font-weight-bold">
                    ₹{this.state.charges?.baseCharge}
                  </div>

                  <div className="col-6">Base Charge Distance</div>
                  <div className="col-6 text-right font-weight-bold">
                    {this.state.charges?.baseChargeDistance}Km
                  </div>

                  <div className="col-6">Additional Charge / Km</div>
                  <div className="col-6 text-right font-weight-bold">
                    ₹{this.state.charges?.additionalChargePerKM}
                  </div>

                  <div className="col-6">Second Base Charge</div>
                  <div className="col-6 text-right font-weight-bold">
                    ₹{this.state.charges?.secondBaseCharge}
                  </div>

                  <div className="col-6">Second Base Charge Distance</div>
                  <div className="col-6 text-right font-weight-bold">
                    ₹{this.state.charges?.secondBaseChargeDistance}
                  </div>

                  <div className="col-6">CGST</div>
                  <div className="col-6 text-right font-weight-bold">
                    {this.state.charges?.cgst}%
                  </div>

                  <div className="col-6">SGST</div>
                  <div className="col-6 text-right font-weight-bold">
                    {this.state.charges?.sgst}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(DeliveryCharge);
