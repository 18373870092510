import React, { useEffect, useState } from "react";
import "./assets/css/admin.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DashBoard from "./component/pages/DashBoard";
import Login from "./component/pages/Login";
import VendorList from "./component/pages/vendor/VendorList";
import StateList from "./component/pages/states/StateList";
import EditState from "./component/pages/states/EditState";
import AddState from "./component/pages/states/AddState";
import CityList from "./component/pages/cities/CityList";
import EditCity from "./component/pages/cities/EditCity";
import AddCity from "./component/pages/cities/AddCity";
import AdminList from "./component/pages/admin/AdminList";
import AddAdmin from "./component/pages/admin/AddAdmin";
import EditAdmin from "./component/pages/admin/EditAdmin";
import AdminPermission from "./component/pages/admin/AdminPermission";
import DeliveryCharge from "./component/pages/deliverycharge/DeliveryCharge";
import EditDeliverycharge from "./component/pages/deliverycharge/EditDeliverycharge";
import CategoryList from "./component/pages/category/CategoryList";
import CategoryEdit from "./component/pages/category/CategoryEdit";
import CategoryAdd from "./component/pages/category/CategoryAdd";
import VendorAdd from "./component/pages/vendor/VendorAdd";
import VendorEdit from "./component/pages/vendor/VendorEdit";
import VendorView from "./component/pages/vendor/VendorView";
import ProductList from "./component/pages/products/ProductList";
import ProductAdd from "./component/pages/products/ProductAdd";
import DeliveryboyList from "./component/pages/deliveryboy/DeliveryboyList";
import DeliveryboyAdd from "./component/pages/deliveryboy/DeliveryboyAdd";
import DeliveryboyEdit from "./component/pages/deliveryboy/DeliveryboyEdit";
import OrdersList from "./component/pages/orders/OrdersList";
import CouponsList from "./component/pages/coupon/CouponsList";
import CouponAdd from "./component/pages/coupon/CouponAdd";
import VendorApprovalPending from "./component/pages/orders/VendorApprovalPending";
import DeliverboyApprovalPending from "./component/pages/orders/DeliverboyApprovalPending";
import DeliveryboyAccepted from "./component/pages/orders/DeliveryboyAccepted";
import ReadyToPickup from "./component/pages/orders/ReadyToPickup";
import OrderPicked from "./component/pages/orders/OrderPicked";
import OrderDelivered from "./component/pages/orders/OrderDelivered";
import OrderCancelled from "./component/pages/orders/OrderCancelled";
import OrderView from "./component/pages/orders/OrderView";
import PayoutList from "./component/pages/payout/PayoutList";
import UnassignedOrders from "./component/pages/unassigned/UnassignedOrders";
import BannerList from "./component/pages/banner/BannerList";
import BannerAdd from "./component/pages/banner/BannerAdd";
import BannerEdit from "./component/pages/banner/BannerEdit";
import PickUpCharge from "./component/pages/pickupcharge/PickUpCharge";
import EditPickUpCharge from "./component/pages/pickupcharge/EditPickUpCharge";
import PickUpDropList from "./component/pages/pickupanddrop/PickUpDropList";
import PickUpDropView from "./component/pages/pickupanddrop/PickUpDropView";
import DeliveryBoyAssignPending from "./component/pages/pickupanddrop/DeliveryBoyAssignPending";
import DeliveryBoyAssigned from "./component/pages/pickupanddrop/DeliveryBoyAssigned";
import DeliveryBoyAccepted from "./component/pages/pickupanddrop/DeliveryBoyAccepted";
import PickedUp from "./component/pages/pickupanddrop/PickedUp";
import DeliveryBoyDelivered from "./component/pages/pickupanddrop/DeliveryBoyDelivered";
import CashOnDeliveryList from "./component/pages/cashondelivery/CashOnDeliveryList";
import CashOnDeliveryView from "./component/pages/cashondelivery/CashOnDeliveryView";
import DeliveryBoyEarning from "./component/pages/deliveryboyearning/DeliveryBoyEarning";
import EditDeliveryBoyEarning from "./component/pages/deliveryboyearning/EditDeliveryBoyEarning";
import ScratchCardList from "./component/pages/scratchcard/ScratchCardList";
import ScratchCardAdd from "./component/pages/scratchcard/ScratchCardAdd";
import ScratchCardEdit from "./component/pages/scratchcard/ScratchCardEdit";
import BusinessStatusList from "./component/pages/business/BusinessStatus";
import UsersList from "./component/pages/users/UsersList";
import DeliveryboyView from "./component/pages/deliveryboy/DeliveryboyView";
import PushNotification from "./component/pages/pushnotification/PushNotification";
import EditProduct from "./component/pages/products/EditProduct";

// import { getToken } from "./libs/firebase";
import SettlementList from "./component/pages/settlements/SettlementList";
import TransactionList from "./component/pages/transaction/TransactionList";
import VendorOrder from "./component/pages/vendor/VendorOrder";
import DeliveryboyOrdersList from "./component/pages/deliveryboy/DeliveryboyOrdersList";
import AdminView from "./component/pages/admin/AdminView";
import Config from "./libs/config";
import axios from "axios";

function App() {
  // const [isTokenFound, setTokenFound] = useState(false);

  // getToken(setTokenFound)
  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  useEffect(() => {
    OneSignal.push(() => {
      OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
      });
      OneSignal.getUserId(function (userId) {
        if (userId) {
          console.log("current token for client: ", userId);
          // Track the token -> client mapping, by sending to backend server
          let token = localStorage.getItem("auth");
          axios
            .post(
              Config.BACKEND_API + "admin/notification-token/update",
              {
                token: userId,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then(() => {
              // localStorage.setItem('notification', userId)
              console.log("Token updated!!!!!!!!!!!!!");
            });
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // shows on the UI that permission is required
        }
        // console.log('user id', userId);
      });
    });
  }, []);

  return (
    <>
      <ToastContainer />
      <Router>
        <Switch>
          <Route exact path="/">
            {" "}
            <DashBoard />{" "}
          </Route>
          <Route path="/login">
            {" "}
            <Login />{" "}
          </Route>
          <Route exact path="/vendor">
            {" "}
            <VendorList />{" "}
          </Route>
          <Route exact path="/vendor/add">
            {" "}
            <VendorAdd />{" "}
          </Route>
          <Route exact path="/vendor/edit/:id">
            {" "}
            <VendorEdit />{" "}
          </Route>
          <Route exact path="/vendor/view/:id">
            {" "}
            <VendorView />{" "}
          </Route>
          <Route exact path="/state">
            {" "}
            <StateList />{" "}
          </Route>
          <Route exact path="/state/edit/:id">
            {" "}
            <EditState />{" "}
          </Route>
          <Route exact path="/state/add">
            {" "}
            <AddState />{" "}
          </Route>
          <Route exact path="/city">
            {" "}
            <CityList />{" "}
          </Route>
          <Route exact path="/city/edit/:id">
            {" "}
            <EditCity />{" "}
          </Route>
          <Route exact path="/city/add">
            {" "}
            <AddCity />{" "}
          </Route>
          <Route exact path="/delivery-charge">
            {" "}
            <DeliveryCharge />{" "}
          </Route>
          <Route exact path="/delivery-charge/edit">
            {" "}
            <EditDeliverycharge />{" "}
          </Route>
          <Route exact path="/admin">
            {" "}
            <AdminList />{" "}
          </Route>
          <Route exact path="/admin/add">
            {" "}
            <AddAdmin />{" "}
          </Route>
          <Route exact path="/admin/edit/:id">
            {" "}
            <EditAdmin />{" "}
          </Route>
          <Route exact path="/admin/permission/:id">
            {" "}
            <AdminPermission />{" "}
          </Route>
          <Route exact path="/admin/view/:id">
            {" "}
            <AdminView />{" "}
          </Route>
          <Route exact path="/category">
            {" "}
            <CategoryList />{" "}
          </Route>
          <Route exact path="/category/edit/:id">
            {" "}
            <CategoryEdit />{" "}
          </Route>
          <Route exact path="/category/add">
            {" "}
            <CategoryAdd />{" "}
          </Route>
          <Route exact path="/product/add/:id">
            {" "}
            <ProductAdd />{" "}
          </Route>
          <Route exact path="/product/edit/:id">
            {" "}
            <EditProduct />{" "}
          </Route>
          <Route exact path="/product/:id">
            {" "}
            <ProductList />{" "}
          </Route>
          <Route exact path="/deliveryboy">
            {" "}
            <DeliveryboyList />{" "}
          </Route>
          <Route exact path="/deliveryboy/add">
            {" "}
            <DeliveryboyAdd />{" "}
          </Route>
          <Route exact path="/deliveryboy/edit/:id">
            {" "}
            <DeliveryboyEdit />{" "}
          </Route>
          <Route exact path="/deliveryboy/view/:id">
            {" "}
            <DeliveryboyView />{" "}
          </Route>
          <Route exact path="/order/list">
            {" "}
            <OrdersList />{" "}
          </Route>
          <Route exact path="/order/vendorpending">
            {" "}
            <VendorApprovalPending />{" "}
          </Route>
          <Route exact path="/order/deliveryboypending">
            {" "}
            <DeliverboyApprovalPending />{" "}
          </Route>
          <Route exact path="/order/deliveryboyaccepted">
            {" "}
            <DeliveryboyAccepted />{" "}
          </Route>
          <Route exact path="/order/readytopickup">
            {" "}
            <ReadyToPickup />{" "}
          </Route>
          <Route exact path="/order/pickedup">
            {" "}
            <OrderPicked />{" "}
          </Route>
          <Route exact path="/order/delivered">
            {" "}
            <OrderDelivered />{" "}
          </Route>
          <Route exact path="/order/cancelled">
            {" "}
            <OrderCancelled />{" "}
          </Route>
          <Route exact path="/coupon">
            {" "}
            <CouponsList />{" "}
          </Route>
          <Route exact path="/coupon/add">
            {" "}
            <CouponAdd />{" "}
          </Route>
          <Route exact path="/order/view/:id">
            {" "}
            <OrderView />{" "}
          </Route>
          <Route exact path="/payout">
            {" "}
            <PayoutList />{" "}
          </Route>
          <Route exact path="/unassigned/list">
            {" "}
            <UnassignedOrders />{" "}
          </Route>
          <Route exact path="/banner/list">
            {" "}
            <BannerList />{" "}
          </Route>
          <Route exact path="/banner/add">
            {" "}
            <BannerAdd />{" "}
          </Route>
          <Route exact path="/banner/edit/:id">
            {" "}
            <BannerEdit />{" "}
          </Route>
          <Route exact path="/pickupcharge">
            {" "}
            <PickUpCharge />{" "}
          </Route>
          <Route exact path="/pickupcharge/edit">
            {" "}
            <EditPickUpCharge />{" "}
          </Route>
          <Route exact path="/pickupdrop">
            {" "}
            <PickUpDropList />{" "}
          </Route>
          <Route exact path="/pickupdrop/view/:id">
            {" "}
            <PickUpDropView />{" "}
          </Route>
          <Route exact path="/pickupdrop/deliveryboynotassigned">
            {" "}
            <DeliveryBoyAssignPending />{" "}
          </Route>
          <Route exact path="/pickupdrop/deliveryboyassigned">
            {" "}
            <DeliveryBoyAssigned />{" "}
          </Route>
          <Route exact path="/pickupdrop/deliveryboyaccepted">
            {" "}
            <DeliveryBoyAccepted />{" "}
          </Route>
          <Route exact path="/pickupdrop/pickedup">
            {" "}
            <PickedUp />{" "}
          </Route>
          <Route exact path="/pickupdrop/delivered">
            {" "}
            <DeliveryBoyDelivered />{" "}
          </Route>
          <Route exact path="/cashondelivery">
            {" "}
            <CashOnDeliveryList />{" "}
          </Route>
          <Route exact path="/cashondelivery/view/:id">
            {" "}
            <CashOnDeliveryView />{" "}
          </Route>
          <Route exact path="/deliveryboyearning">
            {" "}
            <DeliveryBoyEarning />{" "}
          </Route>
          <Route exact path="/deliveryboyearning/edit">
            {" "}
            <EditDeliveryBoyEarning />{" "}
          </Route>
          <Route exact path="/scratchcard">
            {" "}
            <ScratchCardList />{" "}
          </Route>
          <Route exact path="/scratchcard/add">
            <ScratchCardAdd />{" "}
          </Route>
          <Route exact path="/scratchcard/edit/:id">
            {" "}
            <ScratchCardEdit />{" "}
          </Route>
          <Route exact path="/businessStatus">
            {" "}
            <BusinessStatusList />{" "}
          </Route>
          <Route exact path="/user">
            {" "}
            <UsersList />{" "}
          </Route>
          <Route exact path="/pushnotification">
            {" "}
            <PushNotification />{" "}
          </Route>
          <Route exact path="/settlements">
            {" "}
            <SettlementList />{" "}
          </Route>
          <Route exact path="/transactions">
            {" "}
            <TransactionList />{" "}
          </Route>
          <Route exact path="/vendororder/:id">
            {" "}
            <VendorOrder />{" "}
          </Route>
          <Route exact path="/deliveryboyorders/:id">
            {" "}
            <DeliveryboyOrdersList />{" "}
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
