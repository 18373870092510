import Layout from "../../layout/mainlayout/Layout.js";
import { Component } from "react";
import { getPermissionAccess } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import axios from "axios";
import { withRouter } from "react-router-dom";

class OrderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryCgst: "",
      deliveryCharge: "",
      deliverySgst: "",
      dropAddress: "",
      paymentMode: "",
      pickUpAddress: "",
      pickUpNo: "",
      pickUpStatus: "",
      total: "",
      totalTax: "",
      deliveryBoy: [],
      deliveryBoyName: "",
      deliveryCharge: "",
      deliveryBoyPhcode: "",
      deliveryBoyPhoneNumber: "",
      deliveryBoyList: [],
      showAlertModal:false
    };
  }

  componentDidMount() {
    getPermissionAccess("PickUpDrop", "view").then((data) =>{
    let token = localStorage.getItem("auth");
    axios
      .get(
        Config.BACKEND_API +
          "admin/pick-up-drop/getById/" +
          this.props.match.params.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          deliveryCharge: res.data.pickUpDrop.deliveryCharge,
          deliverySgst: res.data.pickUpDrop.deliverySgst,
          dropAddress: res.data.pickUpDrop.dropAddress,
          paymentMode: res.data.pickUpDrop.paymentMode,
          pickUpAddress: res.data.pickUpDrop.pickUpAddress,
          pickUpNo: res.data.pickUpDrop.pickUpNo,
          pickUpStatus: res.data.pickUpDrop.pickUpStatus,
          total: res.data.pickUpDrop.total,
          totalTax: res.data.pickUpDrop.totalTax,
          deliveryBoy: res.data.pickUpDrop.deliveryBoy,
        });
        if (res.data.pickUpDrop.deliveryBoy) {
          this.setState({
            deliveryBoyName: res.data.pickUpDrop.deliveryBoy.name,
            deliveryBoyPhcode: res.data.pickUpDrop.deliveryBoy.phoneCode,
            deliveryBoyPhoneNumber: res.data.pickUpDrop.deliveryBoy.phoneNumber,
            deliveryCharge: res.data.pickUpDrop.deliveryCharge,
          });
        }
        this.getDeliveryBoyList();
      })
    })
      .catch((err) => {
        console.log("err ", err);
      });
  }

  getDeliveryBoyList = () => {
    let token = localStorage.getItem("auth");
    axios
      .get(
        Config.BACKEND_API +
          "admin/pickUpAndDrop/delivery-boy/assignable/list/" +
          this.props.match.params.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("assigndeliveryboy", res.data.deliveryBoy);
        this.setState({
          deliveryBoyList: res.data.deliveryBoy,
        });
      });
  };


  
  handleNo = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  cancelConfirmationPopup = () => {
    this.setState({
      showAlertModal: true,
    });
  };

  handleCancelOrder = () => {
    let id = this.props.match.params.id;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/pick-up-drop/cancel/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({  
          pickUpStatus:11,       
          showAlertModal: false,
        });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/pickUpAndDrop/delivery-boy/assign/" +
          this.props.match.params.id,
        {
          deliveryBoy: this.state.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/pickupdrop");
      });
  };

  render() {
    let status = this.state.pickUpStatus;
    let badge;

    if (status === 0) {
      badge = <span className="badge badge-success">Delivered</span>;
    } else if (status === 1) {
      badge = (
        <span className="badge badge-warning"> DeliveryBoy Assign Pending</span>
      );
    } else if (status === 2) {
      badge = <span className="badge badge-primary">DeliveryBoy Assigned</span>;
    } else if (status === 3) {
      badge = <span className="badge badge-primary">DeliveryBoy Accepted</span>;
    } else if (status === 4) {
      badge = <span className="badge badge-info">Picked Up</span>;
    }  else if (status === 11) {
      badge = <span className="badge badge-danger">Cancelled</span>;
    }

    let paymentMode = this.state.paymentMode;
    let paymentModeBagde;

    if (paymentMode === 1) {
      paymentModeBagde = <span>COD</span>;
    } else if (paymentMode === 2) {
      paymentModeBagde = <span>Online</span>;
    }

    return (
      <Layout>
        <div className="float-right d-flex">
          {status !== 0 && status !== 11 ? (
            <button
              onClick={this.cancelConfirmationPopup}
              className="btn btn-outline-danger"
            >
              Cancel Order
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex align-items-center mb-4">
          <h1 className="mb-0">PickUp And Drop #{this.state.pickUpNo}</h1>

          <div className="float-right ml-2">
            <h4 className="mb-0">{badge}</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 pb-5">
            <div className="card h-100">
              <div class="card-header py-3 bg-white">
                <h6 class="m-0 font-weight-bold text-primary ">
                  PickUp And Drop
                </h6>
              </div>
              <div class="card-body">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="w-50">PickUp Address</td>
                      <td className="w-50">{this.state.pickUpAddress}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Drop Address</td>
                      <td className="w-50">{this.state.dropAddress}</td>
                    </tr>

                    <tr>
                      <td className="w-50">PickUp Number</td>
                      <td className="w-50">{this.state.pickUpNo}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Pick Up Status</td>
                      <td className="w-50">{badge}</td>
                    </tr>

                    <tr>
                      <td className="w-50">Payment Mode</td>
                      <td className="w-50">{paymentModeBagde}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {this.state.deliveryBoy === null ? (
            ""
          ) : (
            <div className="col-lg-6 pb-5">
              <div className="card h-100">
                <div class="card-header py-3 bg-white">
                  <h6 class="m-0 font-weight-bold text-primary ">
                    Delivery Boy
                  </h6>
                </div>
                <div class="card-body">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="w-50">Address</td>
                        <td className="w-50">
                          {this.state.deliveryBoy.address}
                        </td>
                      </tr>

                      <tr>
                        <td className="w-50">Phone Code</td>
                        <td className="w-50">
                          {this.state.deliveryBoy.phoneCode}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-50">Phone Number</td>
                        <td className="w-50">
                          {this.state.deliveryBoy.phoneNumber}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-6 pb-5">
            <div className="card h-100">
              <div class="card-header py-3 bg-white">
                <h6 class="m-0 font-weight-bold text-primary ">Pricing</h6>
              </div>
              <div class="card-body">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="w-50">Delivery Cgst</td>
                      <td className="w-50">{this.state.deliveryCgst}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Delivery Charge</td>
                      <td className="w-50">{this.state.deliveryCharge}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Delivery Sgst</td>
                      <td className="w-50">{this.state.deliverySgst}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Total Tax</td>
                      <td className="w-50">{this.state.totalTax}</td>
                    </tr>
                    <tr>
                      <td className="w-50 font-weight-bold">Total</td>
                      <td className="w-50 font-weight-bold">
                        {this.state.total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-sm-8 pb-5">
          <div className="card h-100">
            <div class="card-header py-3 bg-white">
              <h6 class="m-0 font-weight-bold text-primary ">
                Delivery Boy Details
              </h6>
            </div>

            <div class="card-body">
              {this.state.deliveryBoy === null && status === 1 ? (
                <form onSubmit={this.handleUpdate}>
                  <div className="d-flex align-items-end">
                    <div className="form-group col-md-6">
                      <label htmlFor="id">Choose Delivery Boy</label>
                      <select
                        class="form-control"
                        id="id"
                        name="id"
                        onChange={this.handleChange}
                        required
                      >
                        <option selected>Choose Delivery Boy</option>
                        {this.state.deliveryBoyList.map((e) => (
                          <option value={e.id}>{e.name} </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-md-6">
                      <button className="btn btn-primary">Assign</button>
                    </div>
                  </div>
                </form>
              ) : this.state.deliveryBoy === null ? (
                <p>Delivery boy is not assigned yet</p>
              ) : (
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td>Delivery Boy Name</td>
                      <td>{this.state.deliveryBoyName}</td>
                    </tr>
                    <tr>
                      <td>Delivery Charge</td>
                      <td>₹{this.state.deliveryCharge}</td>
                    </tr>
                    <tr>
                      <td>Phone Code</td>
                      <td>{this.state.deliveryBoyPhcode}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{this.state.deliveryBoyPhoneNumber}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

              {/* #popup  */}

              <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">
                Are you sure you want to cancel your order ?
              </h6>

              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleCancelOrder}
                  className="btn btn-primary  mr-3"
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleNo}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    );
  }
}

export default withRouter(OrderView);
