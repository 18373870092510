import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import { getPermission } from "../../../libs/auth.js";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class CashOnDeliveryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      cashOnDelivery: [],
      permissions: [],
    };
  }

  componentDidMount() {
    getPermission("CashOnDelivery")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(Config.BACKEND_API + "admin/cash-on-delivery/list", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log("cod", res);
            this.setState({
              permissions: data.permissions,
              columns: [
                {
                  dataField: "id",
                  text: "ID",
                  isDummyField: true,
                  formatter: (cell, row, rowIndex) => ++rowIndex,
                },
                {
                  dataField: "name",
                  text: "Name",
                  searchable: true,
                },
                {
                  dataField: "phoneCode",
                  text: "Phone Code",
                  searchable: true,
                },
                {
                  dataField: "phoneNumber",
                  text: "Phone Number",
                  searchable: true,
                },

                {
                  dataField: "due",
                  text: "Due",
                  searchable: true,
                  formatter: (due, row) => <strong>₹{due}</strong>,
                },

                {
                  dataField: "actions",
                  text: "Actions",
                  isDummyField: true,
                  csvExport: false,
                  formatter: this.actionsFormatter,
                },
              ],
              cashOnDelivery: res.data.cashOnDelivery,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  actionsFormatter = (cell, row) => {
    return (
      <>
        {this.state.permissions.view && (
          <Link to={"/cashondelivery/view/" + row.id}>
            <button type="button" class="btn btn-primary mr-2">
              <i class="fas fa-eye"></i>
            </button>
          </Link>
        )}
      </>
    );
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Cash On Delivery List</h1>
        <div className="card shadow mb-4">
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.cashOnDelivery}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(CashOnDeliveryList);
