import Layout from "../../layout/mainlayout/Layout.js";
import { Component } from "react";
import { getPermissionAccess } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router-dom";
import _ from "lodash";

class OrderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNo: "",
      paymentMode: "",
      paymentStatus: "",
      address: "",
      subTotal: "",
      totalTax: "",
      total: "",
      name: "",
      storeAddress: "",
      storePhcode: "",
      storePhoneNumber: "",
      deliveryBoy: "",
      deliveryBoyName: "",
      deliveryBoyPhcode: "",
      deliveryBoyPhoneNumber: "",
      deliveryCharge: "",
      usedCoupon: "",
      couponCode: "",
      couponName: "",
      userName: "",
      userPhcode: "",
      userPhoneNumber: "",
      orderedProducts: [],
      orderStatus: "",
      deliveryBoyList: [],
      createdAt: "",
      showAlertModal: false,
      discountAmount: "",
      cookingTime: "",
      vendorAccepted: "",
      vendorAcceptTime: "",
      vendorAcceptedAt:""
    };
  }

  componentDidMount() {
    getPermissionAccess("Order", "view")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(
            Config.BACKEND_API +
              "admin/orders/getById/" +
              this.props.match.params.id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("vendorview", res);
            this.setState({
              orderNo: res.data.order.orderNo,
              paymentMode: res.data.order.paymentMode,
              paymentStatus: res.data.order.paymentStatus,
              address: res.data.order.address,
              subTotal: res.data.order.subTotal,
              totalTax: res.data.order.totalTax,
              total: res.data.order.total,
              name: res.data.order.store.name,
              storeAddress: res.data.order.store.address,
              storePhcode: res.data.order.store.phoneCode,
              storePhoneNumber: res.data.order.store.phoneNumber,
              deliveryBoy: res.data.order.deliveryBoy,
              usedCoupon: res.data.order.usedCoupon,
              userName: res.data.order.user.name,
              userPhcode: res.data.order.user.phoneCode,
              userPhoneNumber: res.data.order.user.phoneNumber,
              orderedProducts: res.data.orderedProducts,
              orderStatus: res.data.order.orderStatus,
              createdAt: res.data.order.createdAt,
              discountAmount: res.data.order.discountAmount,
              deliveryCharge: res.data.order.deliveryCharge,
              cookingTime: res.data.order.cookingTime,
              // vendorAcceptTime: res.data.order.vendorAcceptTime,
              vendorAcceptedAt:res.data.order.vendorAcceptedAt
            });
            if (res.data.order.deliveryBoy) {
              this.setState({
                deliveryBoyName: res.data.order.deliveryBoy.name,
                deliveryBoyPhcode: res.data.order.deliveryBoy.phoneCode,
                deliveryBoyPhoneNumber: res.data.order.deliveryBoy.phoneNumber,
              });
            }
            if (res.data.order.usedCoupon) {
              this.setState({
                couponCode: res.data.order.usedCoupon.code,
                couponName: res.data.order.usedCoupon.name,
              });
            }
          });
        this.getDeliveryBoyList();
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }

  getDeliveryBoyList = () => {
    let token = localStorage.getItem("auth");
    axios
      .get(
        Config.BACKEND_API +
          "admin/delivery-boy/assignable/list/" +
          this.props.match.params.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("assigndeliveryboy", res.data.deliveryBoy);
        this.setState({
          deliveryBoyList: res.data.deliveryBoys,
        });
      });
  };

  handleNo = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  cancelConfirmationPopup = () => {
    this.setState({
      showAlertModal: true,
    });
  };

  handleCancelOrder = () => {
    let id = this.props.match.params.id;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/orders/cancel/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          orderStatus: 11,
          showAlertModal: false,
        });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/delivery-boy/assign/" +
          this.props.match.params.id,
        {
          deliveryBoy: this.state.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/order/list");
      });
  };

  render() { 
    // var vendorAccepted;
    // if (
    //   process.env.REACT_APP_ASSIGN_BEFORE_MINUTES &&
    //   this.state.cookingTime >= process.env.REACT_APP_ASSIGN_BEFORE_MINUTES
    // ) {
    //   var timeDifference =
    //     this.state.cookingTime - process.env.REACT_APP_ASSIGN_BEFORE_MINUTES;
    //   vendorAccepted = moment(this.state.vendorAcceptTime)
    //     .subtract(timeDifference, "minutes")
    //     .format("LT");
    // } else {
    //   var timeDifference = 0;
    //   vendorAccepted = "N/A";
    // }

    let status = this.state.orderStatus;
    let badge;

    if (status === 1) {
      badge = <span className="badge badge-warning">Pending</span>;
    } else if (status === 2) {
      badge = <span className="badge badge-primary">Vendor Accepted</span>;
    } else if (status === 3) {
      badge = (
        <span className="badge badge-primary">Delivery Boy Accepted</span>
      );
    } else if (status === 4) {
      badge = <span className="badge badge-primary">Ready to pick up</span>;
    } else if (status === 5) {
      badge = <span className="badge badge-info">Picked</span>;
    } else if (status === 0) {
      badge = <span className="badge badge-success">Delivered</span>;
    } else if (status === 11) {
      badge = <span className="badge badge-danger">Cancelled</span>;
    }

    let paymentStatus = this.state.paymentStatus;
    let paymentbadge;

    if (paymentStatus === 1) {
      paymentbadge = <span className="badge badge-warning">Pending</span>;
    } else if (paymentStatus === 2) {
      paymentbadge = (
        <span className="badge badge-info">Online Payment Completed</span>
      );
    } else if (paymentStatus === 3) {
      paymentbadge = <span className="badge badge-info">COD Recieved</span>;
    } else if (paymentStatus === 4) {
      paymentbadge = <span className="badge badge-danger">Failed</span>;
    }

    let paymentMode = this.state.paymentMode;
    let paymentModeBagde;

    if (paymentMode === 1) {
      paymentModeBagde = <span>COD</span>;
    } else if (paymentMode === 2) {
      paymentModeBagde = <span>Online</span>;
    }

    return (
      <Layout>
        <div className="float-right d-flex">
          <a
            href={`https://spicyfood.s3.ap-south-1.amazonaws.com/pdf/${this.state.orderNo}.pdf`}
          >
            <button className="btn btn-secondary d-flex align-items-center mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-printer mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
              </svg>
              Print
            </button>
          </a>

          {status !== 0 && status !== 11 ? (
            <button
              onClick={this.cancelConfirmationPopup}
              className="btn btn-outline-danger"
            >
              Cancel Order
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex align-items-center mb-0">
          <h1 className="mb-0">Order #{this.state.orderNo}</h1>

          <div className="float-right ml-2">
            <h4 className="mb-0">{badge}</h4>
          </div>
        </div>
        <p className="mb-4 text-gray-600 pl-1">
          <span className="text-gray-700 font-weight-bold">
            Ordered Time :{" "}
          </span>
          {moment(this.state.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        </p>

        <div className="row">
          <div className="col-lg-6 pb-5">
            <div className="card h-100">
              <div class="card-header py-3 bg-white">
                <h6 class="m-0 font-weight-bold text-primary ">
                  Order Details
                </h6>
              </div>
              <div class="card-body">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="w-50">Order No</td>
                      <td className="w-50">{this.state.orderNo}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Payment Mode</td>
                      <td className="w-50">{paymentModeBagde}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Payment Status</td>
                      <td className="w-50">{paymentbadge}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Address</td>
                      <td className="w-50">{this.state.address}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Vendor Accepted Time</td>
                      <td className="w-50">{moment(this.state.vendorAcceptedAt).format("LT")}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Cooking Time</td>
                      <td className="w-50">{this.state.cookingTime} Min</td>
                    </tr>

                    <tr>
                      <td className="w-50">Redeem Amount</td>
                      <td className="w-50">{this.state.discountAmount}</td>
                    </tr>

                    <tr>
                      <td>Delivery Charge</td>
                      <td>₹{this.state.deliveryCharge}</td>
                    </tr>

                    <tr>
                      <td className="w-50">SubTotal</td>
                      <td className="w-50">{this.state.subTotal}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Total Tax</td>
                      <td className="w-50">{this.state.totalTax}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Total</td>
                      <td className="font-weight-bold w-50">
                        {this.state.total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-lg-6 pb-5">
            <div className="card h-100">
              <div class="card-header py-3 bg-white">
                <h6 class="m-0 font-weight-bold text-primary ">
                  Store Details
                </h6>
              </div>
              <div class="card-body">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="w-50">Store Name</td>
                      <td className="w-50">{this.state.name}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Address</td>
                      <td className="w-50">{this.state.storeAddress}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Phone Code</td>
                      <td className="w-50">{this.state.storePhcode}</td>
                    </tr>
                    <tr>
                      <td className="w-50">Phone Number</td>
                      <td className="w-50">{this.state.storePhoneNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 pb-5">
            <div className="card h-100">
              <div class="card-header py-3 bg-white">
                <h6 class="m-0 font-weight-bold text-primary ">
                  Delivery Boy Details
                </h6>
              </div>

              <div class="card-body">
                {status === 2 && (
                  <form onSubmit={this.handleUpdate}>
                    <div className="d-flex align-items-end">
                      <div className="form-group col-md-6">
                        <label htmlFor="id">Choose Delivery Boy</label>
                        <select
                          class="form-control"
                          id="id"
                          name="id"
                          onChange={this.handleChange}
                          required
                        >
                          <option selected>Choose Delivery Boy</option>
                          {console.log(
                            "deliveryBoyList -> ",
                            this.state.deliveryBoyList
                          )}
                          {this.state.deliveryBoyList &&
                            this.state.deliveryBoyList.map((e) => (
                              <option value={e.id}>{e.name} </option>
                            ))}
                        </select>
                      </div>

                      <div className="form-group col-md-6">
                        <button className="btn btn-primary">Assign</button>
                      </div>
                    </div>
                  </form>
                )}
                {this.state.deliveryBoy === null ? (
                  <p>Delivery boy is not assigned yet</p>
                ) : (
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>Delivery Boy Name</td>
                        <td>{this.state.deliveryBoyName}</td>
                      </tr>
                      {/* <tr>
                        <td>Delivery Charge</td>
                        <td>₹{this.state.deliveryCharge}</td>
                      </tr> */}
                      <tr>
                        <td>Phone Code</td>
                        <td>{this.state.deliveryBoyPhcode}</td>
                      </tr>
                      <tr>
                        <td>Phone Number</td>
                        <td>{this.state.deliveryBoyPhoneNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 pb-5">
            <div className="card h-100">
              <div class="card-header py-3 bg-white">
                <h6 class="m-0 font-weight-bold text-primary ">
                  Coupon Details
                </h6>
              </div>
              <div class="card-body">
                {this.state.usedCoupon === null ? (
                  <p>Coupon Is Not Applied</p>
                ) : (
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>Coupon Code</td>
                        <td className="font-weight-bold">
                          {this.state.couponCode}
                        </td>
                      </tr>
                      <tr>
                        <td>Coupon Name</td>
                        <td className="font-weight-bold">
                          {this.state.couponName}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 pb-5">
            <div className="card h-100">
              <div class="card-header py-3 bg-white">
                <h6 class="m-0 font-weight-bold text-primary ">User Details</h6>
              </div>
              <div class="card-body">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{this.state.userName}</td>
                    </tr>
                    <tr>
                      <td>Phone Code</td>
                      <td>{this.state.userPhcode}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{this.state.userPhoneNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-12 pb-5">
            <div className="card h-100">
              <div class="card-header py-3 bg-white">
                <h6 class="m-0 font-weight-bold text-primary ">Products</h6>
              </div>
              <div class="card-body">
                <table className="w-100 table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Actual Price</th>
                      <th>Sold Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orderedProducts.map((product, index) => {
                      // if (product.isVariant) {
                      return (
                        <tr>
                          <td>
                            {product.details.name}{" "}
                            {product.isVariant
                              ? `(${product.details.variant.type})`
                              : ""}
                          </td>
                          <td>{product.quantity}</td>
                          <td>{product.price}</td>
                          <td>{product.sellPrice}</td>
                        </tr>
                      );
                      // }else{

                      // }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* #popup  */}

        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">
                Are you sure you want to cancel your order ?
              </h6>

              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleCancelOrder}
                  className="btn btn-primary  mr-3"
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleNo}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(OrderView);
