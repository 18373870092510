import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
// import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { withRouter, Link } from "react-router-dom";
// import { getPermission } from "../../../libs/auth.js";
import Loader from "../../layout/loader/loader.js";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class DeliveryboyOrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      columnsTwo: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      orders: [],
      rewards:[],
      loaderLoading: true,
      balance:"",
      bonus:"",
      ordersCount:"",
      totalEarnings:""
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("auth");
    if (!token) {
      this.props.history.push("/login");
    }
    this.getDeliveryboyOrdersListByDate();
  }

  getDeliveryboyOrdersList = (e) => {
    this.setState({
      loaderLoading: true,
    });
    e.preventDefault();
    this.getDeliveryboyOrdersListByDate();
  };

  getDeliveryboyOrdersListByDate = () => {
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/deliveryBoy/orders/list/" +
          this.props.match.params.id,
        {
          startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "orderNo",
              text: "Order No",
              searchable: true,
            },
            {
              dataField: "createdAt",
              text: "Ordered Time",
              searchable: true,
              formatter: (createdAt, row) => (
                <span>
                  {" "}
                  {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                </span>
              ),
            },
            {
              dataField: "deliveryBoyEarning",
              text: "DeliveryBoy Earning",
              searchable: true,
              formatter: (deliveryBoyEarning) =>(<>₹{deliveryBoyEarning}</>)
            },
            {
              dataField: "total",
              text: "Total",
              searchable: true,
              formatter: (total) =>(<>₹{total}</>)
            },
          ],
          columnsTwo: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "rewardName",
              text: "Reward Name",
              searchable: true,
            },
            {
              dataField: "createdAt",
              text: "Ordered Time",
              searchable: true,
              formatter: (createdAt, row) => (
                <span>
                  {" "}
                  {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                </span>
              ),
            },
         
            {
              dataField: "amount",
              text: "Amount",
              searchable: true,
              formatter: (amount) =>(<>₹{amount}</>)
            },
          ],
          orders: res.data.orders, 
          rewards:res.data.rewards, 
          balance:res.data.balance,
          bonus:res.data.bonus,
          ordersCount:res.data.ordersCount,
          totalEarnings:res.data.totalEarnings,     
          loaderLoading: false,
        });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  downloadPDF = () => {
    let orders = this.state.orders;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "DeliveryBoy Orders";
    const headers = [
      ["Order No", "Ordered Time", "DeliveryBoy Earning", "Total"],
    ];

    orders = orders.map((e) => [
      e.orderNo,
      moment(e.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
      e.deliveryBoyEarning,
      e.total,
    ]);

    let content = {
      styles: { fontSize: 10 },
      startY: 50,
      head: headers,
      body: orders,
    };

    // doc.autoTable({
    //     body: [
    //       [{ content, styles: { fontSize: 5 }} ],
    //     ],
    //   })

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("DeliveryBoyOrders.pdf");
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">DeliveryBoy Order List</h1>

        <div className="row">
          <div className="col-12">
            <div className="card  mb-4">
              {/* <div className="card-header py-3">
                <h4 className="m-0 font-weight-bold text-primary float-left">
                  Business Status
                </h4>
              </div> */}
              <div className="card-body">
                <form onSubmit={this.getDeliveryboyOrdersList}>
                  <div className="form-row">
                    <div className="form-group col-4">
                      <label for="startDate">Select Start Date</label>
                      <input
                        type="date"
                        value={this.state.startDate}
                        name="startDate"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group  col-4">
                      <label for="endDate">Select End Date</label>
                      <input
                        type="date"
                        value={this.state.endDate}
                        name="endDate"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label className="opacity-0">.</label> <br />
                      <button
                        type="submit"
                        className="btn btn-primary px-4 shadow"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


        <div className="row mb-4">
            <div className="col-6 col-md-3 mb-2">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Order Count
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.ordersCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-2">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Bonus
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.bonus}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-2">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Total Earnings
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.totalEarnings}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-2">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Balance
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.balance}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              DeliveryBoy Order List
            </h4>
            <button
              className="float-right btn btn-outline-dark"
              onClick={this.downloadPDF}
            >
              Print
            </button>
          </div>

          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.orders}
                columns={this.state.columns}
                pagination={pagination}
                wrapperClasses="table-responsive"
              />
            )}
          </div>
        </div>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              DeliveryBoy Reward List
            </h4>
            {/* <button
              className="float-right btn btn-outline-dark"
              onClick={this.downloadPdfRewards}
            >
              Print
            </button> */}
          </div>

          <div className="card-body">
            {this.state.columnsTwo.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.rewards}
                columns={this.state.columnsTwo}
                pagination={pagination}
                wrapperClasses="table-responsive"
              />
            )}
          </div>
        </div>
        {this.state.loaderLoading ? <Loader /> : null}
      </Layout>
    );
  }
}

export default withRouter(DeliveryboyOrdersList);
