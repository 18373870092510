import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermission } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";

class EditState extends Component {
  constructor(props) {
    super(props);
    this.state = {
        name: ''
    }
  }

  componentDidMount() {
    getPermission('State').then(data => {
        console.log('eehhh ', data);       
        let token = localStorage.getItem('auth')
        axios.get(Config.BACKEND_API+ 'admin/state/' + this.props.match.params.id, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(res=> {
            console.log('aaaa', res)
            this.setState({
                
                name:res.data.state.name
            })
        })
        
    }).catch(err => {
        console.log('err ', err);
    })

}
handleChange = key => event => {
    this.setState({
        [key]: event.target.value,
    })
}
handleUpdate = e => {
    e.preventDefault();
    console.log(this.state)
    let token = localStorage.getItem('auth')
    axios.post(Config.BACKEND_API+ 'admin/state/update/' + this.props.match.params.id, {
        name:this.state.name
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).then(res=> {
        console.log('aaaa', res)
        this.props.history.push('/state')

    })
}
  render() {
    console.log("propssssssss", this.props);
    return (
      <Layout>
        <h1>Edit State</h1>
        <form onSubmit={this.handleUpdate}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleChange('name')}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
           Update
          </button>
        </form>
      </Layout>
    );
  }
}

export default withRouter(EditState);
