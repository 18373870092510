import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

const { SearchBar, ClearSearchButton } = Search;

class VendorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      vendor: [],
      isOpen: false,
      disabledId: "",
      showAlertModal: false,
      vendorId: "",
    };
  }

  componentDidMount() {
    getPermission("Vendor")
      .then((data) => {
        this.setState({
          data,
        });
        this.getVendorList();
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getVendorList = () => {
    let data = this.state.data;
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/vendor/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("dddddmms", res);
        this.setState({
          permissions: data.permissions,
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "name",
              text: "Name",
              searchable: true,
            },
            {
              dataField: "storeType",
              text: "Store",
              searchable: true,
            },
            {
              dataField: "totalOrders",
              text: "Total Orders",
              searchable: true,
            },
            {
              dataField: "earning",
              text: "Settlement Balance",
              searchable: true,
              formatter: (earning, row) => <span>₹{earning}</span>,
            },
            {
              dataField: "totalEarning",
              text: "Total Earning",
              searchable: true,
              formatter: (totalEarning, row) => <span>₹{totalEarning}</span>,
            },

            {
              dataField: "isOpen",
              text: "Is Shop Open",
              searchable: true,
              formatter: this.isShopOpen,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          vendor: res.data.vendor,
        });
      });
  };

  isShopOpen = (isOpen, row) => {
    return (
      <>
        <label htmlFor={row.id} className="toggle-btn mt-2">
          <div className="position-relative">
            <input
              id={row.id}
              className="d-none"
              type="checkbox"
              name="isOpen"
              value={row.isOpen}
              defaultChecked={row.isOpen}
              disabled={this.state.disabledId === row.id}
              onClick={() => this.isOpenToggle(row.isOpen, row.id)}
            />
            <div className="rounded-pill shadow-inner toggle-pill" />
            <div className="dot position-absolute bg-white rounded-circle" />
          </div>
        </label>
      </>
    );
  };

  isOpenToggle = (isOpen, id) => {
    this.setState({ disabledId: id });
    let vendor = this.state.vendor;
    let index = _.findIndex(vendor, { id });
    let status = vendor[index].isOpen;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/vendor/status/update/" + id,
        {
          isOpen: !status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        vendor[index].isOpen = !status;
        this.setState({
          vendor,
          disabledId: "",
        });
      });
  };

  print = (id) => {
    this.setState({
      printDisableId: id,
    });
    let token = localStorage.getItem("auth");
    axios
      .post(Config.BACKEND_API + "admin/reports/vendor/orders/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        window.location.href = res.data.path;
        this.setState({ printDisableId: "" });
      });
  };

  deleteConfirmationPopup = (vendor) => {
    this.setState({
      showAlertModal: true,
      vendorId: vendor.id,
    });
  };

  handleCancel = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  handleDeleteVendor = () => {
    let id = this.state.vendorId;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/vendor/delete/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          showAlertModal: false,
        });
        this.getVendorList();
      });
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
      <div className="d-flex align-items-center ">
        {this.state.permissions.edit && (
          <Link to={"/vendor/edit/" + row.id}>
            <button type="button" class="btn btn-warning mr-2">
              <i class="fas fa-pen"></i>
            </button>
          </Link>
        )}

        {this.state.permissions.view && (
          <Link to={"/vendor/view/" + row.id}>
            <button type="button" class="btn btn-primary mr-2">
              <i class="fa fa-eye"></i>
            </button>
          </Link>
        )}

        <button
          onClick={() => this.deleteConfirmationPopup(row)}
          type="button"
          class="btn btn-danger mr-1"
        >
          <i class="fas fa-trash"></i>
        </button>

        <Link to={"/product/" + row.id}>
          <button
            type="button"
            class="btn btn-white border font-weight-normal ml-1 mr-2"
          >
            View Products
          </button>
        </Link>

        <Link to={"/vendororder/" + row.id}>
          <button
            type="button"
            class="btn btn-outline-info font-weight-normal ml-1 mr-2"
          >
            Vendor Orders
          </button>
        </Link>

        <button
          className="btn btn-dark"
          onClick={() => this.print(row.id)}
          disabled={this.state.printDisableId === row.id}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-printer"
            viewBox="0 0 16 16"
          >
            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
          </svg>
        </button>
        </div>
      </>
    );
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Vendor</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Vendor List
            </h4>
            {this.state.permissions.add && (
              <Link to={"/vendor/add/"} className="float-right">
                <button type="button" class="btn btn-primary mr-1">
                  <i class="fas fa-plus"></i> Add
                </button>
              </Link>
            )}
          </div>
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <ToolkitProvider
                bootstrap4
                keyField="id"
                data={this.state.vendor}
                columns={this.state.columns}
                search
              >
                {(props) => (
                  <>
                    <div className="d-flex align-items-center mb-3">
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={pagination}
                      wrapperClasses="table-responsive"
                    />
                  </>
                )}
              </ToolkitProvider>
            )}
          </div>
        </div>

        {/* #popup */}
        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">
                Are you sure you want to delete this vendor ?
              </h6>

              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleDeleteVendor}
                  className="btn btn-success mr-3"
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(VendorList);
