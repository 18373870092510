import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import {MultiSelect} from "react-multi-select-component";

class CouponAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      code: "",
      isFixedCharge: false,
      isDeliveryChargeBased: false,
      isStoreBased: false,
      amount: "",
      percentage: "",
      amountLimit: "",
      isLimited: false,
      availableCoupon: "",
      store: [],
      storeList: [],
      minimumPurchaseAmount: "",
    };
  }

  componentDidMount() {
    getPermissionAccess("Coupon", "add")
      .then(() => {
        let token = localStorage.getItem("auth");
        axios
          .get(Config.BACKEND_API + "admin/vendor/list", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then(async (storeList) => {
            let list = await Promise.all(
              _.map(storeList.data.vendor, (store) => {
                return { label: store.name, value: store.id };
              })
            );
            this.setState({
              storeList: list,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
    let token = localStorage.getItem("auth");
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleBooleanChange = (e) => {
    let current = this.state[e.target.name];
    this.setState({ [e.target.name]: !current });
  };

  handleMultiSelect = async (e) => {
    let list = await Promise.all(
      _.map(e, (store) => {
        return { id: store.value, label: store.label, value: store.value };
      })
    );
    this.setState({
      store: list,
    });
  };

  handleAdd = (e) => {
    e.preventDefault();
    let data = {
      name: this.state.name,
      code: this.state.code,
      isFixedCharge: this.state.isFixedCharge,
      isDeliveryChargeBased: this.state.isDeliveryChargeBased,
      isStoreBased: this.state.isStoreBased,
      minimumPurchaseAmount:this.state.minimumPurchaseAmount,
      //   amount: this.state,
      //   percentage: this.state,
      //   amountLimit: this.state,
      isLimited: this.state.isLimited,
      //   availableCoupon: this.state,
        store:[],
    };
    if (this.state.isFixedCharge) {
      data.amount = this.state.amount;
    } else {
      data.percentage = this.state.percentage;
      data.amountLimit = this.state.amountLimit;
    }
    if (this.state.isStoreBased) {
      data.store = this.state.store;
    }
    if (this.state.isLimited) {
      data.availableCoupon = this.state.availableCoupon;
    }
    let token = localStorage.getItem("auth");
    axios
      .post(Config.BACKEND_API + "admin/coupons/add/", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.props.history.push("/coupon");
      });
  };

  render() {
    console.log("tttt ", this.state);
    return (
      <Layout>
        <h1 className="b-4">Add Coupon</h1>
        <div className="row pb-5">
          <div className="col-6">
            <form onSubmit={this.handleAdd}>
              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group col-md-6" required>
                  <label htmlFor="code">Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    placeholder="code"
                    name="code"
                    value={this.state.code}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="isDeliveryChargeBased">
                    Charging Based On
                  </label>
                  <select
                    class="form-control"
                    id="isDeliveryChargeBased"
                    name="isDeliveryChargeBased"
                    onChange={this.handleBooleanChange}
                    required
                  >
                    <option value={false}>Product</option>
                    <option value={true}>Delivery</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="isFixedCharge">Charging Type</label>
                  <select
                    class="form-control"
                    id="isFixedCharge"
                    name="isFixedCharge"
                    onChange={this.handleBooleanChange}
                    required
                  >
                    <option value={false}>Percentage</option>
                    <option value={true}>Fixed</option>
                  </select>
                </div>
                {this.state.isFixedCharge ? (
                  <div className="form-group col-md-6" required>
                    <label htmlFor="amount">Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      id="amount"
                      placeholder="amount"
                      name="amount"
                      value={this.state.amount}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                ) : (
                  <>
                    <div className="form-group col-md-6" required>
                      <label htmlFor="percentage">Percentage</label>
                      <input
                        type="number"
                        className="form-control"
                        id="percentage"
                        placeholder="percentage"
                        name="percentage"
                        value={this.state.percentage}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6" required>
                      <label htmlFor="amountLimit">Amount Limit</label>
                      <input
                        type="number"
                        className="form-control"
                        id="amountLimit"
                        placeholder="amount limit"
                        name="amountLimit"
                        value={this.state.amountLimit}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </>
                )}
                <div className="form-group col-md-6">
                  <label htmlFor="isStoreBased">Charge Applies to</label>
                  <select
                    class="form-control"
                    id="isStoreBased"
                    name="isStoreBased"
                    onChange={this.handleBooleanChange}
                    required
                  >
                    <option value={false}>All Stores</option>
                    <option value={true}>Specific Stores</option>
                  </select>
                </div>
                {this.state.isStoreBased && (
                  <div className="form-group col-md-6">
                    <label htmlFor="isStoreBased">Select Stores</label>
                    <MultiSelect
                      options={this.state.storeList}
                      value={this.state.store}
                      onChange={this.handleMultiSelect}
                      labelledBy="Select"
                    />
                  </div>
                )}
                <div className="form-group col-md-6">
                  <label htmlFor="isLimited">Coupon Usage</label>
                  <select
                    class="form-control"
                    id="isLimited"
                    name="isLimited"
                    onChange={this.handleBooleanChange}
                    required
                  >
                    <option value={false}>Unlimited</option>
                    <option value={true}>Limited</option>
                  </select>
                </div>
                {this.state.isLimited && (
                  <div className="form-group col-md-6" required>
                    <label htmlFor="availableCoupon">Available Coupon</label>
                    <input
                      type="number"
                      className="form-control"
                      id="availableCoupon"
                      placeholder="Available Coupon"
                      name="availableCoupon"
                      value={this.state.availableCoupon}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                )}

                <div className="form-group col-md-6" required>
                  <label htmlFor="minimumPurchaseAmount">Minimum Purchase Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="minimumPurchaseAmount"
                    placeholder="Minimum Purchase Amount"
                    name="minimumPurchaseAmount"
                    value={this.state.minimumPurchaseAmount}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(CouponAdd);
