import axios from "axios";
import { Component } from "react";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import { withRouter } from "react-router-dom";

class EditPickUpCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseCharge: "",
      baseChargeDistance: "",
      additionalChargePerKM: "",
      sgst: "",
      cgst: "",
    };
  }

  componentDidMount() {
    getPermissionAccess("PickUpCharge", "edit").catch((err) => {
      this.props.history.push("/");
    });
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/pick-up/charge/get-charges", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let {
          baseCharge,
          baseChargeDistance,
          additionalChargePerKM,
          sgst,
          cgst,
        } = res.data.charges;
        this.setState({
          baseCharge: baseCharge,
          baseChargeDistance: baseChargeDistance,
          additionalChargePerKM: additionalChargePerKM,
          sgst: sgst,
          cgst: cgst,
        });
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/pick-up/charge/update-charges",
        {
          baseCharge: this.state.baseCharge,
          baseChargeDistance: this.state.baseChargeDistance,
          additionalChargePerKM: this.state.additionalChargePerKM,
          cgst: this.state.cgst,
          sgst: this.state.sgst,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/pickupcharge");
      });
  };

  render() {
    return (
      <Layout>
        <h1 className="mb-4">Edit PickUp Charge</h1>
        <div className="row">
          <div className="col-6">
            <form onSubmit={this.handleUpdate}>
              <div className="form-group ">
                <label htmlFor="basecharge">Base Charge</label>
                <input
                  type="number"
                  className="form-control"
                  id="basecharge"
                  placeholder="Base Charge"
                  name="baseCharge"
                  value={this.state.baseCharge}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group ">
                <label htmlFor="baseChargeDistance">
                  Base Charge Distance (Km)
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="baseChargeDistance"
                  placeholder="Delivery Charge"
                  name="baseChargeDistance"
                  value={this.state.baseChargeDistance}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="additionalcharge">Additional Charge / Km</label>
                <input
                  type="number"
                  className="form-control"
                  id="additionalcharge"
                  placeholder="Additional Charge / Km"
                  name="additionalChargePerKM"
                  value={this.state.additionalChargePerKM}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="cgst">CGST %</label>
                <input
                  type="number"
                  className="form-control"
                  id="cgst"
                  placeholder="CGST"
                  name="cgst"
                  value={this.state.cgst}
                  max="100"
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="sgst">SGST %</label>
                <input
                  type="number"
                  className="form-control"
                  id="sgst"
                  placeholder="SGST"
                  name="sgst"
                  max="100"
                  value={this.state.sgst}
                  onChange={this.handleChange}
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(EditPickUpCharge);
