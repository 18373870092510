import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import {MultiSelect} from "react-multi-select-component";
import { getPermissionAccess } from "../../../libs/auth";


class ScratchCardAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      amount: "",
      minOrderPrice: "",
      maxOrderPrice: "",
      type: "normal",
      typeList: [
        {
          name: "normal",
        },
        {
          name: "premium",
        },
      ],
      store: [],
      storeList: [],
    };
  }

  componentDidMount() {
    getPermissionAccess("ScratchCard", "add").catch((err) => {
      this.props.history.push("/");
    });
    let token = localStorage.getItem("auth");
    this.setState({ token: token });
    axios
      .get(Config.BACKEND_API + "admin/vendor/list", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(async (storeList) => {
        let list = await Promise.all(
          _.map(storeList.data.vendor, (store) => {
            return { label: store.name, value: store.id };
          })
        );
        this.setState({
          storeList: list,
        });
      });
  }

  handleMultiSelect = async (e) => {
    let list = await Promise.all(
      _.map(e, (store) => {
        return { id: store.value, label: store.label, value: store.value };
      })
    );
    this.setState({
      store: list,      
    });

  };

  handleChange = (e) => {     
    this.setState({ [e.target.name]: e.target.value }); 
  };

  handleAdd = (e) => {
    e.preventDefault();
    let storeId =  _.map(this.state.store, 'id');      
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/scratch-card/add",
        {
          name: this.state.name,
          amount: this.state.amount,
          minOrderPrice: this.state.minOrderPrice,
          maxOrderPrice: this.state.maxOrderPrice,
          type: this.state.type,
          stores:storeId          
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/scratchcard");
      });
  };

  render() {
    return (
      <Layout>
        <h1 className="b-4">Add Scratch Card</h1>
        <div className="row pb-5">
          <div className="col-6">
            <form onSubmit={this.handleAdd}>
              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className="form-group col-md-6" required>
                  <label htmlFor="type">Type</label>
                  <select
                    class="form-control"
                    name="type"
                    onChange={this.handleChange}
                    required
                  >
                    {this.state.typeList.map((e) => (
                      <option value={e.name}>{e.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    placeholder="Amount"
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className="form-group col-md-6" required>
                  <label htmlFor="minOrderPrice">Min Order Price</label>
                  <input
                    type="number"
                    className="form-control"
                    id="minOrderPrice"
                    placeholder="Min Order Price"
                    name="minOrderPrice"
                    value={this.state.minOrderPrice}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="maxOrderPrice">Max Order Price</label>
                  <input
                    type="maxOrderPrice"
                    className="form-control"
                    id="maxOrderPrice"
                    placeholder="Max Order Price"
                    name="maxOrderPrice"
                    value={this.state.maxOrderPrice}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              {this.state.type === "normal" ? (
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="isStoreBased">Select Stores</label>
                    <MultiSelect
                      options={this.state.storeList}
                      value={this.state.store}
                      onChange={this.handleMultiSelect}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(ScratchCardAdd);
