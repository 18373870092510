import React, { Component } from "react";
import _ from "lodash";

export default class GetVarientField extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      id:"",
      type: "",
      price: "",
      cgst: "",
      sgst: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      id: this.props.fields.id,
      type: this.props.fields.type,
      price: this.props.fields.price,
      cgst: this.props.fields.cgst,
      sgst: this.props.fields.sgst,
    });
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.handleFieldChange(this.props.index, this.state);
      }
    );
  };

  render() {
    return (
      <div className="card p-3 mb-4">
        <div className="form-row">
          <div className="form-group col-md-6" required>
            <label htmlFor="price">Type</label>
            <input
              type="text"
              className="form-control"
              id="type"
              placeholder="Type"
              name="type"
              value={this.props.fields.type}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="form-group col-md-6" required>
            <label htmlFor="price">Price</label>
            <input
              type="number"
              className="form-control"
              id="price"
              placeholder="Price"
              name="price"
              value={this.props.fields.price}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6" required>
            <label htmlFor="cgst">cgst</label>
            <input
              type="number"
              className="form-control"
              id="cgst"
              placeholder="cgst"
              name="cgst"
              value={this.props.fields.cgst}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="form-group col-md-6" required>
            <label htmlFor="sgst">sgst</label>
            <input
              type="number"
              className="form-control"
              id="sgst"
              placeholder="sgst"
              name="sgst"
              value={this.props.fields.sgst}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
      </div>
    );
  }
}
