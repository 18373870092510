import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link } from "react-router-dom";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class CouponsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      coupons: [],
      showAlertModal: false,
      couponId: "",
    };
  }

  componentDidMount() {
    getPermission("Coupon")
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState({
          permissions: data.permissions,
          token: token,
        });
        this.getCouponList();
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  actionsFormatter = (cell, row) => {
    return (
      <>
        {this.state.permissions.delete && (
          <button
            onClick={() => this.deleteConfirmationPopup(row)}
            type="button"
            class="btn btn-danger mr-1"
          >
            <i class="fas fa-trash"></i>
          </button>
        )}
      </>
    );
  };

  getCouponList = () => {
    axios
      .get(Config.BACKEND_API + "admin/coupons/list", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {      
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "name",
              text: "Name",
              searchable: true,
            },
            {
              dataField: "code",
              text: "Coupon Code",
              searchable: true,
            },
            {
              dataField: "minimumPurchaseAmount",
              text: "Min Purchase Amt",
              searchable: true,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          coupons: res.data.coupons,
        });
      });
  };

  deleteConfirmationPopup = (coupon) => { 
    this.setState({
      showAlertModal: true,
      couponId: coupon.id,
    });
  };

  handleCancel = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  handleDeleteCoupon = () => {
    let id = this.state.couponId;  
    axios
      .post(
        Config.BACKEND_API + "admin/coupons/delete/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          showAlertModal: false,
        });
        this.getCouponList();
      });
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Coupons</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Coupons List
            </h4>
            <Link to={"/coupon/add/"} className="float-right">
              <button type="button" class="btn btn-primary mr-1">
                <i class="fas fa-plus"></i> Add
              </button>
            </Link>
          </div>
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.coupons}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>

        {/* #popup */}
        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">
                Are you sure you want to delete this coupon ?
              </h6>

              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleDeleteCoupon}
                  className="btn btn-success mr-3"
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CouponsList;
