import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
// import { Modal } from "react-bootstrap";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { getPermission } from "../../../libs/auth.js";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class BannerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      BannerList: [],
      permissions: [],
      showAlertModal: false,
      bannerId: "",
    };
  }

  componentDidMount() {
    getPermission("Banner").then((data) => {
      let token = localStorage.getItem("auth");
      this.setState({
        permissions: data.permissions,
        token: token,
      });
      this.getBannerList();
    });
  }

  getBannerList = () => {
    axios
      .get(Config.BACKEND_API + "admin/banner/list", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "bannerImageUrl",
              text: "Banner",
              searchable: true,
              formatter: this.preivewImage,
            },
            {
              dataField: "isShowing",
              text: "Is Banner Showing",
              searchable: true,
              formatter: this.isShowing,
            },
            {
              dataField: "url",
              text: "URL",
              searchable: true,
            },
            {
              dataField: "store.name",
              text: "Store",
              searchable: true,
              formatter: this.store,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          BannerList: res.data.banners,
        });
      });
  };

  isShowing = (isShowing, row) => {
    return (
      <>
        <label htmlFor={row.id} className="toggle-btn mt-2">
          <div className="position-relative">
            <input
              id={row.id}
              className="d-none"
              type="checkbox"
              name="isShowing"
              value={row.isShowing}
              defaultChecked={row.isShowing}
              disabled={this.state.disabledId === row.id}
              onClick={() => this.isShowingToggle(row.isShowing, row.id)}
            />
            <div className="rounded-pill shadow-inner toggle-pill" />
            <div className="dot position-absolute bg-white rounded-circle" />
          </div>
        </label>
      </>
    );
  };

  isShowingToggle = (isShowing, id) => {
    this.setState({ disabledId: id });
    let banner = this.state.BannerList;
    let index = _.findIndex(banner, { id });
    let status = banner[index].isShowing;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/banner/update/showing/" + id,
        {
          isShowing: !status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        banner[index].isShowing = !status;
        this.setState({
          banner,
          disabledId: "",
        });
      });
  };

  preivewImage = (url) => {
    return (
      <>
        <img src={url} className="banner-img"></img>
      </>
    );
  };

  deleteConfirmationPopup = (banner) => {
    this.setState({
      showAlertModal: true,
      bannerId: banner.id,
    });
  };

  handleCancel = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  handleDeleteBanner = () => {
    let id = this.state.bannerId;   
    axios
      .post(
        Config.BACKEND_API + "admin/banner/delete/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          showAlertModal: false,
        });
        this.getBannerList();
      });
  };

  actionsFormatter = (cell, row) => {
    console.log("del", this.state.permissions);
    return (
      <>
        {this.state.permissions.edit && (
          <Link to={"/banner/edit/" + row.id}>
            <button type="button" class="btn btn-warning mr-1">
              <i class="fas fa-pen"></i>
            </button>
          </Link>
        )}
        {this.state.permissions.delete && (
          <button
            onClick={() => this.deleteConfirmationPopup(row)}
            type="button"
            class="btn btn-danger mr-1"
          >
            <i class="fas fa-trash"></i>
          </button>
        )}
      </>
    );
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Banner</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Banner List
            </h4>
            <Link to={"/banner/add/"} className="float-right">
              <button type="button" class="btn btn-primary mr-1">
                <i class="fas fa-plus"></i> Add
              </button>
            </Link>
          </div>
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.BannerList}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>

        {/* #popup */}
        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">
                Are you sure you want to delete this banner ?
              </h6>

              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleDeleteBanner}
                  className="btn btn-success mr-3"
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default BannerList;
