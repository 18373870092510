import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import _ from "lodash";
import Layout from "../../layout/mainlayout/Layout";
import ImgUpload from "../../layout/upload/ImgUpload";

class DeliveryboyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      state: "",
      city: "",
      phoneCode: "",
      phoneNumber: "",
      email: "",
      altPhoneCode: "",
      altPhoneNumber: "",
      vehicleNo: "",
      rcBookImageUrl: "",
      adhaarNo: "",
      adhaarImageUrl: "",
      bankName: "",
      bankAccountNo: "",
      bankIfscCode: "",
      states: [],
      cities: [],
      latitude: "",
      longitude: "",
      isLoading:false,
    };
  }

  componentDidMount() {
    getPermissionAccess("DeliveryBoy", "edit")
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState({ token: token });
        axios
          .get(
            Config.BACKEND_API +
              "admin/deliveryBoy/getById/" +
              this.props.match.params.id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            this.setState({
              name: res.data.deliveryBoy.name,
              address: res.data.deliveryBoy.address,
              state: res.data.deliveryBoy.state,
              city: res.data.deliveryBoy.city,
              phoneCode: res.data.deliveryBoy.phoneCode,
              altPhoneCode: res.data.deliveryBoy.altPhoneCode,
              phoneNumber: res.data.deliveryBoy.phoneNumber,
              email: res.data.deliveryBoy.email,
              altPhoneNumber: res.data.deliveryBoy.altPhoneNumber,
              vehicleNo: res.data.deliveryBoy.vehicleNo,
              rcBookImageUrl: res.data.deliveryBoy.rcBookImageUrl,
              adhaarNo: res.data.deliveryBoy.adhaarNo,
              adhaarImageUrl: res.data.deliveryBoy.adhaarImageUrl,
              bankName: res.data.deliveryBoy.bankName,
              bankAccountNo: res.data.deliveryBoy.bankAccountNo,
              bankIfscCode: res.data.deliveryBoy.bankIfscCode,
              latitude: res.data.deliveryBoy.centralLatLng.coordinates[0],
              longitude: res.data.deliveryBoy.centralLatLng.coordinates[1],
            });

            axios
              .get(Config.BACKEND_API + "admin/state/list", {
                headers: {
                  Authorization: "Bearer " + token,
                },
              })
              .then((states) => {
                this.setState({
                  states: states.data.states,
                });
                this.handleStateChange({ target: { value: res.data.deliveryBoy.state } });
              });
          });
      })

      .catch((err) => {
        console.log("err ", err);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleImageChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };

  handleStateChange = (e) => {
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/city/list?state_id=" + e.target.value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((cities) => {
        this.setState({
          cities: cities.data.cities,
          // city: cities.data.cities[0].id,
          state: e.target.value,
        });
      });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/deliveryBoy/update/" +
          this.props.match.params.id,
        {
          name: this.state.name,
          address: this.state.address,
          state: this.state.state,
          city: this.state.city,
          phoneCode: this.state.phoneCode,
          altPhoneCode: this.state.altPhoneCode,
          phoneNumber: this.state.phoneNumber,
          email: this.state.email,
          altPhoneNumber: this.state.altPhoneNumber,
          vehicleNo: this.state.vehicleNo,
          rcBookImageUrl: this.state.rcBookImageUrl,
          adhaarNo: this.state.adhaarNo,
          adhaarImageUrl: this.state.adhaarImageUrl,
          bankName: this.state.bankName,
          bankAccountNo: this.state.bankAccountNo,
          bankIfscCode: this.state.bankIfscCode,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/deliveryboy");
      });
  };

  getLocation = () => {
    this.setState({
      isLoading: true,
    });

    let _this = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      _this.setState({
        isLoading: false,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
    console.log("ss", this.state.latitude);
  };

  render() {
    return (
      <Layout>
        <h1 className="mb-4">Edit Delivery Boy</h1>
        <div className="row">
          <div className="col-6 pb-5">
            <form onSubmit={this.handleUpdate}>
              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className="form-group col-md-6" required>
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder="Address"
                    name="address"
                    value={this.state.address}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="state">State</label>
                  <select
                    class="form-control"
                    id="state"
                    name="state"
                    onChange={this.handleStateChange}
                    required
                  >
                    <option disabled selected>
                      Select State
                    </option>
                    {this.state.states.map((e) => (
                      <option value={e.id} selected={this.state.state === e.id}>
                        {_.startCase(_.toLower(e.name))}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="city">City</label>
                  <select
                    class="form-control"
                    id="city"
                    name="city"
                    onChange={this.handleChange}
                    required
                  >
                    <option selected>Select City</option>
                    {this.state.cities.map((e) => (
                      <option value={e.id} selected={this.state.city === e.id}>
                        {_.startCase(_.toLower(e.name))}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group col-md-6" required>
                  <label htmlFor="phoneNumber">Alternate Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="altPhoneNumber"
                    placeholder="Alternate Phone Number"
                    name="altPhoneNumber"
                    value={this.state.altPhoneNumber}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="vehicleNo">Vehicle Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="vehicleNo"
                    placeholder="Vehicle Number"
                    name="vehicleNo"
                    value={this.state.vehicleNo}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-6" required>
                  <label htmlFor="adhaarNo">Aadhaar Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="adhaarNo"
                    placeholder="Aadhaar Number"
                    name="adhaarNo"
                    value={this.state.adhaarNo}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="bankName">Bank Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bankName"
                    placeholder="Bank Name"
                    name="bankName"
                    value={this.state.bankName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="bankAccountNo">Bank Account Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="bankAccountNo"
                    placeholder="Bank Account Number"
                    name="bankAccountNo"
                    value={this.state.bankAccountNo}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-6" required>
                  <label htmlFor="bankIfscCode">Bank IFSC Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bankIfscCode"
                    placeholder="Bank IFSC Code"
                    name="bankIfscCode"
                    value={this.state.bankIfscCode}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="latitude">Latitude</label>
                  <input
                    type="number"
                    className="form-control"
                    id="latitude"
                    placeholder="Latitude"
                    name="latitude"
                    value={this.state.latitude}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="longitude">Longitude</label>
                  <input
                    type="text"
                    className="form-control"
                    id="longitude"
                    placeholder="Longitude"
                    name="longitude"
                    value={this.state.longitude}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-4">
                  <label className="opacity-0">.</label> <br />
                  <button
                    type="button"
                    onClick={this.getLocation}
                    className="btn btn-secondary w-100 d-flex justify-content-center align-items-center"
                  >
                    {this.state.isLoading ? (
                      <div
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"
                      ></div>
                    ) : (
                      ""
                    )}
                    Get Location
                  </button>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="rcBookImageUrl">RC BookImage</label>
                  {this.state.rcBookImageUrl !== "" && (
                    <ImgUpload
                      name={"rcBookImageUrl"}
                      token={this.state.token}
                      actionUrl={"admin/upload"}
                      handleImageChange={this.handleImageChange}
                      image={this.state.rcBookImageUrl}
                    />
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="adhaarImageUrl">Adhaar Image</label>
                  {this.state.adhaarImageUrl !== "" && (
                    <ImgUpload
                      name={"adhaarImageUrl"}
                      token={this.state.token}
                      actionUrl={"admin/upload"}
                      handleImageChange={this.handleImageChange}
                      image={this.state.adhaarImageUrl}
                    />
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(DeliveryboyEdit);
