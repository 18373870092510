import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class PickUpDropList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      pickUpDrops: [],
      permissions: [],
    };
  }

  componentDidMount() {
    getPermission("PickUpDrop").then((data) => {
      let token = localStorage.getItem("auth");
      axios
        .get(Config.BACKEND_API + "admin/pick-up-drop/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          this.setState({
            permissions: data.permissions,
            columns: [
              {
                dataField: "id",
                text: "ID",
                isDummyField: true,
                formatter: (cell, row, rowIndex) => ++rowIndex,
              },
              {
                dataField: "pickUpNo",
                text: "Pick Up No",
                searchable: true,
              },
              {
                dataField: "createdAt",
                text: "PickedUp Time",
                searchable: true,
                formatter: (createdAt, row) => (
                  <span>
                    {" "}
                    {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                  </span>
                ),
              },
              {
                dataField: "pickUpStatus",
                text: "Pick Up Status",
                searchable: true,
                formatter: this.pickUpStatus,
              },
              {
                dataField: "pickUpAddress",
                text: "Pick Up Address",
                searchable: true,
              },

              {
                dataField: "dropAddress",
                text: "Drop Address",
                searchable: true,
              },

              {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                csvExport: false,
                formatter: this.actionsFormatter,
              },
            ],
            pickUpDrops: res.data.pickUpDrops,
          });
        });
    })
    .catch((err) => {
      this.props.history.push("/");
    });
  }

  pickUpStatus = (status, row) => {
    if (status === 0) {
      return (
        <>
          <h5>
            <span className="badge badge-success">Delivered</span>
          </h5>
        </>
      );
    } else if (status === 1) {
      return (
        <>
          <h5>
            <span className="badge badge-warning">
              DeliveryBoy Assign Pending
            </span>
          </h5>
        </>
      );
    } else if (status === 2) {
      return (
        <>
          <h5>
            <span className="badge badge-primary">DeliveryBoy Assigned</span>
          </h5>
        </>
      );
    } else if (status === 3) {
      return (
        <>
          <h5>
            <span className="badge badge-primary">DeliveryBoy Accepted</span>
          </h5>
        </>
      );
    } else if (status === 4) {
      return (
        <>
          <h5>
            <span className="badge badge-info">Picked Up</span>
          </h5>
        </>
      );
    } else if (status === 11) {
      return (
        <>
          <h5>
            <span className="badge badge-danger">Cancelled</span>
          </h5>
        </>
      );
    }
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
        {this.state.permissions.view && (
          <Link to={"/pickupdrop/view/" + row.id}>
            <button type="button" class="btn btn-primary mr-2">
              <i class="fa fa-eye"></i>
            </button>
          </Link>
        )}
      </>
    );
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">PickUp And Drop</h1>
        <div className="card shadow mb-4">
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.pickUpDrops}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(PickUpDropList);
