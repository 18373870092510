import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import ImgUpload from "../../layout/upload/ImgUpload";

class CategoryEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      image:""
    };
  }

  componentDidMount() {
    getPermissionAccess('Category', 'edit')
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState({ token: token });
        axios
          .get(
            Config.BACKEND_API + "admin/category/" + this.props.match.params.id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            this.setState({
              name: res.data.category.name,
              image: res.data.category.image
            });
          });
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/category/update/" +
          this.props.match.params.id,
        {
          name: this.state.name,
          image: this.state.image
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/category");
      });
  };

  handleImageChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };


  render() {
    return (
      <Layout>
        <h1 className="mb-4">Edit Category</h1>
        <div className="row">
          <div className="col-6">
            <form onSubmit={this.handleUpdate}>
              <div className="form-group ">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Replace Image</label>
                  {this.state.image !== "" && (
                    <ImgUpload
                      name={"image"}
                      token={this.state.token}
                      actionUrl={"admin/upload"}
                      handleImageChange={this.handleImageChange}
                      image={this.state.image}
                    />
                  )}
                </div>
              </div>

              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(CategoryEdit);
