import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class CityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      cities: [],
      showModal: false,
      deleteId: "",
      printDisableId: "",
      isSurge: true,
      showAlertModal: false,
      surgeName: "",
      surgeAmount: "",
      surgeDeliveryBoyAmount: "",
      surgeIsActivated: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    getPermission("City")
      .then((data) => {
        this.setState({
          permissions: data.permissions,
        });
        let token = localStorage.getItem("auth");
        this.getCityList();
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getCityList = () => {
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/city/list", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((cities) => {
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "name",
              text: "Name",
              searchable: true,
            },
            {
              dataField: "state.name",
              text: "State",
              searchable: true,
            },
            {
              dataField: "surgeIsActivated",
              text: "Surge",
              searchable: true,
              formatter: this.isSurge,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          cities: cities.data.cities,
        });
      });
  };

  isSurge = (surgeIsActivated, row) => {
    console.log("sfdfdsfsd", surgeIsActivated);
    return (
      <>
        <label htmlFor={row.id} className="toggle-btn mt-2">
          <div className="position-relative">
            <input
              id={row.id}
              className="d-none"
              type="checkbox"
              name="isSurge"
              value={surgeIsActivated}
              defaultChecked={surgeIsActivated}
              onChange={() => this.handleCheckboxInput(row)}
            />
            <div className="rounded-pill shadow-inner toggle-pill" />
            <div className="dot position-absolute bg-white rounded-circle" />
          </div>
        </label>
      </>
    );
  };

  handleCheckboxInput = (surge) => {
    let current = this.state.isSurge;
    this.setState({ isSurge: !current });
    if (this.state.isSurge === true && surge.surgeIsActivated === false) {
      this.isSurgeToggle(surge);
    } else {
      this.isSurgeDeactivate(surge);
    }
  };

  isSurgeToggle = (surge) => {
    let id = surge.id;
    if (this.state.isSurge === true) {
      this.setState({
        showAlertModal: true,
        surgeId: id,
      });
    }
  };

  handleCancel = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.setState({
          isLoading: false,
          showAlertModal: false,
          isSurge: true,
        });
      }
    );
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isSurgeActive = () => {
    let id = this.state.surgeId;
    let cities = this.state.cities;
    let index = _.findIndex(cities, { id });
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/activate/surge/" + id,
        {
          surgeName: this.state.surgeName,
          surgeAmount: this.state.surgeAmount,
          surgeDeliveryBoyAmount: this.state.surgeDeliveryBoyAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        cities[index].surgeIsActivated = "true";
        this.setState({
          cities,
          showAlertModal: false,
          isSurge: true,
        });
      });
  };

  isSurgeDeactivate = (surge) => {
    let id = surge.id;
    let cities = this.state.cities;
    let index = _.findIndex(cities, { id });
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/disable/surge/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        cities[index].surgeIsActivated = "false";
        this.setState({
          cities,
          showAlertModal: false,
          isSurge: true,
        });
      });
  };

  print = (id, type) => {
    this.setState({
      printDisableId: id,
    });
    let token = localStorage.getItem("auth");
    axios
      .get(
        Config.BACKEND_API +
          "admin/reports/city-based/" +
          type +
          "/orders/" +
          id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        window.location.href = res.data.path;
        this.setState({ printDisableId: "" });
        // payout[index].status = "rejected";
        // this.setState({ isLoading: true }, () => {
        //   this.setState({
        //     isLoading: false,
        //   });
        // });
      });
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
        {/* <button type="button" class="btn btn-primary mr-1" >
                <i class="fas fa-eye"></i>
            </button> */}
        <div className="d-flex">
          {this.state.permissions.edit && (
            <Link to={"/city/edit/" + row.id}>
              <button type="button" class="btn btn-warning mr-2">
                <i class="fas fa-pen"></i>
              </button>
            </Link>
          )}

          <button
            className="btn btn-dark mr-2"
            onClick={() => this.print(row.id, "vendor")}
            disabled={this.state.printDisableId === row.id}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-printer mr-1"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            Vendor
          </button>

          <button
            className="btn btn-dark"
            onClick={() => this.print(row.id, "delivery-boy")}
            disabled={this.state.printDisableId === row.id}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-printer mr-1"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            Delivery Boy
          </button>
        </div>
      </>
    );
  };

  // handleShowDeleteModal = (id) => {
  //   this.setState({
  //     showModal: true,
  //     deleteId: id,
  //   });
  // };

  // handleDelete = () => {
  //   axios.get(Config.BACKEND_API + 'admin/state/list', {
  //       headers: {
  //           Authorization: 'Bearer ' + token
  //       }
  //   }).then(states => {
  //       this.setState({
  //           states: states.data.states
  //       })
  //   })
  // };

  render() {
    return (
      <>
        <Layout>
          <>
            {/* <Modal show={this.state.showModal} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure to delete?</Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-secondary"
                  onClick={this.handleClose}
                >
                  Close
                </button>
                <button className="btn btn-danger" >
                  Delete
                </button>
              </Modal.Footer>
            </Modal> */}
            {/* Page Heading */}
            <h1 className="h3 mb-2 text-gray-800">Cities</h1>
            {/* <p className="mb-4">DataTables is a third party plugin that is used to generate the demo table below.
    For more information about DataTables, please visit the <a target="_blank" href="https://datatables.net">official DataTables documentation</a>.</p> */}
            {/* DataTales Example */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h4 className="m-0 font-weight-bold text-primary float-left">
                  List
                </h4>
                <Link to={"/city/add/"} className="float-right">
                  <button type="button" class="btn btn-primary mr-1">
                    <i class="fas fa-plus"></i> Add
                  </button>
                </Link>
              </div>
              <div className="card-body">
                {this.state.columns.length !== 0 && (
                  <BootstrapTable
                    keyField="id"
                    // data={this.state.cities}
                    data={
                      this.state.isLoading === false ? this.state.cities : []
                    }
                    columns={this.state.columns}
                    pagination={pagination}
                  />
                )}
              </div>
            </div>
          </>

          {/* #popup  */}

          <div
            className="d-pageoverlay"
            style={{ display: this.state.showAlertModal ? "block" : "none" }}
          >
            <div className="pagemodal-bg-wrapper">
              <div className="d-popup2">
                <div className="form-group">
                  <label htmlFor="surgeName">Surge Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="surgeName"
                    name="surgeName"
                    value={this.state.surgeName}
                    onChange={this.handleChange}
                    placeholder="Enter Name"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="surgeAmount">Surge Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="surgeAmount"
                    name="surgeAmount"
                    placeholder="Enter Amount"
                    value={this.state.surgeAmount}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="surgeDeliveryBoyAmount">
                    Surge DeliveryBoy Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="surgeDeliveryBoyAmount"
                    name="surgeDeliveryBoyAmount"
                    placeholder="Enter DeliveryBoy Amount"
                    value={this.state.surgeDeliveryBoyAmount}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="d-flex justify-content-center mt-3">
                  <button
                    onClick={this.isSurgeActive}
                    className="btn btn-primary  mr-3"
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withRouter(CityList);
