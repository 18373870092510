import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";

class EditCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      state: "",
      states: [],
      latitude: "",
      longitude: "",
      coordinates: [],
    };
  }

  componentDidMount() {
    getPermissionAccess("City", "add")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(
            Config.BACKEND_API + "admin/city/" + this.props.match.params.id,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((res) => {
            this.setState({
              name: res.data.city.name,
              state: res.data.city.state.id,
              latitude: res.data.city.centralLatLng.coordinates[0],
              longitude: res.data.city.centralLatLng.coordinates[1],
            });
          });
        axios
          .get(Config.BACKEND_API + "admin/state/list", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((states) => {
            this.setState({
              states: states.data.states,
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  handleChange = (key) => (event) => {
    this.setState({
      [key]: event.target.value,
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/city/update/" + this.props.match.params.id,
        {
          name: e.target.name.value,
          state: e.target.state.value,
          latitude: e.target.latitude.value,
          longitude: e.target.longitude.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/city");
      });
  };

  render() {
    console.log("latlon", this.state.coordinates[0]);
    return (
      <Layout>
        <h1>Edit City</h1>
        <form onSubmit={this.handleUpdate}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                // value={this.state.name}
                defaultValue={this.state.name}
                // onChange={this.handleChange('name')}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <select class="form-control" name="state">
                {this.state.states.map((e) => (
                  <option value={e.id} selected={this.state.state === e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="latitude">Latitude</label>
              <input
                type="text"
                className="form-control"
                id="latitude"
                placeholder="Latitude"
                name="latitude"
                defaultValue={this.state.latitude}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="longitude">Longitude</label>
              <input
                type="text"
                className="form-control"
                id="longitude"
                placeholder="Longitude"
                name="longitude"
                defaultValue={this.state.longitude}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </form>
      </Layout>
    );
  }
}

export default withRouter(EditCity);
