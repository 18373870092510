import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import _ from 'lodash'

class AddCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      states: [],
      cities: [],
      showState: false
    }
  }

  componentDidMount() {
    getPermissionAccess('Admin', 'add').then(states => {
      let token = localStorage.getItem('auth')
      axios.get(Config.BACKEND_API + 'admin/state/list', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(states => {
        this.setState({
          states: states.data.states
        })
      })
    }).catch(err => {
      this.props.history.push('/')
    })

  }

  handleChange = key => event => {
    this.setState({
      [key]: event.target.value,
    })
  }

  handleTypeChange = e => {
    console.log('eeeee', e.target.value);
    if (e.target.value) {
      this.setState({
        showState: true
      })
    }
  }

  handleStateChange = e => {
    console.log();
    let token = localStorage.getItem('auth')
    axios.get(Config.BACKEND_API + 'admin/city/list?state_id=' + e.target.value, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(cities => {
      this.setState({
        cities: cities.data.cities
      })
    })
  }

  handleAdd = e => {
    e.preventDefault();
    let token = localStorage.getItem('auth')
    axios.post(Config.BACKEND_API + 'admin/create', {
      name: e.target.name.value,
      type: e.target.type.value,
      email: e.target.email.value,
      password: e.target.password.value,
      state: this.state.showState ? e.target.state.value : undefined,
      city: this.state.showState ? e.target.city.value : undefined,
    }, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(res => {
      this.props.history.push('/admin')

    })
  }

  render() {
    console.log("propssssssss", this.props);
    return (
      <Layout>
        <h1>Add Admin</h1>
        <form onSubmit={this.handleAdd}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="type">Name</label>
              <select class="form-control" id="type" onChange={this.handleTypeChange} required>
                <option value="master">Master</option>
                <option value="franchise">Franchise</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                required
              />
            </div>
          </div>
          {this.state.showState &&
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="state">State</label>
                <select class="form-control" id="state" onChange={this.handleStateChange} required>
                  <option disabled selected>Select State</option>
                  {this.state.states.map(e => <option value={e.id}>{_.startCase(_.toLower(e.name))}</option>)}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="city">City</label>
                <select class="form-control" id="city" required>
                  {this.state.cities.map(e => <option value={e.id}>{_.startCase(_.toLower(e.name))}</option>)}
                </select>
              </div>
            </div>
          }
          <button type="submit" className="btn btn-primary">
            Create
          </button>
        </form>
      </Layout>
    );
  }
}

export default withRouter(AddCity);
