import Layout from "../../layout/mainlayout/Layout.js";
import { Component } from "react";
import { getPermissionAccess } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import axios from "axios";
import { withRouter } from "react-router-dom";
import _ from "lodash";

class VendorView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor: {},
      states: [],
      cities: [],
      coordinates: [],
    };
  }

  componentDidMount() {
    getPermissionAccess("Vendor", "view")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(
            Config.BACKEND_API +
              "admin/vendor/getById/" +
              this.props.match.params.id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            this.setState({
              vendor: res.data.vendor,
              coordinates: res.data.vendor.LatLng.coordinates,
            });
            axios
              .get(Config.BACKEND_API + "admin/state/list", {
                headers: {
                  Authorization: "Bearer " + token,
                },
              })
              .then((states) => {
                axios
                  .get(Config.BACKEND_API + "admin/city/list", {
                    headers: {
                      Authorization: "Bearer " + token,
                    },
                  })
                  .then((cities) => {
                    this.setState({
                      cities: cities.data.cities,
                      states: states.data.states,
                    });
                  });
              });
          });
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }
  render() {
    let data = this.state.vendor;
    return (
      <Layout>
        <h1 className="mb-4">Vendor</h1>
        <div className="row">
          <div className="col-6 pb-5">
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={data.name}
                    disabled
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder="Address"
                    name="address"
                    value={data.address}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="state">State</label>
                  <select
                    class="form-control"
                    id="state"
                    name="state"
                    style={{ appearance: "none" }}
                    disabled
                  >
                    {this.state.states.map((e) => (
                      <option
                        value={e.id}
                        selected={data.state === e.id}
                        disabled
                      >
                        {_.startCase(_.toLower(e.name))}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="city">City</label>
                  <select
                    class="form-control"
                    id="city"
                    name="city"
                    style={{ appearance: "none" }}
                    disabled
                  >
                    <option selected>Select City</option>
                    {this.state.cities.map((e) => (
                      <option value={e.id} selected={data.city === e.id}>
                        {_.startCase(_.toLower(e.name))}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={data.phoneNumber}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="phoneNumber">Alternate Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="altPhoneNumber"
                    placeholder="Alternate Phone Number"
                    name="altPhoneNumber"
                    value={data.altPhoneNumber}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    value={data.email}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="storeType">Store Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="subStoreType"
                    placeholder="Sub Store Type"
                    name="subStoreType"
                    value={data.storeType}
                    disabled
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="subStoreType">Sub Store Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="subStoreType"
                    placeholder="Sub Store Type"
                    name="subStoreType"
                    value={data.subStoreType}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="fssai">fssai</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fssai"
                    placeholder="Fssai"
                    name="fssai"
                    value={data.fssai}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="rateForTwo">Rate For Two</label>
                  <input
                    type="text"
                    className="form-control"
                    id="rateForTwo"
                    placeholder="Rate For Two"
                    name="rateForTwo"
                    value={data.rateForTwo}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="bankName">Bank Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bankName"
                    placeholder="Bank Name"
                    name="bankName"
                    value={data.bankName}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="bankAccountNo">Bank Account Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="bankAccountNo"
                    placeholder="Bank Account Number"
                    name="bankAccountNo"
                    value={data.bankAccountNo}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="bankIfscCode">Bank IFSC Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bankIfscCode"
                    placeholder="Bank IFSC Code"
                    name="bankIfscCode"
                    value={data.bankIfscCode}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="commissionPercentage">
                    Commission Percentage (%)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="commissionPercentage"
                    placeholder="Commission Percentage"
                    name="commissionPercentage"
                    max="100"
                    value={data.commissionPercentage}
                    disabled
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="deliveryDistanceLimit">
                    Delivery Distance Limit (Km)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="deliveryDistanceLimit"
                    placeholder="Delivery Distance Limit"
                    name="deliveryDistanceLimit"
                    max="50"
                    value={data.deliveryDistanceLimit}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="locationName">Location Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="locationName"
                    placeholder="Location Name"
                    name="locationName"
                    value={data.locationName}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="latitude">Latitude</label>
                  <input
                    type="number"
                    className="form-control"
                    id="latitude"
                    placeholder="Latitude"
                    name="latitude"
                    value={this.state.coordinates[0]}
                    disabled
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="longitude">Longitude</label>
                  <input
                    type="text"
                    className="form-control"
                    id="longitude"
                    placeholder="Longitude"
                    name="longitude"
                    value={this.state.coordinates[1]}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="openTime">Open Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="openTime"
                    placeholder="Open Time"
                    name="openTime"
                    value={data.openTime}
                    disabled
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="closeTime">Close Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="closeTime"
                    placeholder="Close Time"
                    name="closeTime"
                    value={data.closeTime}
                    disabled
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="cookingTime">Cooking Time (Minutes)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="cookingTime"
                    placeholder="Cooking Time"
                    name="cookingTime"
                    max="59"
                    value={data.cookingTime}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isPopularBrand"
                      name="isPopularBrand"
                      value={data.isPopularBrand}
                      checked={data.isPopularBrand}
                      disabled
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isPopularBrand"
                    >
                      Is Popular Brand
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isPureVeg"
                      name="isPureVeg"
                      checked={data.isPureVeg}
                      value={data.isPureVeg}
                      disabled
                    />
                    <label className="custom-control-label" htmlFor="isPureVeg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        className="mr-2"
                        viewBox="0 0 91.47 91.82"
                      >
                        <path
                          id="Path_7399"
                          data-name="Path 7399"
                          d="M2.94,0H91.47V91.82H0V0ZM45.73,19.96A25.945,25.945,0,1,1,19.78,45.91,25.952,25.952,0,0,1,45.73,19.96ZM85.59,5.88H5.88V85.94H85.59V5.88Z"
                          fill="#008300"
                        />
                      </svg>
                      Is Pure Veg
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isTopOfferPlusCashBack"
                      name="isTopOfferPlusCashBack"
                      value={data.isTopOfferPlusCashBack}
                      checked={data.isTopOfferPlusCashBack}
                      disabled
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isTopOfferPlusCashBack"
                    >
                      Is Top Offer Plus Cashback
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isNewlyLaunched"
                      name="isNewlyLaunched"
                      value={data.isNewlyLaunched}
                      checked={data.isNewlyLaunched}
                      disabled
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isNewlyLaunched"
                    >
                      Is Newly Launched
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Image</label>
                  <img src={data.image} className="vendorview-img"></img>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Logo</label>
                  <img src={data.logo} className="vendorview-img"></img>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(VendorView);
