import React, { Component } from "react";
import _ from "lodash";
import { getAllPermissions } from "../../../libs/auth";
import NavDropdownItem from "./NavDropdownItem";
import { Link } from "react-router-dom";

export default class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
      active: "",
      toggled: false,
      modules: [],
    };
  }

  componentDidMount() {
    let path = window.location.pathname;
    let file = path.split("/");
    console.log("ffffff ", file);
    getAllPermissions().then((modules) => {
      this.props.stopLoader()
      this.setState({
        modules,
        path,
        active: file[1],
      });
    });
  }

  render() {
    console.log(this.state.modules,"this.state.modules");
    return (
      <ul
        className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${
          this.props.mainToggled && "toggled"
        }`}
        id="accordionSidebar"
      >
        {/* Sidebar - Brand */}
        <a className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-text mx-3">Yummitto</div>
        </a>
        {/* Divider */}
        <hr className="sidebar-divider my-0" />
        {/* Nav Item - Dashboard */}
        <Link to="/">
          <li className={`nav-item ${this.state.active === "" && "active"}`}>
            <a className="nav-link">
              <i className="fas fa-fw fa-tachometer-alt" />
              <span>Dashboard</span>
            </a>
          </li>
        </Link>

        {_.find(
          this.state.modules,
          (o) => o.module === "CashOnDelivery" && o.view === true
        ) && (
          <Link to="/cashondelivery">
            <li
              className={`nav-item ${
                this.state.active === "cashondelivery" && "active"
              }`}
            >
              <a className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt" />
                <span>Cash On Delivery</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "Order" && o.view === true
        ) && (
          <Link to="/unassigned/list">
            <li
              className={`nav-item ${
                this.state.active === "unassigned" && "active"
              }`}
            >
              <a className="nav-link">
                <i className="fas fa-exclamation-circle" />
                <span>Unassigned Orders</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "Banner" && o.view === true
        ) && (
          <Link to="/banner/list">
            <li
              className={`nav-item ${
                this.state.active === "banner" && "active"
              }`}
            >
              <a className="nav-link">
                <i className="fas fa-image"></i>
                <span>Banner</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "ScratchCard" && o.view === true
        ) && (
          <Link to="/scratchcard">
            <li
              className={`nav-item ${
                this.state.active === "scratchcard" && "active"
              }`}
            >
              <a className="nav-link">
                <i className="fas fa-image"></i>
                <span>Scratch Card</span>
              </a>
            </li>
          </Link>
        )}
        {/* Divider */}
        {/* <hr className="sidebar-divider" /> */}
        {/* Heading */}
        {/* <div className="sidebar-heading">Interface</div> */}
        {/* Nav Item - Pages Collapse Menu */}
        {/* {_.find(this.state.modules, (o) => o.module === 'Vendor') &&
          <Link to="/vendor">
            <li className={`nav-item ${this.state.active === "vendor" && "active"}`}>
              <a className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt" />
                <span>Vendor</span>
              </a>
            </li>
          </Link>
        } */}
        {_.find(
          this.state.modules,
          (o) => o.module === "State" && o.view === true
        ) && (
          <Link to="/state">
            <li
              className={`nav-item ${
                this.state.active === "state" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-city"></i>
                <span>State</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "City" && o.view === true
        ) && (
          <Link to="/city">
            <li
              className={`nav-item ${this.state.active === "city" && "active"}`}
            >
              <a className="nav-link">
                <i class="fas fa-building"></i>
                <span>City</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "DeliveryCharge" && o.view === true
        ) && (
          <Link to="/delivery-charge">
            <li
              className={`nav-item ${
                this.state.active === "delivery-charge" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-rupee-sign"></i>
                <span>Deliver Charge </span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "PushNotification" && o.view === true
        ) && (
          <Link to="/pushnotification">
            <li
              className={`nav-item ${
                this.state.active === "pushnotification" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-rupee-sign"></i>
                <span>Push Notification</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "User" && o.view === true
        ) && (
          <Link to="/user">
            <li
              className={`nav-item ${this.state.active === "user" && "active"}`}
            >
              <a className="nav-link">
                <i class="fas fa-rupee-sign"></i>
                <span>Users</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "BusinessStatus" && o.view === true
        ) && (
          <Link to="/businessStatus">
            <li
              className={`nav-item ${
                this.state.active === "businessStatus" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-rupee-sign"></i>
                <span>Business Status</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "PickUpCharge" && o.view === true
        ) && (
          <Link to="/pickupcharge">
            <li
              className={`nav-item ${
                this.state.active === "pickupcharge" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-rupee-sign"></i>
                <span>PickUp Charge</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "DeliveryBoyEarning" && o.view === true
        ) && (
          <Link to="/deliveryboyearning">
            <li
              className={`nav-item ${
                this.state.active === "deliveryboyearning" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-rupee-sign"></i>
                <span>Delivery Boy Earining</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "DeliveryBoy" && o.view === true
        ) && (
          <Link to="/deliveryboy">
            <li
              className={`nav-item ${
                this.state.active === "deliveryboy" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-truck"></i>
                <span>Delivery Boy</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "Category" && o.view === true
        ) && (
          <Link to="/category">
            <li
              className={`nav-item ${
                this.state.active === "category" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-list"></i>
                <span>Category</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "Vendor" && o.view === true
        ) && (
          <Link to="/vendor">
            <li
              className={`nav-item ${
                this.state.active === "vendor" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-store"></i>
                <span>Vendor</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "Coupon" && o.view === true
        ) && (
          <Link to="/coupon">
            <li
              className={`nav-item ${
                this.state.active === "coupon" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-receipt"></i>
                <span>Coupons</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "Admin" && o.view === true
        ) && (
          <Link to="/admin">
            <li
              className={`nav-item ${
                this.state.active === "admin" && "active"
              }`}
            >
              <a className="nav-link">
                <i class="fas fa-user"></i>
                <span>Admin</span>
              </a>
            </li>
          </Link>
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "Payout" && o.view === true
        ) && (
          <Link to="/payout">
            <li className="nav-item">
              <a className="nav-link">
                <i class="fas fa-money-bill-wave-alt"></i>
                <span>Payout</span>
              </a>
            </li>
          </Link>
        )}

        
          <Link to="/settlements">
            <li className="nav-item">
              <a className="nav-link">
                <i class="fas fa-money-bill-wave-alt"></i>
                <span>Settlements</span>
              </a>
            </li>
          </Link>

          <Link to="/transactions">
            <li className="nav-item">
              <a className="nav-link">
                <i class="fas fa-money-bill-wave-alt"></i>
                <span>Transactions</span>
              </a>
            </li>
          </Link>
      

        {_.find(
          this.state.modules,
          (o) => o.module === "Order" && o.view === true
        ) && (
          <NavDropdownItem
            title="Order"
            list={[
              {
                name: "List All",
                link: "/order/list",
              },
              {
                name: "Vendor Pending",
                link: "/order/vendorpending",
              },
              {
                name: "DeliveryBoy Pending",
                link: "/order/deliveryboypending",
              },
              {
                name: "DeliveryBoy Accepted",
                link: "/order/deliveryboyaccepted",
              },
              {
                name: "Ready To Pickup",
                link: "/order/readytopickup",
              },
              {
                name: "Order PickedUp",
                link: "/order/pickedup",
              },
              {
                name: "Order Delivered",
                link: "/order/delivered",
              },
              {
                name: "Order Cancelled",
                link: "/order/cancelled",
              },
            ]}
          />
        )}

        {_.find(
          this.state.modules,
          (o) => o.module === "PickUpDrop" && o.view === true
        ) && (
          <NavDropdownItem
            title="PickUp and DropDown"
            list={[
              {
                name: "List All",
                link: "/pickupdrop",
              },
              {
                name: "DeliveryBoy Assign Pending",
                link: "/pickupdrop/deliveryboynotassigned",
              },
              {
                name: "DeliveryBoy Assigned",
                link: "/pickupdrop/deliveryboyassigned",
              },
              {
                name: "DeliveryBoy Accepted",
                link: "/pickupdrop/deliveryboyaccepted",
              },
              {
                name: "PickedUp",
                link: "/pickupdrop/pickedup",
              },
              {
                name: "Delivered",
                link: "/pickupdrop/delivered",
              },
            ]}
          />
        )}
        {/* Nav Item - Utilities Collapse Menu */}
        {/* <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i className="fas fa-fw fa-wrench" />
            <span>Utilities</span>
          </a>
          <div
            id="collapseUtilities"
            className="collapse"
            aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Utilities:</h6>
              <a className="collapse-item">Colors</a>
              <a className="collapse-item">Borders</a>
              <a className="collapse-item">Animations</a>
              <a className="collapse-item">Other</a>
            </div>
          </div>
        </li> */}

        {/* Divider */}
        {/* <hr className="sidebar-divider d-none d-md-block" /> */}
        {/* Sidebar Toggler (Sidebar) */}
        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => this.props.handleMainToggle()}
          />
        </div>
      </ul>
    );
  }
}
