import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess, getAllPermissions } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import _ from 'lodash'

class AdminPermission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: [
                {
                    module: "Vendor",
                },
                {
                    module: "DeliveryBoy",
                },
                {
                    module: "State"
                },
                {
                    module: "City"
                },
                {
                    module: "Admin",
                },
                {
                    module: "AdminPermission"
                },
                {
                    module: "Coupon"
                },
                {
                    module: "DeliveryCharge"
                },
                {
                    module: "Order"
                },
                {
                    module: "Product"
                },
                {
                    module: "Category"
                },
                {
                    module: "CashOnDelivery"
                },
                {
                    module: "Banner"
                },
                {
                    module: "ScratchCard"
                },
                {
                    module: "PushNotification"
                },
                {
                    module: "User"
                },
                {
                    module: "BusinessStatus"
                },
                {
                    module: "PickUpCharge"
                },
                {
                    module: "DeliveryBoyEarning"
                },
                {
                    module: "Payout"
                },
                {
                    module: "PickUpDrop"
                },
            ]
        }
    }

    componentDidMount() {
        getPermissionAccess('Admin', 'add').then(data => {
            let token = localStorage.getItem('auth')
            axios.get(Config.BACKEND_API + 'admin/permission/list/' + this.props.match.params.id, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(res => {
                let updatedPermission = _.merge(_.keyBy(this.state.permissions, 'module'), _.keyBy(res.data.permissions, 'module'));
                updatedPermission = _.values(updatedPermission);
                this.setState({
                    permissions: updatedPermission
                })
            })
        }).catch(err => {
            this.props.history.push('/')
        })

    }

    handleChange = key => event => {
        this.setState({
            [key]: event.target.value,
        })
    }
    
    handleCheckboxChange = async (e, name, type) => {
        console.log('eeeeee ', e.target.checked, name, type);
        let permissions = this.state.permissions;
        permissions = await Promise.all(
            _.map(permissions, permission => {
                if (permission.module === name) {
                    permission[type] = e.target.checked
                }
                return permission;
            })
        )
        console.log('eeeeee ppppp ');
        this.setState({
            permissions
        })
    }

    handlePermissionSet = e => {
        e.preventDefault();
        let token = localStorage.getItem('auth')
        console.log('permissionspermissions ',this.state.permissions);
        axios.post(Config.BACKEND_API + 'admin/update-permission', {
            id: this.props.match.params.id,
            permissions: this.state.permissions
        }, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(res => {
            this.props.history.push('/admin')

        })
    }

    render() {
        console.log("propssssssss", this.props);
        return (
            <Layout>
                <h1>Admin Permission</h1>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h4 className="m-0 font-weight-bold text-primary float-left">Permissions</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.handlePermissionSet}>
                            <div className="table-sec">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Management</th>
                                                <th scope="col">View</th>
                                                <th scope="col">Add</th>
                                                <th scope="col">Edit</th>
                                                <th scope="col">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.permissions.map( permission => <tr>
                                                <td>{permission.module}</td>
                                                <td><input type="checkbox" className="check-input" onChange={e => this.handleCheckboxChange(e, permission.module, 'view')} checked={permission.view} /></td>
                                                <td><input type="checkbox" className="check-input" onChange={e => this.handleCheckboxChange(e, permission.module, 'add')} checked={permission.add} /></td>
                                                <td><input type="checkbox" className="check-input" onChange={e => this.handleCheckboxChange(e, permission.module, 'edit')} checked={permission.edit} /></td>
                                                <td><input type="checkbox" className="check-input" onChange={e => this.handleCheckboxChange(e, permission.module, 'delete')} checked={permission.delete} /></td>
                                            </tr>)}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <button type="submit" className="btn btn-primary">
                                Set Permission
                            </button>
                        </form>
                    </div>
                </div>

            </Layout>
        );
    }
}

export default withRouter(AdminPermission);
