import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class NavDropdownItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false
        }
    }

    handDropdown = () => {
        let showDropdown = this.state.showDropdown;
        this.setState({
            showDropdown: !showDropdown
        })
    }

    render() {
        return (
            <li className="nav-item">
                <a
                    className="nav-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseUtilities"
                    aria-expanded="true"
                    aria-controls="collapseUtilities"
                    onClick={this.handDropdown}
                >
                    <i className="fas fa-fw fa-wrench" />
                    <span>{this.props.title}</span>
                </a>
                {this.props.list && 
                    <div
                        id="collapseUtilities"
                        className={`collapse ${this.state.showDropdown && 'show'}`}
                        aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar"
                    >
                        <div className="bg-white py-2 collapse-inner rounded">
                            {/* <h6 className="collapse-header">Custom Utilities:</h6> */}
                            {this.props.list.map( e => {
                                return <Link to={e.link}>
                                    <a className="collapse-item">{e.name}</a>
                                </Link>
                            })}
                        </div>
                    </div>
                }
                
            </li>
        )
    }
}
