import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
// import { Modal } from "react-bootstrap";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      category: [],
      showAlertModal: false,
      categoryId: "",
    };
  }

  componentDidMount() {
    getPermission("Category")
      .then((data) => {
        this.setState({
          data,
        });
        this.getCategoryList()
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getCategoryList = () => {
    let data = this.state.data;
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/category/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("caty", res);
        this.setState({
          permissions: data.permissions,
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "image",
              text: "Image",
              searchable: true,
              formatter: this.preivewImage,
            },
            {
              dataField: "name",
              text: "Name",
              searchable: true,
            },
            {
              dataField: "isPopular",
              text: "Is Category Popular",
              searchable: true,
              formatter: this.isPopular,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          category: res.data.category,
        });
      });
  };

  preivewImage = (url) => {
    return (
      <>
        <img src={url} className="img-div"></img>
      </>
    );
  };

  isPopular = (isPopular, row) => {
    return (
      <>
        <label htmlFor={row.id} className="toggle-btn mt-2">
          <div className="position-relative">
            <input
              id={row.id}
              className="d-none"
              type="checkbox"
              name="isPopular"
              value={row.isPopular}
              defaultChecked={row.isPopular}
              disabled={this.state.disabledId === row.id}
              onClick={() => this.isPopularToggle(row.isPopular, row.id)}
            />
            <div className="rounded-pill shadow-inner toggle-pill" />
            <div className="dot position-absolute bg-white rounded-circle" />
          </div>
        </label>
      </>
    );
  };

  isPopularToggle = (isPopular, id) => {
    this.setState({ disabledId: id });
    let category = this.state.category;
    let index = _.findIndex(category, { id });
    let status = category[index].isPopular;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/category/isPopular/" + id,
        {
          isPopular: !status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        category[index].isPopular = !status;
        this.setState({
          category,
          disabledId: "",
        });
      });
  };

  deleteConfirmationPopup = (category) => {
    this.setState({
      showAlertModal: true,
      categoryId: category.id,
    });
  };

  handleCancel = () => {
    this.setState({
      showAlertModal: false,
    });
  };

  handleDeleteCategory = () => {
    let id = this.state.categoryId;
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/category/delete/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          showAlertModal: false,
        });
        this.getCategoryList();
      });
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
        {this.state.permissions.edit && (
          <Link to={"/category/edit/" + row.id}>
            <button type="button" class="btn btn-warning mr-1">
              <i class="fas fa-pen"></i>
            </button>
          </Link>
        )}

        <button
          onClick={() => this.deleteConfirmationPopup(row)}
          type="button"
          class="btn btn-danger mr-1"
        >
          <i class="fas fa-trash"></i>
        </button>
      </>
    );
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Category</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Category List
            </h4>
            <Link to={"/category/add/"} className="float-right">
              <button type="button" class="btn btn-primary mr-1">
                <i class="fas fa-plus"></i> Add
              </button>
            </Link>
          </div>
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.category}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>

        {/* #popup */}
        <div
          className="d-pageoverlay"
          style={{ display: this.state.showAlertModal ? "block" : "none" }}
        >
          <div className="pagemodal-bg-wrapper">
            <div className="d-popup">
              <h6 className="mb-3">
                Are you sure you want to delete this Category ?
              </h6>

              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={this.handleDeleteCategory}
                  className="btn btn-success mr-3"
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.handleCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CategoryList;
