import { Component } from "react";
import Layout from "../../layout/mainlayout/Layout";
import axios from "axios";
import Config from "../../../libs/config";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getPermission } from "../../../libs/auth.js";

const initialState = {
  city: "",
  title: "",
  message: "",
};

class PushNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      cities: [],
      city: "",
      title: "",
      message: "",
      permissions: [],
    };
  }

  componentDidMount() {
    getPermission("PushNotification")
      .then((data) => {
        let token = localStorage.getItem("auth");
        axios
          .get(Config.BACKEND_API + "admin/state/list", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((states) => {
            this.setState({
              permissions: data.permissions,
              states: states.data.states,
            });
            console.log("perrrrr", this.state.permissions);
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  handleStateChange = (e) => {
    console.log();
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/city/list?state_id=" + e.target.value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((cities) => {
        this.setState({
          cities: cities.data.cities,
          city: cities.data.cities[0].id,
          state: e.target.value,
        });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  notify = (message) => {
    toast.success("🔔" + "Notification Sent!!!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handlePushNotification = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + `admin/notification/send?usertype=${this.state.userType}`,
        {
          title: this.state.title,
          message: this.state.message,
          city: this.state.city,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        this.notify();
        this.setState(initialState);
      });
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Push Notification</h1>
        <div className="col-9">
          <div className="card shadow mb-4">
            <div className="card-body">
              <form onSubmit={this.handlePushNotification}>
                <div className="form-group">
                  <label for="notification_title">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="notification_title"
                    placeholder="Enter Your Title"
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label for="notification_message">Message</label>
                  <textarea
                    className="form-control"
                    id="notification_message"
                    rows="3"
                    placeholder="Enter Your Message"
                    name="message"
                    value={this.state.message}
                    onChange={this.handleChange}
                    required
                  ></textarea>
                </div>

                <div className="form-row">
                  <div className="form-group col-3">
                    <label htmlFor="state">State</label>
                    <select
                      class="form-control"
                      id="state"
                      name="state"
                      onChange={this.handleStateChange}
                      required
                    >
                      <option disabled selected required>
                        Select State
                      </option>
                      {this.state.states.map((e) => (
                        <option value={e.id}>
                          {_.startCase(_.toLower(e.name))}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-3">
                    <label htmlFor="city">City</label>
                    <select
                      class="form-control"
                      id="city"
                      name="city"
                      onChange={this.handleChange}
                      required
                    >
                      {this.state.cities.map((e) => (
                        <option value={e.id}>
                          {_.startCase(_.toLower(e.name))}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-3">
                    <label htmlFor="type">User Type</label>
                    <select
                      class="form-control"
                      id="userType"
                      name="userType"
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">~Select Type~</option>
                      <option value="customer">Customer</option>
                      <option value="vendor">Vendor</option>
                      <option value="deliveryboy">Delivery Boy</option>
                    </select>
                  </div>
                </div>

                <button className="btn btn-primary mt-2">Send</button>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(PushNotification);