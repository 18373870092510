import Layout from "../../layout/mainlayout/Layout";
import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import ImgUpload from "../../layout/upload/ImgUpload";
import VariantFields from "./VariantFields";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import axios from "axios";

class ProductAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      image: "",
      isVariant: false,
      categorylist: [],
      store: this.props.match.params.id,
      price: "",
      cgst: "",
      sgst: "",
      isVeg: false,
      variant: [
        {
          id: 1,
          type: "",
          price: "",
          cgst: "",
          sgst: "",
        },
      ],
      category: ""
    };
  }

  componentDidMount() {
    getPermissionAccess("Product", "add").catch((err) => {
      this.props.history.push("/");
    });
    let token = localStorage.getItem("auth");
    this.setState({ token: token });
    axios
      .get(Config.BACKEND_API + "admin/category/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("cat", res);
        this.setState({
          // category: res.data.category[0].id,
          categorylist: res.data.category,
        });
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log("onchsnging val", [e.target.name]);
  };

  addVariant = () => {
    let variant = this.state.variant;
    variant.push({
      id: variant[variant.length - 1].id + 1,
      type: "",
      price: "",
      cgst: "",
      sgst: "",
    });
    this.setState({
      variant,
    });
  };

  removeVariant = (id) => {
    let variant = this.state.variant;
    _.remove(variant, {
      id,
    });
    this.setState({
      variant,
    });
  };

  handleFieldChange = (index, value) => {
    let variant = this.state.variant;
    variant[index] = value;
    this.setState({
      variant,
    });
  };

  handleCheckboxChange = (key) => {
    var current = this.state[key];
    this.setState({
      [key]: !current,
    });
  };

  handleAdd = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    let data = {
      name: this.state.name,
      description: this.state.description,
      image: this.state.image,
      isVariant: this.state.isVariant,
      category: this.state.category,
      store: this.state.store,
      isVeg: this.state.isVeg,
      variant: this.state.variant,
    }
    if (!this.state.isVariant) {
      
      data.price= this.state.price
      data.cgst= this.state.cgst
      data.sgst= this.state.sgst
      data.variant = []
    }
    
    axios
      .post(
        Config.BACKEND_API + "admin/product/add/",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/product/"+this.state.store);
      });
  };

  handleImageChange = (key, val) => {
    // console.log('kkkkk ',key, val);
    this.setState({
      [key]: val,
    });
  };

  render() {
    console.log("ttttt ", this.state);
    return (
      <Layout>
        <h1 className="b-4">Add Product</h1>
        <div className="row pb-5">
          <div className="col-6">
            <form onSubmit={this.handleAdd}>
              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12" required>
                  <label htmlFor="description">Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    required
                  ></textarea>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="category">Category</label>
                  
                  <select
                    class="form-control"
                    name="category"
                    onChange={this.handleChange}
                    required
                  >
                    <option value="" selected disabled>Select Category</option>
                    {this.state.categorylist.map((e) => (
                      <option value={e.id}>{e.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isVariant"
                      name="isVariant"
                      value={this.state.isVariant}
                      onClick={() => this.handleCheckboxChange("isVariant")}
                    />
                    <label className="custom-control-label" htmlFor="isVariant">
                      Is Variant
                    </label>
                  </div>
                </div>
              </div>

              {!this.state.isVariant ? (
                <>
                  <div className="form-row">
                    <div className="form-group col-md-6" required>
                      <label htmlFor="price">Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="price"
                        placeholder="Price"
                        name="price"
                        value={this.state.price}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="form-group col-md-6" required>
                      <label htmlFor="cgst">cgst</label>
                      <input
                        type="number"
                        className="form-control"
                        id="cgst"
                        placeholder="cgst"
                        name="cgst"
                        value={this.state.cgst}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6" required>
                      <label htmlFor="sgst">sgst</label>
                      <input
                        type="number"
                        className="form-control"
                        id="sgst"
                        placeholder="sgst"
                        name="sgst"
                        value={this.state.sgst}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {this.state.variant.map((e, i) => {
                    return (
                      <>
                        <VariantFields
                          fields={e}
                          handleFieldChange={this.handleFieldChange}
                          index={i}
                        />

                        {this.state.variant.length !== 1 && (
                          <button
                            type="button"
                            className="btn btn-outline-danger w-100 mb-4"
                            onClick={() => this.removeVariant(e.id)}
                          >
                            Remove
                          </button>
                        )}
                      </>
                    );
                  })}
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-primary-outlined w-100 bg-white text-dark border mb-4"
                      onClick={() => this.addVariant()}
                    >
                      Add Vairant
                    </button>
                  </div>
                </>
              )}

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isVeg"
                      name="isVeg"
                      value={this.state.isVeg}
                      onClick={() => this.handleCheckboxChange("isVeg")}
                    />
                    <label className="custom-control-label" htmlFor="isVeg">
                      Is Veg
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Upload Image</label>
                  <ImgUpload
                    name={"image"}
                    token={this.state.token}
                    actionUrl={"admin/upload"}
                    handleImageChange={this.handleImageChange}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(ProductAdd);
