import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import { getPermission } from "../../../libs/auth.js";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";
import Loader from "../../layout/loader/loader.js";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class VendorApprovalPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      permissions: [],
      orders: [],
      loaderLoading:true
    };
  }

  componentDidMount() {
    getPermission("Order")
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState(
          {
            permissions: data.permissions,
            token: token,
          },
          () => {
            // this.intervalId = setTimeout(this.getOrderList, 15000);
            this.getVendorPendingLit();
          }
        );
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getVendorPendingLit = () => {
    axios
      .get(Config.BACKEND_API + "admin/orders/statusId/1", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((res) => {       
        this.intervalId = setTimeout(this.getVendorPendingLit, 15000);
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "orderNo",
              text: "Order No",
              searchable: true,
            },
            {
              dataField: "restaurant",
              text: "Restaurant",
              searchable: true,
            },
            {
              dataField: "orderStatus",
              text: "OrderStatus",
              searchable: true,
              formatter: this.orderStatusLabel,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          orders: res.data.orders,
          loaderLoading:false
        });
      });
  };

  orderStatusLabel = (cell, row) => {
    return (
      <>
        <h5>
          {" "}
          <span className="badge badge-warning">Pending</span>
        </h5>
      </>
    );
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
        {this.state.permissions.view && (
          <Link to={"/order/view/" + row.id}>
            <button type="button" class="btn btn-primary mr-2">
              <i class="fa fa-eye"></i>
            </button>
          </Link>
        )}
      </>
    );
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Vendor pending to accept</h1>
        <div className="card shadow mb-4">
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.orders}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>
        {(this.state.loaderLoading) ? <Loader /> : null}
      </Layout>
    );
  }
}

export default withRouter(VendorApprovalPending);
