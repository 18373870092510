import Layout from "../../layout/mainlayout/Layout";
import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import ImgUpload from "../../layout/upload/ImgUpload";
import VariantFields from "./VariantFields";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import axios from "axios";
import GetVarientField from "./GetVariantField";

class EditProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      name: "",
      description: "",
      image: "",
      isVariant: true,
      price: "",
      cgst: "",
      sgst: "",
      store: "",
      variant: [
        {
          id: 1,
          type: "",
          price: "",
          cgst: "",
          sgst: "",
        },
      ],
      isLoading: true
    };
  }

  componentDidMount() {
    getPermissionAccess("Product", "edit")
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState({
          token: token,
        });
        axios
          .get(
            Config.BACKEND_API +
              "admin/product/getById/" +
              this.props.match.params.id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            this.setState({
              permissions: data.permissions,
              product: res.data.product,
              name: res.data.product.name,
              description: res.data.product.description,
              isVariant: res.data.product.isVariant,
              price: res.data.product.price,
              sgst: res.data.product.sgst,
              cgst: res.data.product.cgst,
              image: res.data.product.image,
              store: res.data.product.store,
              isLoading: false
            });
            if (res.data.product.variant) {
              this.setState({
                variant: res.data.product.variant,
              });
            }
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log("onchsnging val", [e.target.name]);
  };

  handleCheckboxChange = (key) => {
    var current = this.state[key];
    this.setState({
      [key]: !current,
    });
  };

  handleFieldChange = (index, value) => {
    let variant = this.state.variant;
    variant[index] = value;
    this.setState({
      variant,
    });
  };



  handleImageChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    let data = {
      name: this.state.name,
      description: this.state.description,
      image: this.state.image,
      variant: this.state.variant,
    };
    if (!this.state.isVariant) {
      data.price = this.state.price;
      data.cgst = this.state.cgst;
      data.sgst = this.state.sgst;
      data.variant = [];
    }

    axios
      .post(
        Config.BACKEND_API +
          "admin/product/update/" +
          this.props.match.params.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/product/" + this.state.store);
      });
  };

  render() {
    console.log("ttttt ", this.state.variant);
    return (
      <Layout>
        <h1 className="b-4">Edit Product</h1>
        <div className="row pb-5">
          <div className="col-6">
            <form onSubmit={this.handleUpdate}>
              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12" required>
                  <label htmlFor="description">Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    required
                  ></textarea>
                </div>
              </div>

              {/* <div className="form-row">
                <div className="form-group col-md-6" required>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isVariant"
                      name="isVariant"
                      value={this.state.isVariant}
                      defaultChecked={this.state.isVariant}
                      onClick={() => this.handleCheckboxChange("isVariant")}
                    />
                    <label className="custom-control-label" htmlFor="isVariant">
                      Is Variant
                    </label>
                  </div>
                </div>
              </div> */}

              {!this.state.isVariant ? (
                <>
                  <div className="form-row">
                    <div className="form-group col-md-6" required>
                      <label htmlFor="price">Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="price"
                        placeholder="Price"
                        name="price"
                        value={this.state.price}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="form-group col-md-6" required>
                      <label htmlFor="cgst">cgst</label>
                      <input
                        type="number"
                        className="form-control"
                        id="cgst"
                        placeholder="cgst"
                        name="cgst"
                        value={this.state.cgst}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6" required>
                      <label htmlFor="sgst">sgst</label>
                      <input
                        type="number"
                        className="form-control"
                        id="sgst"
                        placeholder="sgst"
                        name="sgst"
                        value={this.state.sgst}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {this.state.variant.map((e, i) => {               

                    return (
                      <>
                        <GetVarientField
                          fields={e}
                          handleFieldChange={this.handleFieldChange}
                          index={i}
                        />
                      </>
                    );
                  })}
                </>
              )}

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="name">Replace Image</label>
                  {!this.state.isLoading && (
                    <ImgUpload
                      name={"image"}
                      token={this.state.token}
                      actionUrl={"admin/upload"}
                      handleImageChange={this.handleImageChange}
                      image={this.state.image}
                    />
                  )}
                </div>
              </div>

              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(EditProduct);
