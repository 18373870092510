import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import Layout from "../../layout/mainlayout/Layout";
import ImgUpload from "../../layout/upload/ImgUpload";

class CategoryAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      image: "",
    };
  }

  componentDidMount() {
    getPermissionAccess("Category", "add")
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState({ token: token });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAdd = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/category/add",
        {
          name: this.state.name,
          image: this.state.image,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/category");
      });
  };

  handleImageChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };

  render() {
    return (
      <Layout>
        <h1 className="b-4">Add Category</h1>
        <div className="row">
          <div className="col-6">
            <form onSubmit={this.handleAdd}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6" required>
                  <label htmlFor="image">Upload Image</label>
                  <ImgUpload
                    name={"image"}
                    token={this.state.token}
                    actionUrl={"admin/upload"}
                    handleImageChange={this.handleImageChange}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(CategoryAdd);
