import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
// import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { withRouter, Link } from "react-router-dom";
import { getPermission } from "../../../libs/auth.js";
import Loader from "../../layout/loader/loader.js";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
});

class BusinessStatusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      orders: [],
      permissions: [],
      totalAmount: "",
      totalOrder: "",
      totalProfit: "",
      onlineAmount:"",
      codAmount:"",
      bonus:"",
      totalTax: "",
      loaderLoading:true
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("auth");
    if (!token) {
      this.props.history.push("/login");
    }
    getPermission("BusinessStatus")
      .then((data) => {
        this.setState({
          permissions: data.permissions,
        });
        this.getBusinessStatusListByDate();
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  getBusinessStatusList = (e) => {
    this.setState({
      loaderLoading:true
    })
    e.preventDefault();
    this.getBusinessStatusListByDate();
  };

  getBusinessStatusListByDate = () => {
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API + "admin/orders/business-status",
        {
          startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("orders", res);
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "orderNo",
              text: "Order No",
              searchable: true,
            },
            {
              dataField: "date",
              text: "Date",
              searchable: true,
              formatter: this.dateFormatter,
            },
            {
              dataField: "shopName",
              text: "Shop Name",
              searchable: true,
            },
            {
              dataField: "productPrice",
              text: "Product Price",
              searchable: true,
            },
            {
              dataField: "tax",
              text: "Tax",
              searchable: true,
            },
            {
              dataField: "deduction",
              text: "Deduction",
              searchable: true,
            },
            {
              dataField: "total",
              text: "Amount Recieved",
              searchable: true,
            },
            {
              dataField: "shopAmount",
              text: "Shop Amount",
              searchable: true,
            },
            {
              dataField: "deliveryCharge",
              text: "Delivery Charge",
              searchable: true,
            },
            {
              dataField: "deliveryBoyEarning",
              text: "Delivery Boy Earning",
              searchable: true,
            },
            {
              dataField: "profit",
              text: "Profit",
              searchable: true,
            },
          ],
          orders: res.data.orders,
          totalAmount: res.data.totalAmount,
          totalOrder: res.data.totalOrder,
          totalProfit: res.data.totalProfit,
          totalTax: res.data.totalTax,
          codAmount:res.data.codAmount,
          onlineAmount:res.data.onlineAmount,
          bonus:res.data.bonus,
          loaderLoading:false
        });
      });
  };

  dateFormatter = (date) => {
    return <>{moment(date).format("YYYY-MM-DD")}</>;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  downloadPDF = () => {
    let orders = this.state.orders;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Business Status";
    const headers = [
      [
        "Order No",
        "Date",
        "Shop Name",
        "Product Price",
        "Delivery Tax",
        "Deduction",
        "Amount Recieved",
        "Shop Amount",
        "Delivery Charge",
        "Profit",
      ],
    ];

    orders = orders.map((e) => [
      e.orderNo,
      moment(e.date).format("YYYY-MM-DD"),
      e.shopName,
      e.productPrice,
      e.deliveryTax,
      e.deduction,
      e.amountRecieved,
      e.shopAmount,
      e.deliveryCharge,
      e.profit,
    ]);

    let content = {
      styles: { fontSize: 10 },
      startY: 50,
      head: headers,
      body: orders,
    };

    // doc.autoTable({
    //     body: [
    //       [{ content, styles: { fontSize: 5 }} ],
    //     ],
    //   })

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("BusinessStatus.pdf");
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">Business Status</h1>

        <div className="row">
          <div className="col-12">
            <div className="card  mb-4">
              {/* <div className="card-header py-3">
                <h4 className="m-0 font-weight-bold text-primary float-left">
                  Business Status
                </h4>
              </div> */}
              <div className="card-body">
                <form onSubmit={this.getBusinessStatusList}>
                  <div className="form-row">
                    <div className="form-group col-4">
                      <label for="startDate">Select Start Date</label>
                      <input
                        type="date"
                        value={this.state.startDate}
                        name="startDate"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group  col-4">
                      <label for="endDate">Select End Date</label>
                      <input
                        type="date"
                        value={this.state.endDate}
                        name="endDate"
                        onChange={this.handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label className="opacity-0">.</label> <br />
                      <button
                        type="submit"
                        className="btn btn-primary px-4 shadow"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

    
          <div className="row mb-4">
            <div className="col">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Total Order
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.totalOrder}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Total Amount
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.totalAmount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        COD Amount
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.codAmount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Online Amount
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.onlineAmount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Total Profit
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.totalProfit}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Bonus
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.bonus}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card  border-left-primary shadow h-100 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Tax
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {this.state.totalTax}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
     
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary float-left">
              Business Status List
            </h4>
            <button
              className="float-right btn btn-outline-dark"
              onClick={this.downloadPDF}
            >
              Print
            </button>
          </div>

          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.orders}
                columns={this.state.columns}
                pagination={pagination}
                wrapperClasses="table-responsive"
              />
            )}
          </div>
        </div>
        {(this.state.loaderLoading) ? <Loader /> : null}
      </Layout>
    );
  }
}

export default withRouter(BusinessStatusList);
