import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPermissionAccess } from "../../../libs/auth";
import Config from "../../../libs/config";
import _ from "lodash";
import Layout from "../../layout/mainlayout/Layout";
import ImgUpload from "../../layout/upload/ImgUpload";

class VendorEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      state: "",
      city: "",
      phoneCode: "",
      phoneNumber: "",
      email: "",
      altPhoneCode: "",
      altPhoneNumber: "",
      locationName: "",
      storeType: "",
      subStoreType: "",
      fssai: "",
      rateForTwo: "",
      image: "",
      logo: "",
      bankName: "",
      bankAccountNo: "",
      bankIfscCode: "",
      commissionPercentage: "",
      deliveryDistanceLimit: "",
      isPopularBrand: false,
      isPureVeg: false,
      isNewlyLaunched:false,
      isTopOfferPlusCashBack:false,
      latitude: "",
      longitude: "",
      openTime: "",
      closeTime: "",
      cookingTime: "",
      isLoading: false,
      storetypeList: [
        {
          name: "Restaurant",
        },
        {
          name: "Grocery",
        },
        {
          name: "FishMeat",
        },
        {
          name: "Bakery",
        },
        {
          name: "Kirana",
        },
        {
          name: "Medicine",
        },
        {
          name: "VegetablesFruits",
        },
      ],
      states: [],
      cities: [],
    };
  }

  componentDidMount() {
    getPermissionAccess("Vendor", "edit")
      .then((data) => {
        let token = localStorage.getItem("auth");
        this.setState({ token: token });
        axios
          .get(
            Config.BACKEND_API +
              "admin/vendor/getById/" +
              this.props.match.params.id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("vendorrrrr res", res.data.vendor);
            let {
              name,
              address,
              state,
              city,
              phoneCode,
              phoneNumber,
              email,
              altPhoneCode,
              altPhoneNumber,
              locationName,
              storeType,
              subStoreType,
              fssai,
              rateForTwo,
              image,
              logo,
              bankName,
              bankAccountNo,
              bankIfscCode,
              commissionPercentage,
              deliveryDistanceLimit,
              isPopularBrand,
              isNewlyLaunched,
              isTopOfferPlusCashBack,
              isPureVeg,
              LatLng,
              openTime,
              closeTime,
              cookingTime,
            } = res.data.vendor;
            this.setState({
              name: name,
              address: address,
              state: state,
              city: city,
              phoneCode: phoneCode,
              phoneNumber: phoneNumber,
              email: email,
              altPhoneCode: altPhoneCode,
              altPhoneNumber: altPhoneNumber,
              locationName: locationName,
              storeType: storeType,
              subStoreType: subStoreType,
              fssai: fssai,
              rateForTwo: rateForTwo,
              image: image,
              logo: logo,
              bankName: bankName,
              bankAccountNo: bankAccountNo,
              bankIfscCode: bankIfscCode,
              commissionPercentage: commissionPercentage,
              deliveryDistanceLimit: deliveryDistanceLimit,
              isPopularBrand: isPopularBrand,
              isPureVeg: isPureVeg,
              isNewlyLaunched: isNewlyLaunched,
              isTopOfferPlusCashBack: isTopOfferPlusCashBack,
              latitude: LatLng.coordinates[0],
              longitude: LatLng.coordinates[1],
              openTime: openTime,
              closeTime: closeTime,
              cookingTime: cookingTime,
            });

            axios
              .get(Config.BACKEND_API + "admin/state/list", {
                headers: {
                  Authorization: "Bearer " + token,
                },
              })
              .then((states) => {
                this.setState({
                  states: states.data.states,
                });
                this.handleStateChange({ target: { value: state } });
                // axios
                //   .get(Config.BACKEND_API + "admin/city/list", {
                //     headers: {
                //       Authorization: "Bearer " + token,
                //     },
                //   })
                //   .then((cities) => {

                //     this.setState({
                //       cities: cities.data.cities,
                //       states: states.data.states,
                //     });
                //   });
              });
          });
      })

      .catch((err) => {
        console.log("err ", err);
      });
  }

  getLocation = () => {
    this.setState({
      isLoading: true,
    });

    let _this = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      _this.setState({
        isLoading: false,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
    console.log("ss", this.state.latitude);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleImageChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };

  handleCheckboxChange = (key) => {
    var current = this.state[key];
    this.setState({
      [key]: !current,
    });
  };

  handleStateChange = (e) => {
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/city/list?state_id=" + e.target.value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((cities) => {
        this.setState({
          cities: cities.data.cities,
          // city: cities.data.cities[0].id,
          state: e.target.value,
        });
      });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("auth");
    axios
      .post(
        Config.BACKEND_API +
          "admin/vendor/update/" +
          this.props.match.params.id,
        {
          name: this.state.name,
          address: this.state.address,
          state: this.state.state,
          city: this.state.city,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          email: this.state.email,
          altPhoneCode: this.state.altPhoneCode,
          altPhoneNumber: this.state.altPhoneNumber,
          locationName: this.state.locationName,
          storeType: this.state.storeType,
          subStoreType: this.state.subStoreType,
          fssai: this.state.fssai,
          rateForTwo: this.state.rateForTwo,
          image: this.state.image,
          logo: this.state.logo,
          bankName: this.state.bankName,
          bankAccountNo: this.state.bankAccountNo,
          bankIfscCode: this.state.bankIfscCode,
          commissionPercentage: this.state.commissionPercentage,
          deliveryDistanceLimit: this.state.deliveryDistanceLimit,
          isPopularBrand: this.state.isPopularBrand,
          isPureVeg: this.state.isPureVeg,
          isNewlyLaunched:this.state.isNewlyLaunched,
          isTopOfferPlusCashBack:this.state.isTopOfferPlusCashBack,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          openTime: this.state.openTime,
          closeTime: this.state.closeTime,
          cookingTime: this.state.cookingTime,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.props.history.push("/vendor");
      });
  };

  render() {
    return (
      <Layout>
        <h1 className="mb-4">Edit Vendor</h1>
        <div className="row">
          <div className="col-6 pb-5">
            <form onSubmit={this.handleUpdate}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder="Address"
                    name="address"
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="state">State</label>
                  <select
                    class="form-control"
                    id="state"
                    name="state"
                    onChange={this.handleStateChange}
                    required
                  >
                    <option disabled selected>
                      Select State
                    </option>
                    {this.state.states.map((e) => (
                      <option value={e.id} selected={this.state.state === e.id}>
                        {_.startCase(_.toLower(e.name))}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="city">City</label>
                  <select
                    class="form-control"
                    id="city"
                    name="city"
                    onChange={this.handleChange}
                    required
                  >
                    {this.state.cities.map((e) => (
                      <option value={e.id} selected={this.state.city === e.id}>
                        {_.startCase(_.toLower(e.name))}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    pattern="[789][0-9]{9}"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="phoneNumber">Alternate Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="altPhoneNumber"
                    placeholder="Alternate Phone Number"
                    name="altPhoneNumber"
                    pattern="[789][0-9]{9}"
                    value={this.state.altPhoneNumber}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="storeType">Store Type</label>
                  <select
                    class="form-control"
                    name="storeType"
                    onChange={this.handleChange}
                  >
                    <option selected>Select Store Type</option>
                    {this.state.storetypeList.map((e) => (
                      <option
                        value={e.name}
                        selected={this.state.storeType == e.name}
                      >
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="subStoreType">Sub Store Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="subStoreType"
                    placeholder="Sub Store Type"
                    name="subStoreType"
                    value={this.state.subStoreType}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="fssai">fssai</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fssai"
                    placeholder="Fssai"
                    name="fssai"
                    value={this.state.fssai}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="rateForTwo">Rate For Two</label>
                  <input
                    type="text"
                    className="form-control"
                    id="rateForTwo"
                    placeholder="Rate For Two"
                    name="rateForTwo"
                    value={this.state.rateForTwo}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="bankName">Bank Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bankName"
                    placeholder="Bank Name"
                    name="bankName"
                    value={this.state.bankName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="bankAccountNo">Bank Account Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="bankAccountNo"
                    placeholder="Bank Account Number"
                    name="bankAccountNo"
                    value={this.state.bankAccountNo}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="bankIfscCode">Bank IFSC Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bankIfscCode"
                    placeholder="Bank IFSC Code"
                    name="bankIfscCode"
                    value={this.state.bankIfscCode}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="commissionPercentage">
                    Commission Percentage (%)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="commissionPercentage"
                    placeholder="Commission Percentage"
                    name="commissionPercentage"
                    max="100"
                    value={this.state.commissionPercentage}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="deliveryDistanceLimit">
                    Delivery Distance Limit (Km)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="deliveryDistanceLimit"
                    placeholder="Delivery Distance Limit"
                    name="deliveryDistanceLimit"
                    max="50"
                    value={this.state.deliveryDistanceLimit}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="locationName">Location Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="locationName"
                    placeholder="Location Name"
                    name="locationName"
                    value={this.state.locationName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="latitude">Latitude</label>
                  <input
                    type="number"
                    className="form-control"
                    id="latitude"
                    placeholder="Latitude"
                    name="latitude"
                    value={this.state.latitude}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="longitude">Longitude</label>
                  <input
                    type="text"
                    className="form-control"
                    id="longitude"
                    placeholder="Longitude"
                    name="longitude"
                    value={this.state.longitude}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-4">
                  <label className="opacity-0">.</label> <br />
                  <button
                    type="button"
                    onClick={this.getLocation}
                    className="btn btn-secondary w-100 d-flex justify-content-center align-items-center"
                  >
                    {this.state.isLoading ? (
                      <div
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"
                      ></div>
                    ) : (
                      ""
                    )}
                    Get Location
                  </button>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="openTime">Open Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="openTime"
                    placeholder="Open Time"
                    name="openTime"
                    value={this.state.openTime}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="closeTime">Close Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="closeTime"
                    placeholder="Close Time"
                    name="closeTime"
                    value={this.state.closeTime}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="cookingTime">Cooking Time (Minutes)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="cookingTime"
                    placeholder="Cooking Time"
                    name="cookingTime"
                    max="59"
                    value={this.state.cookingTime}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isPopularBrand"
                      name="isPopularBrand"
                      value={this.state.isPopularBrand}
                      checked={this.state.isPopularBrand}
                      onClick={() =>
                        this.handleCheckboxChange("isPopularBrand")
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isPopularBrand"
                    >
                      Is Popular Brand
                    </label>
                  </div>
                </div>
              </div>
              

              <div className="form-row">
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isPureVeg"
                      name="isPureVeg"
                      checked={this.state.isPureVeg}
                      value={this.state.isPureVeg}
                      onClick={() => this.handleCheckboxChange("isPureVeg")}
                    />
                    <label className="custom-control-label" htmlFor="isPureVeg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        className="mr-2"
                        viewBox="0 0 91.47 91.82"
                      >
                        <path
                          id="Path_7399"
                          data-name="Path 7399"
                          d="M2.94,0H91.47V91.82H0V0ZM45.73,19.96A25.945,25.945,0,1,1,19.78,45.91,25.952,25.952,0,0,1,45.73,19.96ZM85.59,5.88H5.88V85.94H85.59V5.88Z"
                          fill="#008300"
                        />
                      </svg>
                      Is Pure Veg
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isNewlyLaunched"
                      name="isNewlyLaunched"
                      checked={this.state.isNewlyLaunched}
                      value={this.state.isNewlyLaunched}
                      onClick={() =>
                        this.handleCheckboxChange("isNewlyLaunched")
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isNewlyLaunched"
                    >
                      Is Newly Launched
                    </label>
                  </div>
                </div>
              </div>   <div className="form-row">
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isTopOfferPlusCashBack"
                      name="isTopOfferPlusCashBack"
                      checked={this.state.isTopOfferPlusCashBacksPopularBrand}
                      value={this.state.isTopOfferPlusCashBacksPopularBrand}
                      onClick={() =>
                        this.handleCheckboxChange("isTopOfferPlusCashBack")
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isTopOfferPlusCashBack"
                    >
                      Is Top Offer Plus Cash Back
                    </label>
                  </div>
                </div>
              </div>


              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Upload Image</label>
                  {this.state.image !== "" && (
                    <ImgUpload
                      name={"image"}
                      token={this.state.token}
                      actionUrl={"admin/upload"}
                      handleImageChange={this.handleImageChange}
                      image={this.state.image}
                    />
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="name">Upload Logo</label>
                  {this.state.image !== "" && (
                    <ImgUpload
                      name={"logo"}
                      token={this.state.token}
                      actionUrl={"admin/upload"}
                      handleImageChange={this.handleImageChange}
                      image={this.state.logo}
                    />
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(VendorEdit);
