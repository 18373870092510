import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { Component } from "react";
import Layout from "../../layout/mainlayout/Layout.js";
import axios from "axios";
import Config from "../../../libs/config.js";
import { Link, withRouter } from "react-router-dom";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50
});

class UnassignedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      unassignedOrders: [],
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("auth");
    axios
      .get(Config.BACKEND_API + "admin/orders/delivery-boy/unassigned/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({
          columns: [
            {
              dataField: "id",
              text: "ID",
              isDummyField: true,
              formatter: (cell, row, rowIndex) => ++rowIndex,
            },
            {
              dataField: "orderNo",
              text: "Order No",
              searchable: true,
            },
            {
              dataField: "name",
              text: "Restaurant",
              searchable: true,
              formatter: this.storeName,
            },
            {
              dataField: "actions",
              text: "Actions",
              isDummyField: true,
              csvExport: false,
              formatter: this.actionsFormatter,
            },
          ],
          unassignedOrders: res.data.orders,
        });
      });
  }

  storeName = (cell, row) => {
    return (
      <>
       <span>{row.store.name}</span>
      </>
    );
  };

  actionsFormatter = (cell, row) => {
    return (
      <>
        <Link to={"/order/view/" + row.id}>
          <button type="button" class="btn btn-primary mr-2">
            <i class="fa fa-eye"></i>
          </button>
        </Link>
      </>
    );
  };

  render() {
    return (
      <Layout>
        <h1 className="h3 mb-4 text-gray-800">
          Delivery Boy Not Assigned Orders
        </h1>
        <div className="card shadow mb-4">
          <div className="card-body">
            {this.state.columns.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.state.unassignedOrders}
                columns={this.state.columns}
                pagination={pagination}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(UnassignedOrders);
